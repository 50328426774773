import React from "react";
import MediaEditModal from "../../components/occurrence/MediaEditModal";
import DoneDialog from "../../common/DoneDialog";
import ConfirmDialog from "../../common/ConfirmDialog";

import { Stack, Button, Typography, Dialog } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, DocumentDownload, Trash } from "iconsax-react";
import { Input } from "../user-management/ManageProfile";
import useFetch from "../../helpers/useFetch";
import Loading from "../../common/Loading";
import { error } from "../../common/Notifications";
import axios from "axios";
import { useSelector } from "react-redux";

const MediaDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userRole = useSelector((state) => state.users?.user?.roles?.role);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { data, isLoading, refetch } = useFetch(`api/media-library/${id}/`);

  // IMAGES
  const [imageToShow, setImageToShow] = React.useState();
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  async function downloadImage() {
    setLoading(true);
    try {
      var link = document.createElement("a");
      link.href = data?.data?.url;
      link.target = "_blank";
      link.download = data?.data?.title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      error(err.message);
    }
    setLoading(false);
    setShowDownloadModal(false);
  }
  async function handleDelete() {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.delete(
        `${process.env.REACT_APP_BASE_API}api/media-library/${data?.data?.id}/`,
        config
      );
      navigate(-1);
    } catch (err) {
      error(err?.data?.message);
    }
    setLoading(false);
    setShowDeleteModal(false);
  }

  if (isLoading) {
    <Loading />;
  } else {
    return (
      <Stack>
        <Dialog
          open={showDownloadModal}
          onClose={() => setShowDownloadModal(false)}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
            },
          }}
        >
          <ConfirmDialog
            isDelete={false}
            isCustom={true}
            confirmText="Download"
            title="Download Occurrence Media?"
            isLoading={loading}
            description="Are you sure you want to download this media? You will now have the media!"
            handleClose={() => setShowDownloadModal(false)}
            handleConfirm={downloadImage}
          />
        </Dialog>
        <Dialog
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
            },
          }}
        >
          <ConfirmDialog
            isDelete={true}
            confirmText="Delete"
            title="Delete Occurrence Media?"
            isLoading={loading}
            description="Are you sure you want to delete this media? You wont be able to get it back!"
            handleClose={() => setShowDeleteModal(false)}
            handleConfirm={handleDelete}
          />
        </Dialog>
        <Dialog
          open={!!imageToShow}
          onClose={() => setImageToShow(undefined)}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              width: "90%",
              maxWidth: "550px",
            },
          }}
        >
          <MediaEditModal
            occurrenceTitle={data?.data?.occurrence?.title}
            imageDetail={data?.data}
            setDone={() => {
              setIsDone(true);
              refetch();
              setImageToShow(undefined);
            }}
          />
        </Dialog>
        <Dialog
          open={isDone}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              width: "90%",
              maxWidth: "550px",
            },
          }}
          onClose={() => setIsDone(false)}
        >
          <DoneDialog
            title="Successfully Editted"
            description="You have successfully edited this media and has been updated for you to access it."
          />
        </Dialog>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <div
            className="flex items-center gap-1 cursor-pointer w-fit ml-6"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size="22" color="#000000" />
            <p>Back</p>
          </div>
          {userRole?.toLowerCase()?.includes("section-owner") && (
            <Button
              variant="contained"
              className="bg-primary capitalize font-bold shadow-none px-12 py-2 rounded-2xl"
              onClick={() => navigate("/media/add")}
            >
              Add Media
            </Button>
          )}
        </Stack>

        <Stack className="bg-white rounded-2xl px-12 py-6 mt-8">
          <h2 className="text-xl font-semibold">Media Details</h2>
        </Stack>

        <Stack className="bg-white rounded-2xl px-12 py-6 mt-7">
          <Stack className="grid grid-cols-10" gap={"20px"}>
            <div className="col-span-6 rounded-xl overflow-hidden max-h-[31rem]">
              {data?.data?.media_type?.toLowerCase() == "image" ? (
                <img
                  src={data?.data?.url}
                  className="w-full h-full object-cover"
                  alt=""
                />
              ) : (
                <video
                  src={data?.data?.url}
                  className="w-full h-full object-cover"
                  controls
                />
              )}
            </div>
            <div className="bg-gray-50 col-span-4 rounded-xl py-3 px-6">
              <div className="flex items-center justify-between gap-4">
                <h2 className="text-xl font-semibold">Media Details</h2>
                {userRole?.toLowerCase() === "section-owner" && (
                  <div className=" flex items-center gap-4">
                    <DocumentDownload
                      variant="Bold"
                      className="cursor-pointer"
                      onClick={() => setShowDownloadModal(true)}
                    />
                    <Trash
                      color="red"
                      className="cursor-pointer"
                      variant="Bold"
                      onClick={() => setShowDeleteModal(true)}
                    />
                  </div>
                )}
              </div>
              <div className="mt-4">
                <div>
                  <Typography variant="p" color={"#D9D9D9"}>
                    Media Title
                  </Typography>
                  <Input
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#D9D9D9",
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "#003163",
                        },
                      },
                    }}
                    fullWidth
                    value={data?.data?.title}
                    disabled
                    placeholder={"Media title"}
                  />
                </div>
                <div className="mt-4">
                  <Typography variant="p" color={"#D2D2D2"}>
                    Occurrence Attached
                  </Typography>
                  <Input
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#D9D9D9",
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "#003163",
                        },
                      },
                    }}
                    fullWidth
                    disabled
                    value={data?.data?.occurrence?.title}
                    placeholder={"occurrence attached"}
                  />
                </div>
                <div className="mt-4">
                  <Typography variant="p" color={"#D9D9D9"}>
                    Section
                  </Typography>
                  <Input
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#D9D9D9",
                        },

                        "&.Mui-focused fieldset": {
                          borderColor: "#003163",
                        },
                      },
                    }}
                    fullWidth
                    disabled
                    value={data?.data?.section?.section_name}
                    placeholder={"section"}
                  />
                </div>
                {userRole?.toLowerCase() === "section-owner" && (
                  <div className="flex justify-end mt-5">
                    <Button
                      variant="contained"
                      className="px-12 font-bold bg-primary text-sm capitalize py-2.5 rounded-2xl shadow-none"
                      onClick={() => setImageToShow(1)}
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Stack>
          <div className="grid grid-cols-10 gap-4">
            <div className="mt-6 col-span-6 w-full bg-gray-50 rounded-xl px-6 py-3 flex items-center gap-4 justify-between flex-wrap">
              <div>
                <Typography variant="p" color={"#AEAEAE"} fontSize={"12px"}>
                  Uploaded By:
                </Typography>
                <Typography fontWeight={600}>
                  {data?.data?.uploaded_by?.first_name +
                    " " +
                    data?.data?.uploaded_by?.last_name}
                </Typography>
              </div>
              <div>
                <Typography variant="p" color={"#AEAEAE"} fontSize={"12px"}>
                  Uploaded Date:
                </Typography>
                <Typography fontWeight={600}>
                  {new Date(data?.data?.uploaded_at)?.toLocaleDateString()}
                </Typography>
              </div>
              <div>
                <Typography variant="p" color={"#AEAEAE"} fontSize={"12px"}>
                  File Size:
                </Typography>
                <Typography fontWeight={600}>
                  {data?.data?.file_size}
                </Typography>
              </div>
              <div>
                <Typography variant="p" color={"#AEAEAE"} fontSize={"12px"}>
                  Upload Date:
                </Typography>
                <Typography fontWeight={600}>
                  {data?.data?.file_type?.replace(".", "")?.toUpperCase()} File
                </Typography>
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
    );
  }
};

export default MediaDetail;
