import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_API,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.accessToken
            console.log(token)
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: (builder) => ({
          logout: builder.query({
            query: () => ({
              url: '/api/xauth/signout/',
              method: 'GET',
            }),
          }),
        
    }),

})

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//     let result = await baseQuery(args, api, extraOptions)

//     if (result?.error?.originalStatus === 403) {
//         console.log('sending refresh token')
//         // send refresh token to get new access token
//         const refreshResult = await baseQuery('/refresh', api, extraOptions)
//         console.log(refreshResult)
//         if (refreshResult?.data) {
//             const user = api.getState().componentSlice.user
//             // store the new token
//             api.dispatch(setCredentials({ ...refreshResult.data, user }))
//             // retry the original query with new access token
//             result = await baseQuery(args, api, extraOptions)
//         } else {
//             api.dispatch(logOut())
//         }
//     }

//     return result
// }
export const { useLogoutMutation } = authApi;

export default authApi