import React from "react";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useFetch from "../../../helpers/useFetch";
import { ArrowLeft } from "iconsax-react";
import Loading from "../../../common/Loading";

export default function ManageOccurrenceSectionOwner() {
  const { occurrenceId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/retrieve_occurrence_information_section_for_owner/`
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack gap={"40px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack>
        <div className="grid grid-cols-3 gap-3 break-words rounded-t-2xl py-5 px-6 bg-primary text-white font-semibold">
          <h2>Occurence Name</h2>
          <h2>Occurence Owner</h2>
          <h2>Occurence Type</h2>
        </div>
        <div className="bg-white grid grid-cols-3 gap-3 rounded-b-2xl py-5 px-6 font-semibold break-words text-sm">
          <h2>{data?.data?.title}</h2>
          <h2>{data?.data?.occurrence_owner}</h2>
          <h2>{data?.data?.occurrence_type}</h2>
        </div>
      </Stack>

      <Stack>
        <div className="grid grid-cols-3 gap-3 break-words rounded-t-2xl py-5 px-6 bg-primary text-white font-semibold">
          <h2>Section Name</h2>
          <h2>Submission Date</h2>
          <h2 className="text-center">Action</h2>
        </div>
        <div className="bg-white rounded-b-2xl px-6">
          {data?.data?.occurrence_section?.map((section) => (
            <div
              key={section?.id}
              className="grid grid-cols-3 gap-3 break-words py-4 border-b last:border-b-0 border-gray-300"
            >
              <h2>{section?.section_name}</h2>
              <h2>
                {new Date(section?.submission_date)?.toLocaleDateString()}
              </h2>
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  disabled={section?.edit_lock}
                  className="shadow-none bg-primary capitalize font-semibold text-sm disabled:bg-gray-400 self-center"
                  onClick={() =>
                    navigate(
                      `/occurrence/section/manage-occurrence/${occurrenceId}/manage-sections/${section?.id}`
                    )
                  }
                >
                  Edit
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Stack>
    </Stack>
  );
}
