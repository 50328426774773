import React from "react";
import { Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export default function Breadcrumb({
  rootLink,
  rootLinkIcon,
  rootLinkPath,
  firstLinkPath,
  firstLink,
  secondLink,
  firstLinkIcon,
  secondLinkIcon,
  secondLinkPath,
  thirdLink,
  thirdLinkPath,
  thirdLinkIcon,
}) {
  return (
    <Stack alignItems={"center"} direction={"row"} gap={"16px"} px={0}>
      <Link
        to={rootLinkPath}
        style={{
          textDecoration: "none",
        }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          fontSize={12}
          gap={"4px"}
          color={"#003163"}
          lineHeight={"19.2px"}
          letterSpacing={"0.32px"}
        >
          <img src={rootLinkIcon} alt="" />
          {rootLink} <span style={{ marginLeft: "4px" }}>/</span>
        </Typography>
      </Link>

      <Link
        to={firstLinkPath}
        style={{
          textDecoration: "none",
          display: secondLink ? "block" : "none",
        }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          fontSize={12}
          gap={"4px"}
          color={"#003163"}
          lineHeight={"19.2px"}
          letterSpacing={"0.32px"}
        >
          <img src={firstLinkIcon} alt="" />
          {firstLink} <span style={{ marginLeft: "4px" }}>/</span>
        </Typography>
      </Link>

      <Typography
        sx={{
          display: secondLink ? "none" : "block",
        }}
        fontSize={12}
        gap={"4px"}
        color={secondLink ? "#003163" : "#7E7E7E"}
        lineHeight={"19.2px"}
        letterSpacing={"0.32px"}
      >
        {firstLink}{" "}
        <span
          style={{ marginLeft: "4px", display: secondLink ? "block" : "none" }}
        >
          /
        </span>
      </Typography>

      <Typography
        display={thirdLink ? "none" : "block"}
        fontSize={12}
        gap={"4px"}
        color={"#7E7E7E"}
        lineHeight={"19.2px"}
        letterSpacing={"0.32px"}
      >
        {secondLink}
      </Typography>

      <Link
        to={secondLinkPath}
        style={{
          textDecoration: "none",
          display: thirdLink ? "block" : "none",
        }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          fontSize={12}
          gap={"4px"}
          color={"#003163"}
          lineHeight={"19.2px"}
          letterSpacing={"0.32px"}
        >
          <img src={secondLinkIcon} alt="" />
          {secondLink} <span style={{ marginLeft: "4px" }}>/</span>
        </Typography>
      </Link>

      <Typography
        display={thirdLink ? "block" : "none"}
        fontSize={12}
        gap={"4px"}
        color={"#7E7E7E"}
        lineHeight={"19.2px"}
        letterSpacing={"0.32px"}
      >
        {thirdLink}
      </Typography>
    </Stack>
  );
}
