import axios from 'axios';


export const auditByModules = async (module, size) => {
    const response = await axios.post(`/api/audit/?search=${module}&size=${size}`);

    return response.data;
}

export const auditById = async (id) => {
    const response = await axios.get(`/api/audit/${id}`);

    return response.data;
}

export const audits = async () => {
    const response = await axios.post('/api/audit');
    
    return response.data;
}
