import React from "react";

import {
  Stack,
  Select,
  FormControl,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Dialog,
} from "@mui/material";
import { error, success } from "../../../common/Notifications";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import { StyledTableRow } from "../../../pages/user-data";
import ConfirmDialog from "../../../common/ConfirmDialog";
import DoneDialog from "../../../common/DoneDialog";
import { useNavigate } from "react-router-dom";
import Editor from "../../../common/Editor";
// import { Input } from "ckeditor5";
import { Input } from "../../../pages/user-management/ManageProfile";
export const severityList = [
  {
    label: "High",
    value: "High",
    colorCode: "#da1e28",
  },
  {
    label: "Medium",
    value: "Medium",
    colorCode: "#F1C21B",
  },
  {
    label: "Okay",
    value: "Okay",
    colorCode: "#25bc52",
  },
  {
    label: "None",
    value: "None",
    colorCode: "transparent",
    border: "1px solid #727272",
  },
];

const navigationList = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Media",
    value: "media",
  },
  {
    label: "Members",
    value: "members",
  },
  {
    label: "Comments",
    value: "comments",
  },
];
const memberColumns = [
  {
    id: "name",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

const SectionForm = ({
  sectionMembersData,
  data,
  occurrenceId,
  accessToken,
  userId,
  refetch,
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState({
    content: "",
  });
  const [memberForm, setMemberForm] = React.useState({
    section_member: "",
  });
  const [summary, setSummary] = React.useState("");

  React.useEffect(() => {
    if (data) {
      setFormData((prev) => ({
        ...prev,
        content: data?.occurrence_page_section[0]?.content || "",
      }));
      setSeverityForm((prev) => ({
        ...prev,
        severity: data?.severity || "",
      }));

      setSummary(data?.summary || "");
    }
  }, [data]);

  const [loading, setLoading] = React.useState(false);
  const [confirmSaveContent, setConfirmSaveContent] = React.useState(false);
  const [contentIsSaved, setContentIsSaved] = React.useState(false);
  const [isFinalContent, setIsFinalContent] = React.useState(false);
  async function handleContentSave(type) {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      let payload;
      if (type && type === "SAVE_DRAFT") {
        payload = {
          ...formData,
          is_final: false,
        };
      } else {
        payload = {
          ...formData,
          is_final: true,
        };
        setIsFinalContent(true);
      }
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${data?.id}/save_page_content/`,
        payload,
        config
      );
      success(res.data.message);
      setConfirmSaveContent(false);
      setContentIsSaved(true);
      refetch();
    } catch (err) {
      error(err.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
    setIsFinalContent(false);
  }

  // SEVERITY
  const [severityForm, setSeverityForm] = React.useState({
    severity: "",
  });
  async function handleSeveritySave(alt) {
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      let payload = severityForm;
      if (alt) {
        payload = { severity: alt };
      }
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${data?.id}/section_severity/`,
        payload,
        config
      );
      success(res.data.message);
    } catch (err) {
      error(err.response?.data?.message || "Something went wrong");
    }
  }

  // MEMBERS
  const [unassignedMembers, setUnassignedMembers] = React.useState({});
  const [assignedMembers, setAssignedMembers] = React.useState({
    section_members: [],
  });
  React.useEffect(() => {
    if (data) {
      const members = data?.occurence_section_member?.filter(
        (member) => !member?.is_owner
      );
      setAssignedMembers(members);
    }
    if (data && sectionMembersData) {
      const assignedMembers = data?.occurence_section_member?.filter(
        (member) => !member?.is_owner
      );
      const unassigned = sectionMembersData?.filter(
        (member) =>
          !assignedMembers?.find((assigned) => assigned?.member === member?.id)
      );
      setUnassignedMembers(unassigned);
    }
  }, [data, sectionMembersData]);
  const [memberLoading, setMemberLoading] = React.useState();
  async function handleMemberSave(type, id) {
    try {
      setMemberLoading(id);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      if (type === "assign") {
        const member = sectionMembersData?.find((member) => member.id === id);
        const payload = [
          {
            id: member?.id,
            email: member?.email,
          },
        ];

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${data?.id}/assign_section_members/`,
          {
            section_members: payload,
          },
          config
        );
        success(res.data.message);
        setMemberForm({ section_member: "" });
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${data?.id}/unassign_section_members/`,
          {
            unassign_section_members: [id],
          },
          config
        );
        success(res.data.message);
      }
      refetch();
    } catch (err) {
      error(err.response?.data?.message || "Something went wrong");
    }
    setMemberLoading(undefined);
  }

  // MEDIA
  const inputRef = React.useRef(null);
  const [media, setMedia] = React.useState({
    media_type: "",
    url: "",
    title: "",
    section: data?.id,
    uploaded_by: userId,
    occurrence: occurrenceId,
  });
  React.useEffect(() => {
    if (data) {
      setMedia((prev) => ({
        ...prev,
        section: data?.id,
      }));
    }
    if (userId) {
      setMedia((prev) => ({
        ...prev,
        uploaded_by: userId,
      }));
    }
    if (occurrenceId) {
      setMedia((prev) => ({
        ...prev,
        occurrence: occurrenceId,
      }));
    }
  }, [data, occurrenceId, userId]);
  function handleFileChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!file?.type?.includes("image") && !file?.type?.includes("video")) {
      error("Only images and videos are allowed");
      return;
    }
    // CHECK IF SIZE IS ABOVE 10 MB
    if (file.size > 10000000) {
      error("File size should not be above 10MB");
      return;
    }
    setMedia((prevState) => ({
      ...prevState,
      url: file,
      media_type: file.type?.includes("image") ? "IMAGE" : "VIDEO",
      title: file.name,
    }));
  }

  const [savingMedia, setSavingMedia] = React.useState(false);
  async function handleUploadMedia() {
    if (!media.media_type || !media.url || !media.title) {
      error("Select an image or video to upload");
      return;
    }
    try {
      setSavingMedia(true);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("media_type", media.media_type);
      formData.append("url", media.url);
      formData.append("alt_text", media.title);
      formData.append("title", media.title);
      formData.append("section", media.section);
      formData.append("uploaded_by", media.uploaded_by);
      formData.append("occurrence", media.occurrence);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/media-library/`,
        formData,
        config
      );
      refetch();
      success(res?.data?.message);
      setMedia((prevState) => ({
        ...prevState,
        media_type: "",
        url: "",
        title: "",
      }));
      inputRef.current.value = null;
    } catch (err) {
      error(err.response?.data?.message);
    }
    setSavingMedia(false);
  }

  // NAVIGATION
  const [activeNavigation, setActiveNavigation] = React.useState("details");
  const changeNavigation = (id) => {
    setActiveNavigation(id);
  };

  return (
    <Stack gap={"20px"}>
      <Dialog
        open={contentIsSaved}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setContentIsSaved(false)}
      >
        <DoneDialog
          title="Content Saved"
          description={`You have successfully saved the content (${data?.section_name})`}
        />
      </Dialog>
      <Stack className="overflow-x-auto mt-10">
        <ul className="flex gap-5 justify-between items-end border-b whitespace-nowrap">
          {navigationList.map((item, index) => (
            <li
              key={index}
              className={`font-medium pb-2 cursor-pointer w-full text-center ${
                activeNavigation === item?.value
                  ? "border-b-2 border-black"
                  : " text-[#AEAEAE]"
              }`}
              onClick={() => changeNavigation(item?.value)}
            >
              <p>{item.label}</p>
            </li>
          ))}
        </ul>
      </Stack>
      {activeNavigation === "details" && (
        <div>
          <Dialog
            open={confirmSaveContent}
            onClose={() => setConfirmSaveContent(false)}
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "16px",
              },
            }}
          >
            <ConfirmDialog
              isDelete={false}
              confirmText="Save Content"
              title="Confirm Save Content"
              isLoading={loading}
              description="Are you sure you want to save the content?. You will not be able to edit it later."
              handleClose={() => setConfirmSaveContent(false)}
              handleConfirm={handleContentSave}
            />
          </Dialog>
          <div className="flex justify-end mt-6">
            <Button
              disabled={data?.edit_lock}
              variant="contained"
              className="bg-primary text-white px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none disabled:opacity-50 disabled:bg-gray-500 disabled:cursor-not-allowed"
              onClick={() => setConfirmSaveContent(true)}
            >
              {loading && isFinalContent ? (
                <CircularProgress size={20} />
              ) : (
                "Submit Section"
              )}
            </Button>
          </div>
          <div className="flex gap-7">
            <Stack gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Section Severity
              </Typography>
              <FormControl>
                <Select
                  labelId="Severity"
                  id="Severity"
                  value={severityForm?.severity}
                  onChange={(e) => {
                    setSeverityForm((prevState) => ({
                      ...prevState,
                      severity: e.target.value,
                    }));
                    handleSeveritySave(e.target.value);
                  }}
                  disabled
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                  sx={{
                    "&.MuiInputBase-root": {
                      width: "220px",
                    },
                  }}
                >
                  {severityList?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.value}
                      className="text-secondary"
                      sx={{
                        outline: "0px",
                        "&.MuiMenuItem-root": {
                          backgroundColor: "#f4f4f4",
                          color: "#7E7E7E",
                          padding: "10px 14px",
                          borderBottom: "1px solid",
                          borderBottomColor: "#CBCBCB",
                          "&:last-child": { border: 0 },
                          "&:active": {
                            backgroundColor: "#051445",
                            color: "#ffffff !important",
                          },
                          "&:hover": {
                            backgroundColor: "#0C33AE",
                            color: "#ffffff !important",
                          },
                          "&:focus-visible": {
                            outline: 0,
                          },
                        },
                      }}
                    >
                      <div className="flex items-center gap-3">
                        <span
                          className="w-3 h-3 rounded-full"
                          style={{
                            backgroundColor: item?.colorCode,
                            border: item?.border,
                          }}
                        ></span>
                        <span>{item?.label}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurrence Summary
              </Typography>
              <div
                className="w-full bg-transparent border border-gray-300 rounded-md px-3 py-2 min-h-[56px] max-h-[10rem] overflow-y-auto"
                dangerouslySetInnerHTML={{
                  __html: summary,
                }}
              />
            </Stack>
          </div>

          <Stack className="flex gap-2 mt-6">
            <Typography variant="p" color={"#D8D8D8"}>
              Section Content
            </Typography>
            <Editor
              value={formData.content}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  content: e,
                }))
              }
            />
          </Stack>
          <div className="flex justify-end mt-6">
            <Button
              disabled={data?.edit_lock}
              variant="contained"
              className="bg-primary text-white px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none disabled:opacity-50 disabled:bg-gray-500 disabled:cursor-not-allowed"
              onClick={() => handleContentSave("SAVE_DRAFT")}
            >
              {loading && !isFinalContent ? (
                <CircularProgress size={20} />
              ) : (
                "Save Draft"
              )}
            </Button>
          </div>
        </div>
      )}
      {activeNavigation === "media" && (
        <div>
          <Stack className="flex gap-2 mt-6">
            <Typography variant="p" color={"#D8D8D8"}>
              Upload Media
            </Typography>
            <div
              className="h-[14rem] border border-dashed border-[#D8D8D8] rounded-lg flex items-center justify-center text-center mt-2 cursor-pointer overflow-hidden"
              onClick={() => inputRef.current.click()}
            >
              {media.url ? (
                media?.media_type?.toLowerCase() === "image" ? (
                  <img
                    src={URL?.createObjectURL(media?.url)}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <video
                    src={URL?.createObjectURL(media?.url)}
                    className="w-full h-full object-cover"
                    controls
                  />
                )
              ) : (
                <div>
                  <Icon
                    icon="ri:upload-cloud-fill"
                    className="text-4xl text-secondary w-fit mx-auto"
                  />
                  <h2 className="font-bold text-xl pt-3">Upload Media</h2>
                  <p className="text-sm font-medium max-w-[60%] mx-auto">
                    Ensure to upload a PNG, JPEG, SVG or MP4 doc of at least 10
                    MB
                  </p>
                </div>
              )}
            </div>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={media.title}
              onChange={(e) =>
                setMedia((prev) => ({ ...prev, title: e.target.value }))
              }
              placeholder="Enter title"
            />
            <input
              ref={inputRef}
              onChange={handleFileChange}
              type="file"
              className="hidden"
            />
            <Button
              disabled={data?.edit_lock}
              variant="outlined"
              className="border-primary text-primary px-6 font-bold text-sm py-2.5 capitalize rounded-xl shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              onClick={handleUploadMedia}
            >
              {savingMedia ? <CircularProgress size={20} /> : "Upload Media"}
            </Button>
          </Stack>
          {data?.occurrence_section_media &&
          data?.occurrence_section_media?.length ? (
            <div className="grid grid-cols-3 gap-5 mt-7">
              {data?.occurrence_section_media?.map((item, index) => (
                <div
                  className="aspect-[4/3] rounded-md overflow-hidden cursor-pointer"
                  key={index}
                  onClick={() => navigate(`/media/${item?.id}`)}
                >
                  {item?.media_type === "VIDEO" ? (
                    <video
                      src={item?.url}
                      controls
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <img
                      src={item?.url}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {activeNavigation === "members" && (
        <div className="mt-8">
          <div className="mb-10 flex items-end gap-10">
            <Stack gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Section Members
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={memberForm?.section_member}
                  onChange={(e) => {
                    setMemberForm((prevState) => ({
                      ...prevState,
                      section_member: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold min-w-[20rem]"
                >
                  <MenuItem value="">
                    <p className=" text-secondary ">
                      {"Select Section Member"}
                    </p>
                  </MenuItem>
                  {unassignedMembers?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      className="text-secondary"
                    >
                      {item?.last_name + " " + item?.first_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <div>
              <Button
                disabled={data?.edit_lock}
                variant="contained"
                className="capitalize bg-primary px-10 py-2.5 rounded-2xl text-white font-bold disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() =>
                  handleMemberSave("assign", memberForm?.section_member)
                }
              >
                {memberLoading == memberForm?.section_member ? (
                  <CircularProgress size={20} />
                ) : (
                  "Add Member"
                )}
              </Button>
            </div>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {memberColumns?.map((column, index) => (
                    <TableCell key={index}>
                      <Typography
                        fontWeight={500}
                        fontSize={"13px"}
                        color={"#AEAEAE"}
                        align={column?.align}
                        style={{
                          minWidth: column.minWidth,
                        }}
                      >
                        {column?.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {assignedMembers?.length > 0 ? (
                  assignedMembers?.map((item, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>{item?.user}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{item?.user_email}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          disabled={data?.edit_lock}
                          className="text-sm shadow-none capitalize bg-primary text-white rounded-lg disabled:bg-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
                          onClick={() =>
                            handleMemberSave("unassign", item?.member)
                          }
                        >
                          {memberLoading === item?.member ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Unassigned"
                          )}
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <TableCell colSpan={4} align="center" className="py-10">
                      <Typography
                        fontWeight={500}
                        fontSize={"13px"}
                        color={"#AEAEAE"}
                      >
                        No Member assigned to this section
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {activeNavigation === "comments" && (
        <Stack className="py-10">
          {data?.occurrence_section_comments &&
          data?.occurrence_section_comments?.length > 0 ? (
            data?.occurrence_section_comments?.map((item, index) => (
              <div className="mb-10" key={index}>
                <div className="flex items-end gap-2 pl-4">
                  <h2 className="font-bold">{item?.name}</h2>
                  <p className="italic text-xs">{item?.time}</p>
                </div>
                <div className="bg-slate-100 p-5 mt-2 rounded-xl shadow">
                  <div>
                    <p className="underline">Reason:</p>
                    <h2 className="text-xl font-semibold">{item?.reason}</h2>
                  </div>
                  <div className="mt-6">
                    <p className="underline">Details:</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.content,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <h2 className="text-xl font-semibold">No Comment</h2>
              <p>You have no comment for this section yet</p>
            </div>
          )}
          <div></div>
        </Stack>
      )}
    </Stack>
  );
};

export default SectionForm;
