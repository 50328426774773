import React from "react";
import CloudIcon from "@mui/icons-material/Cloud";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import { Drone } from "../../assets/media";
import { Plane } from "../../assets";
import CustomModal from "../../components/media/Modal";
import { Button, TextField } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { useOutletContext } from "react-router-dom";
import Loading from "../../common/Loading";
import { error, success } from "../../common/Notifications";
import axiosInstance from "../../helpers/axiosInstance";

const craftFirstRow = [
  { id: 1, name: "Weather", icon: <CloudIcon />, status: "open" },
  {
    id: 2,
    name: "FOD/Bird/Drone Strike",
    icon: <img src={Drone} />,
    status: "open",
  },
  { id: 3, name: "Fire", icon: <LocalFireDepartmentIcon />, status: "open" },
  { id: 4, name: "Systems", icon: <DesktopWindowsIcon />, status: "danger" },
  {
    id: 5,
    name: "Operations",
    icon: <DesktopWindowsIcon />,
    status: "success",
  },
  {
    id: 6,
    name: "Crew/Passenger/ Survivability",
    icon: <DesktopWindowsIcon />,
    status: "success",
  },
  { id: 7, name: "Recorders", icon: <DesktopWindowsIcon />, status: "danger" },
  {
    id: 8,
    name: "Flight Controls",
    icon: <DesktopWindowsIcon />,
    status: "danger",
  },
];

const craftSecondRow = [
  { id: 1, name: "Flight History", icon: <CloudIcon />, status: "open" },
  { id: 2, name: "Transcripts", icon: <img src={Drone} />, status: "open" },
  { id: 3, name: "ATC", icon: <LocalFireDepartmentIcon />, status: "open" },
  { id: 4, name: "SOPs", icon: <DesktopWindowsIcon />, status: "danger" },
  {
    id: 5,
    name: "Structures",
    icon: <DesktopWindowsIcon />,
    status: "success",
  },
  {
    id: 6,
    name: "Aircraft Details",
    icon: <DesktopWindowsIcon />,
    status: "success",
  },
  { id: 7, name: "Witnesses", icon: <DesktopWindowsIcon />, status: "danger" },
  {
    id: 8,
    name: "Aerodrome / Crash Site",
    icon: <DesktopWindowsIcon />,
    status: "danger",
  },
];

function SingleOccurrenceIndex() {
  const { occurrenceId } = useParams();
  const [publishedData, data, isLoading, refetch] = useOutletContext();
  const [loading, setLoading] = React.useState(false);

  const initialForm = {
    email: "",
    occurrence: occurrenceId,
  };

  const [subscribeForm, setSubscribeForm] = React.useState(initialForm);

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleEditModalOpen = () => setOpenEditModal(true);
  const handleEditModalClose = () => setOpenEditModal(false);

  const cardDetails = [
    {
      id: 1,
      title: "Occurrence Date",
      subtitle: data?.data?.occurrence_date || "-",
    },
    {
      id: 2,
      title: "Occurrence Type",
      subtitle: data?.data?.occurrence_type || "-",
    },
    {
      id: 3,
      title: "Aircraft Registration Number",
      subtitle: data?.data?.aircraft_registration_number || "-",
    },
    {
      id: 4,
      title: "Location",
      subtitle: data?.data?.occurrence_details?.location || "-",
    },
    {
      id: 5,
      title: "State",
      subtitle: data?.data?.occurrence_details?.state || "-",
    },
    {
      id: 6,
      title: "Country",
      subtitle: data?.data?.occurrence_details?.country || "-",
    },
    {
      id: 7,
      title: "Airline",
      subtitle: data?.data?.occurrence_details?.airline || "-",
    },
    {
      id: 8,
      title: "Aircraft Type",
      subtitle: data?.data?.occurrence_details?.aircraft_type || "-",
    },
    {
      id: 9,
      title: "Flight Destination",
      subtitle: data?.data?.occurrence_details?.flight_destination || "-",
    },
    {
      id: 10,
      title: "Flight Departure",
      subtitle: data?.data?.occurrence_details?.flight_departure || "-",
    },
    {
      id: 11,
      title: "Flight Phase",
      subtitle: data?.data?.occurrence_details?.flight_phase || "-",
    },
    { id: 12, title: "Last Updated", subtitle: data?.data?.updated_at || "-" },
  ];

  const handleSubscribeFormSubmit = async () => {
    console.log(subscribeForm);

    setLoading(true);

    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/subscribe/`,
        subscribeForm
      );
      console.log(res);
      success(res.data?.message);
      setLoading(false);
      setSubscribeForm(initialForm);
      handleEditModalClose();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
      console.log(err);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="bg-white py-6 px-8  shadow-md rounded-lg">
        <div className="flex justify-between items-center">
          <div className="w-2/3">
            <p className="font-bold text-base">{data?.data?.title}</p>
            <div className="flex items-center gap-4 mt-2">
              <div>
                <p className="text-secondary text-xs">Occurrence Date</p>
                <p className="text-sm">{data?.data?.occurrence_date}</p>
              </div>
              <div className="border border-l border-solid h-11 border-secondary"></div>
              <div>
                <p className="text-secondary text-xs">Status</p>
                <p className="text-primary font-bold text-sm">
                  {data?.data?.occurrence_report_status}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-xs">Subscribe and get Occurrence updates</p>
            <button
              className="mt-3 px-[70px] py-2.5 bg-primary text-white w-full"
              onClick={handleEditModalOpen}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white py-6 px-8  mt-6 shadow-md rounded-lg">
        <div className="grid grid-cols-6 gap-x-6 gap-y-2 w-full">
          {cardDetails.map((item, index) => (
            <div key={item.id} className="space-y-3 ">
              <p className="text-secondary text-xs">{item?.title}</p>
              <p className="font-sm">{item?.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-10 px-14 ">
        <div className="grid grid-cols-4 gap-y-[50px] ">
          {publishedData.map((item, index) => (
            <Link
              to={`/occurence-index/${occurrenceId}/${item?.id}/${item?.slug_name}/`}
              className="flex items-center gap-4"
              key={index}
            >
              <div className="w-[17px] h-[17px]">
                {item?.severity === "Okay" ? (
                  <div className="w-[17px] h-[17px] border border-solid rounded-full border-green-800">
                    <span className="w-full h-full p-0.5 rounded-full bg-green-800 block"></span>
                  </div>
                ) : item?.severity === "High" ? (
                  <div className="w-[17px] h-[17px] rounded-full border border-red-800">
                    <span className="w-full h-full p-0.5 rounded-full bg-red-800 animate-pulse block"></span>
                  </div>
                ) : item?.severity === "Medium" ? (
                  <div className="w-[17px] h-[17px] rounded-full border border-yellow-500">
                    <span className="w-full h-full p-0.5 rounded-full bg-yellow-500 animate-pulse block"></span>
                  </div>
                ) : (
                  <div className="w-[17px] h-[17px] rounded-full border border-gray-500">
                    <span className="w-full h-full p-0.5 rounded-full bg-gray-500 animate-pulse block"></span>
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2">
                {item?.section_name?.includes("flight") ||
                item?.section_name?.includes("Flight") ||
                item?.section_name?.includes("bird") ||
                item?.section_name?.includes("drone") ? (
                  <img src={Drone} />
                ) : item?.section_name?.includes("fire") ? (
                  <LocalFireDepartmentIcon />
                ) : item?.section_name?.includes("weather") ? (
                  <CloudIcon />
                ) : (
                  <DesktopWindowsIcon />
                )}
                <p className="">{item?.section_name}</p>
              </div>
            </Link>
          ))}
        </div>

        <div className="mt-10"></div>
      </div>
      <CustomModal
        openModal={openEditModal}
        handleModalClose={handleEditModalClose}
        title={`Subcribe to occurrence`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-[482px] space-y-6 py-16 ">
            <div>
              <p className=" text-xs text-secondary">Email address</p>
              <TextField
                id="input-with-icon-textfield"
                placeholder="youremail@address.com"
                variant="filled"
                value={subscribeForm?.email}
                fullWidth
                size="small"
                onChange={(e) =>
                  setSubscribeForm({ ...subscribeForm, email: e.target.value })
                }
              />
            </div>
            <Button
              variant="contained"
              onClick={handleSubscribeFormSubmit}
              className="bg-primary capitalize py-4 rounded-none px-7  text-sm font-normal w-full"
            >
              {loading ? "Please wait..." : "Subscribe"}
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default SingleOccurrenceIndex;
