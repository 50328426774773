import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as userAPI from '../../../apis/users';

export const fetchUser = createAsyncThunk(
    'users/fetchUser',
    async (payload, thunkAPI) => {
        const response = await userAPI.getUser(thunkAPI);
        return response;
    }
);

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async () => {
        const response = await userAPI.getUsers();
        return response;
    }
);

export const fetchGroups = createAsyncThunk(
    'users/fetchGroups',
    async () => {
        const response = await userAPI.getUsersGroup();
        return response;
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (userId, body) => {
        const response = await userAPI.updateUser(userId, body);
        return response;
    }
);

export const updateImage = createAsyncThunk(
    'users/updateImage',
    async (formdata) => {
        const response = await userAPI.updateImage(formdata);
        return response;
    }
);

export const activateUser = createAsyncThunk(
    'users/activate',
    async (userId) => {
        const response = await userAPI.activateUser(userId);
        return response;
    }
);

export const deactivateUser = createAsyncThunk(
    'users/deactivate',
    async (userId) => {
        const response = await userAPI.deactivateUser(userId);
        return response;
    }
);


const usersSlice = createSlice({
    name: 'users',
    initialState: {
        user: null,
        loading: false,
        status: 'idle'
    },
    reducers: {
        clearUserData: (state) => {
            state.user = null;
            state.loading = false;
            state.status = 'idle'
        },
    },

    extraReducers: builder => {
        // Fetch single user
        builder
            .addCase(fetchUser.pending, (state) => {
                return {
                    ...state,
                    loading: true,
                }
            })
            .addCase(fetchUser.fulfilled, (state, { payload }) => {
                console.log(payload)
                return {
                    ...state,
                    loading: false,
                    user: payload?.data,
                    status: payload?.status,
                }
            })
            .addCase(fetchUser.rejected, (state, { payload }) => {
                return {
                    ...state,
                    loading: false,
                    status: payload?.status,
                    error: payload,
                }
            });

        // Fetch user list
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userList = action.payload.userList;
            })
            .addCase(fetchUsers.rejected, (state) => {
                state.status = 'failed';
                state.userList = [];
            });

        // Fetch user groups
        builder
            .addCase(fetchGroups.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGroups.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userList = action.payload.userList;
            })
            .addCase(fetchGroups.rejected, (state) => {
                state.status = 'failed';
                state.userList = [];
            });

        // Update user
        builder
            .addCase(updateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
            })
            .addCase(updateUser.rejected, (state) => {
                state.status = 'failed';
            });

        // Update user image
        builder
            .addCase(updateImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
            })
            .addCase(updateImage.rejected, (state) => {
                state.status = 'failed';
            });

        // Activate user
        builder
            .addCase(activateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(activateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
            })
            .addCase(activateUser.rejected, (state) => {
                state.status = 'failed';
            });

        // Deactivate user
        builder
            .addCase(deactivateUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deactivateUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
            })
            .addCase(deactivateUser.rejected, (state) => {
                state.status = 'failed';
            });

    }
});
export const { clearUserData } = usersSlice.actions;

export default usersSlice.reducer;
