import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useOnClickOutside } from "usehooks-ts";

const CustomSelect = ({
  className,
  value,
  value_attribute = "value",
  list,
  searchable = true,
  placeholder = "Select",
  searchValue = "name",
  disabled = false,
  isLoading,
  maxHeight,
  isTop = false,
  onChange,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(list);

  useEffect(() => {
    setFilteredData(
      list?.filter((item) => item[searchValue].toLowerCase().includes(query))
    );
  }, [query, list]);

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className="relative">
      <div
        className={
          "w-full border border-black/25 hover:border-black/60 rounded-lg min-w-0 flex justify-between items-center px-3 py-[15px] cursor-pointer font-semibold min-h-[56px]" +
          className +
          (disabled ? " pointer-events-none text-secondary" : "")
        }
        onClick={() => setOpen(!open)}
      >
        <p className={"truncate" + (value ? "" : " text-secondary/60")}>
          {value && list && list?.length
            ? list?.find((item) => item[value_attribute] == value)[searchValue]
            : placeholder}
        </p>
        <Icon icon="fe:drop-down" />
      </div>
      {open && (
        <div
          ref={ref}
          className={`absolute z-10 ${
            isTop ? "bottom-0" : "top-0"
          } left-1/2 -translate-x-1/2 bg-white w-full rounded-md pt-6 pb-2 shadow-md`}
        >
          {searchable && (
            <div className="px-5 ">
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                className="border border-gray-400 py-2 px-3.5 rounded-md w-full outline-none"
              />
            </div>
          )}

          {isLoading && (
            <div className="flex justify-center items-center h-20 mt-4">
              <Icon
                className="animate-spin"
                icon="eos-icons:loading"
                width={30}
              />
            </div>
          )}
          <ul
            className={`${maxHeight || "max-h-[30rem]"} overflow-y-auto mt-2`}
          >
            {filteredData?.map((item, index) => (
              <li
                key={index}
                className="py-2 px-5 font-medium hover:bg-blue-100 text-stone-500 duration-200 cursor-pointer"
                onClick={() => {
                  onChange(item[value_attribute]);
                  setOpen(false);
                }}
              >
                {item[searchValue]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
