import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { success, warning } from '../../../common/Notifications';



const API = process.env.REACT_APP_BASE_API

export const accessKey = async (data, thunkAPI) => {

    try {
        const key = data.key
        const occurrenceId = data.occurrenceId
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const res = await axios.get(
            `${API}api/occurrence-manager/stakeholder_access/${key}/${occurrenceId}/`,
            config
        )
        console.log(res.data)
        return res.data
    } catch (error) {
        console.log(error)
        return thunkAPI.rejectWithValue(error.response.data)

    }
}


export const getAccessKey = createAsyncThunk(
    'stakeholder/getAccessKey',
    async (data, thunkAPI) => {
        const response = await accessKey(data, thunkAPI);
        return response;
    }
);

const stakeholderAccessSlice = createSlice({
    name: 'stakeholder',
    initialState: {
        email: null,
        name: null,
        loading: false,
        access: false,
        status: 'idle'
    },

    extraReducers: builder => {
        builder
            .addCase(getAccessKey.pending, (state) => {
                return {
                    ...state,
                    loading: true,
                }
            })
            .addCase(getAccessKey.fulfilled, (state, { payload }) => {
                success(payload.message)
                return {
                    ...state,
                    loading: false,
                    email: payload?.data?.email,
                    name: payload?.data?.name,
                    status: payload?.status,
                    access: true
                }
            })
            .addCase(getAccessKey.rejected, (state, { payload }) => {
                warning(payload.message)
                return {
                    ...state,
                    loading: false,
                    status: payload?.status,
                    access: false,
                    email: null,
                }
            });

    }
});

export default stakeholderAccessSlice.reducer;
