import { Store } from 'react-notifications-component';
import { toast } from "react-toastify";



// const success = (message) => Store.addNotification({
//     title: "Success",
//     message: message,
//     type: "success",
//     insert: "top",
//     container: "top-right",
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: {
//         duration: 3000,
//         onScreen: true
//     }
// });

function success(message) {
    toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
}

// const error = (message) => Store.addNotification({
//     title: "Error",
//     message: message,
//     type: "danger",
//     insert: "top",
//     container: "top-right",
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: {
//         duration: 3000,
//         onScreen: true
//     }
// });

function error(message) {
    toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
}

// const warning = (message) => Store.addNotification({
//     title: "Error",
//     message: message,
//     type: "warning",
//     insert: "top",
//     container: "top-right",
//     animationIn: ["animate__animated", "animate__fadeIn"],
//     animationOut: ["animate__animated", "animate__fadeOut"],
//     dismiss: {
//         duration: 3000,
//         onScreen: true
//     }
// });

function warning(message) {
    toast.warn(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
}


export { success, error, warning }