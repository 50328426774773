import React from "react";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import InputField from "./InputField";
import { editNoteIcon } from "../../assets";

function NewMediaUploadForm({
  image,
  closeModal,
  handleOpenAlert,
  uploadComplete,
  widget,
  mediaForm,
  setMediaForm,
  formSubmit,
}) {
  async function Done() {
    await closeModal();
    await uploadComplete(false);
    await handleOpenAlert();
  }
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      width={"100%"}
      justifyContent={"space-between"}
    >
      <Stack
        width={"100%"}
        flex={0.45}
        sx={{
          position: "relative",
        }}
      >
        {/* <img src={URL.createObjectURL(image)} style={{ maxWidth: '100%', width: 'fit-content', maxHeight: '100%', borderRadius: '4px', height: 'fit-content',}} /> */}
        <img style={{}} alt="" />
        <Stack
          sx={{
            background: `url(${URL.createObjectURL(image)})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          width={"100%"}
          height={"200px"}
          borderRadius={"4px"}
        ></Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          marginTop={"10px"}
        >
          <Button
            variant="contained"
            disableElevation
            sx={{
              p: 0,
              minWidth: "32px",
              height: "32px",
              maxWidth: "fit-content",
              fontFamily: "PT Sans",
              marginRight: "14px",
              color: "#ffffff",
              backgroundColor: "#003163",
              borderRadius: 0,
              fontFamily: "PT Sans",
              textTransform: "none",
              fontSize: 14,
              "&.MuiButton-contained": {
                background: "#003163",
              },
            }}
          >
            <img src={editNoteIcon} alt="" />
          </Button>

          <IconButton
            sx={{
              width: "32px",
              aspectRatio: "1/1",
              color: "#DA1E28",
              border: "1px solid #DA1E28",
              borderRadius: 0,
              p: "7px",
            }}
          >
            <i
              className="bi bi-trash-fill"
              style={{
                fontSize: 14,
                marginLeft: "auto",
                marginRight: "auto",
                p: 0,
              }}
            ></i>
          </IconButton>
        </Stack>
      </Stack>
      <Stack flex={0.45} width={"100%"} gap={"18px"}>
        <div>
          <p className=" text-xs text-secondary">Title</p>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Image Title"
            variant="filled"
            fullWidth
            onChange={(e) =>
              setMediaForm((prevState) => ({
                ...prevState,
                title: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <p className=" text-xs text-secondary">Alt Text</p>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Image Alternative Text"
            variant="filled"
            fullWidth
            onChange={(e) =>
              setMediaForm((prevState) => ({
                ...prevState,
                alt_text: e.target.value,
              }))
            }
          />
        </div>
        <div>
          <p className=" text-xs text-secondary">Footnote (Optional)</p>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Image footnote"
            variant="filled"
            fullWidth
            multiline
            rows={3}
            onChange={(e) =>
              setMediaForm((prevState) => ({
                ...prevState,
                foot_note: e.target.value,
              }))
            }
          />
        </div>

        <Button
          variant="contained"
          disableElevation
          sx={{
            p: 0,
            minWidth: "120px",
            height: "32px",
            maxWidth: "fit-content",
            fontFamily: "PT Sans",
            marginRight: "14px",
            color: "#ffffff",
            backgroundColor: "#003163",
            borderRadius: 0,
            fontFamily: "PT Sans",
            textTransform: "none",
            fontSize: 14,
            "&.MuiButton-contained": {
              background: "#003163",
            },
          }}
          onClick={() => {
            const updatedImageForm = { ...mediaForm };
            mediaForm.image_url = image;
            setMediaForm(updatedImageForm);
            formSubmit();
          }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

function ImageUploader({
  open,
  handleClose,
  openAlert,
  whiteBg,
  content,
  widget,
  mediaForm,
  setMediaForm,
  formSubmit,
}) {
  const [dragActive, setDragActive] = React.useState(false);
  const [uploadStart, setUploadStart] = React.useState(false);
  const [uploadComplete, setUploadComplete] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (uploadStart) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress >= 100 ? 100 : prevProgress + 1;
          if (newProgress === 100) {
            setUploadStart(false);
            setUploadComplete(true);
          }
          return newProgress;
        });
      }, 80);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploadStart]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setProgress(0);
      setUploadStart(true);
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setProgress(0);
      setUploadStart(true);
      setImage(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Stack
      bgcolor={whiteBg ? "#fff" : "#F4F4F4"}
      px={uploadComplete ? "" : "32px"}
      py={content ? "32px" : "18px"}
      pt={"32px"}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        height={"250px"}
        sx={{
          backgroundImage:
            widget && uploadComplete
              ? "none"
              : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237E7E7EFF' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
        }}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          className="hidden h-full"
          onChange={handleChange}
        />
        {uploadComplete ? (
          <NewMediaUploadForm
            widget={widget}
            image={image}
            handleOpenAlert={openAlert}
            closeModal={handleClose}
            uploadComplete={setUploadComplete}
            mediaForm={mediaForm}
            setMediaForm={setMediaForm}
            formSubmit={formSubmit}
          />
        ) : uploadStart ? (
          <Stack
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography color={"#7e7e7e"} fontSize={16} fontFamily={"PT Sans"}>
              Uploading {content ? "content" : "file"}..{" "}
              {`(${Math.round(progress)}% Uploaded)`}
            </Typography>
          </Stack>
        ) : dragActive ? (
          <Stack
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Typography fontSize={16} color={"#7e7e7e"} fontFamily={"PT Sans"}>
              Drop the {content ? "content" : "file"} here
            </Typography>
          </Stack>
        ) : (
          <Stack
            onClick={onButtonClick}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"14px"}
            sx={{ cursor: "pointer" }}
          >
            <Typography fontSize={16} color={"#7e7e7e"} fontFamily={"PT Sans"}>
              Drag and Drop Image Here
            </Typography>
            <Button
              disableElevation
              variant="contained"
              onClick={onButtonClick}
              sx={{
                display: content ? "none" : "block",
                "&.MuiButton-contained": {
                  background: "#003163",
                  textTransform: "none",
                  fontFamily: "PT Sans",
                  width: "150px",
                  height: "45px",
                  fontSize: "14px",
                  borderRadius: 0,
                },
              }}
            >
              Add Content
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default ImageUploader;
