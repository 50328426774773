import React, { useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  Dialog,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Menu,
  MenuItem,
} from "@mui/material";
import { Breadcrumb, InputField } from "../../../components";
import {
  fileIconPrimary,
  dashboardIcon,
  fileIconLight,
  editNotePrimaryIcon,
} from "../../../assets";
import {
  avatar,
  editNoteIcon,
  trashIcon,
  historyIcon,
  pointIcon,
  TextIcon,
  VideoIcon,
  DataTableIcon,
  ImageIcon,
  MapIcon,
  UrlIcon,
} from "../../../assets";
import FileUploader from "../../../components/common/FileUploader";
import { Editor } from "@tinymce/tinymce-react";
import { DialogComponent } from "../../../components";
import VideoUploader from "../../../components/common/VideoUploader";
import ImageUploader from "../../../components/common/ImageUploader";
import { userRole } from "../../../constants/User";
import { One } from "../../../assets/media";
import { Form } from "../../user-management/ManageProfile";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useFetch from "../../../helpers/useFetch";
import SanitizeHTML from "./SanitizeHTML";
import { useSelector } from "react-redux";
import { success, error } from "../../../common/Notifications";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "@mui/material";

const Comments = [
  {
    id: 1,
    userName: "James Badu",
    timeCommented: "2 mins ago",
    content: `I was there on the day and this is the one talk I'll be sharing with designers still getting their head around design tokens. Thanks for the work guys - great presentation.`,
  },
  {
    id: 2,
    userName: "James Badu",
    timeCommented: "2 mins ago",
    content: `I was there on the day and this is the one talk I'll be sharing with designers still getting their head around design tokens. Thanks for the work guys - great presentation.`,
  },
];

const auditTrail = [
  {
    id: "40grt50t5yth",
    userName: "Mohammed Bala",
    activity: "edited occurence information",
    details: "Today 8:02 AM",
  },
  {
    id: "40grt50tyth",
    userName: "Mohammed Bala",
    activity: "edited report information",
    details: "Yesterday",
  },
  {
    id: "40gt50tyth",
    userName: "Mohammed Bala",
    activity: "created occurence report",
    details: "Yesterday",
  },
];

export default function ContentManagement() {
  const { id } = useParams();
  const { sectionId } = useParams();
  const user = useSelector((state) => state.users?.user?.id);
  const user_email = useSelector((state) => state.users?.user?.email);
  const [reply, setReply] = React.useState(false);
  const [selectedCommentIndex, setSelectedCommentIndex] = React.useState(null);

  const userRoles = useSelector((state) => state.users?.user?.roles);

  const editOccureenceContentRole =
    userRoles.occurence_manager?.content_management?.edit;
  const createOccureenceContentRole =
    userRoles.occurence_manager?.content_management?.create;

  const initialCommentText = {
    content: "",
    section_page: sectionId,
    email: `${user_email}`,
    reply: "",
  };

  const {
    data: dataComments,
    isLoading: isLoadingComments,
    refetch: isLoadingRefresh,
  } = useFetch(`api/occurrence-manager/comment/${sectionId}/`);

  const initialWidgetTitleForm = {
    title: "",
    page_type: "",
    section_page: `${id}`,
    link: ``,
  };

  const [commentText, setCommentText] = React.useState(initialCommentText);
  const [replyText, setReplyText] = React.useState(initialCommentText);

  const initialMediaForm = {
    title: "",
    url: "",
    alt_text: "",
    foot_note: "",
    page_content_id: "",
    uploaded_by: user,
  };
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [medialPageForm, setMedialPageForm] = React.useState(initialMediaForm);

  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/section-page-contents/${id}/`
  );

  const [pageContents, setPageContents] = React.useState([]);

  React.useEffect(() => {
    if (!isLoading) {
      setPageContents(data?.data?.occurrence_page_section_contents);
    }
  }, [data, isLoading]);
  const [editTextWidgetDialog, setEditTextWidgetDialog] = useState(false);
  const [editDataTableWidgetDialog, setEditDataTableWidgetDialog] =
    useState(false);
  const [editImageWidgetDialog, setEditImageWidgetDialog] = useState(false);
  const [editVideoWidgetDialog, setEditVideoWidgetDialog] = useState(false);
  const [newWidgetForm, setNewWidgetForm] = useState(initialWidgetTitleForm);

  const [deleteSection, setDeleteSection] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editSectionTitleDialogState, setEditSectionTitleDialogState] =
    useState(false);
  const History = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [textWidget, setTextWidget] = useState(false);
  const [imageWidget, setImageWidget] = useState(false);
  const [videoWidget, setVideoWidget] = useState(false);
  const [tableWidget, setTableWidget] = useState(false);
  const [urlWidget, setUrlWidget] = useState(false);
  const [mapWidget, setMapWidget] = useState(false);
  const [urlDialog, setUrlDialog] = useState(false);
  const [mapDialog, setMapDialog] = useState(false);
  const [formState, manageFormState] = useState({
    url: "",
    map: "",
    widgetTitle: "",
    urlTitle: "",
    comments: "",
  });
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const open = Boolean(anchorEl);

  if (isLoading) {
    return <div>Loading.....</div>;
  }
  async function handleAddSectionPageTitleSubmit(Type) {
    setLoading(true);
    const updatedPageContents = { ...newWidgetForm };
    newWidgetForm.page_type = Type;
    setNewWidgetForm(updatedPageContents);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };

      // Use the updated addSection in the API call
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/page-content/`,
        newWidgetForm,
        config
      );
      console.log(res);
      success(res.data.message);
      setLoading(false);
      setEditTextWidgetDialog(false);
      setNewWidgetForm(initialWidgetTitleForm);
      refetch();
    } catch (err) {
      error(err.response?.data.message);
      setLoading(false);
      console.log(err);
      refetch();
    }
  }

  async function handleAddMediaPageSubmit(id) {
    setLoading(true);
    const updatedPageContents = { ...medialPageForm };
    medialPageForm.page_content_id = id;
    setMedialPageForm(updatedPageContents);

    console.log(medialPageForm, "crazy eh?");
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      // Use the updated addSection in the API call
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/page-content/add_media_to_page_content/`,
        medialPageForm,
        config
      );
      console.log(res);
      success(res.data.message);
      setLoading(false);
      setMedialPageForm(initialMediaForm);
      refetch();
    } catch (err) {
      error(err.response?.data.message);
      setLoading(false);
      console.log(err);
      refetch();
    }
  }

  async function handleAddSectionPageContentSubmit(index, id) {
    setLoading(true);
    console.log(id);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };

      // Use the updated addSection in the API call
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/update-section-page-contents/${id}/`,
        pageContents[index],
        config
      );
      console.log(res);
      success(res.data.message);
      setLoading(false);

      refetch();
    } catch (err) {
      error(err.response?.data.message);
      setLoading(false);
      console.log(err);
      refetch();
    }
  }

  async function handleCommentSubmit() {
    console.log(commentText, "<<<<comment text>>>");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/comment-user/`,
        commentText,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": "csrftoken",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `JWT ${accessToken}`,
          },
        }
      );
      success(res.data.message);
      setLoading(false);
      setCommentText(initialCommentText);
      isLoadingRefresh();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
      console.log(err);
    }
  }

  async function handleReplySubmit(reply) {
    const updatedcomment = { ...replyText };
    updatedcomment.reply = reply;
    setReplyText(updatedcomment);

    console.log(replyText, "reply text");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/comment-user/`,
        updatedcomment,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": "csrftoken",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `JWT ${accessToken}`,
          },
        }
      );
      success(res.data.message);
      setLoading(false);
      setReplyText(initialCommentText);
      isLoadingRefresh();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
      console.log(err);
    }
  }
  return (
    <Stack gap={"32px"}>
      {/* Text Widget Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={editTextWidgetDialog}
        onClose={() => setEditTextWidgetDialog(false)}
      >
        <Stack width={"640px"} height={"309px"} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Edit Text Widget Title
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setEditTextWidgetDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack height={"100%"} p={"32px"} justifyContent={"center"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter new title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("TEXT");
                setTextWidget(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "45px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* Data table Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={editDataTableWidgetDialog}
        onClose={() => setEditDataTableWidgetDialog(false)}
      >
        <Stack width={"640px"} height={"309px"} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Edit Datatable Widget Title
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setEditDataTableWidgetDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack height={"100%"} p={"32px"} justifyContent={"center"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter new title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("DATATABLE");
                setEditDataTableWidgetDialog(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "45px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* Image Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={editImageWidgetDialog}
        onClose={() => setEditImageWidgetDialog(false)}
      >
        <Stack width={"640px"} height={"309px"} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Edit Image Title
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setEditImageWidgetDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack height={"100%"} p={"32px"} justifyContent={"center"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter new title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("IMAGE");
                setEditImageWidgetDialog(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "45px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      {/* Video Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={editVideoWidgetDialog}
        onClose={() => setEditVideoWidgetDialog(false)}
      >
        <Stack width={"640px"} height={"309px"} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Edit Video Title
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setEditVideoWidgetDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack height={"100%"} p={"32px"} justifyContent={"center"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter new title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("VIDEO");
                setEditVideoWidgetDialog(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "45px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={editSectionTitleDialogState}
        onClose={() => setEditSectionTitleDialogState(false)}
      >
        <Stack width={"640px"} height={"309px"} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Edit Widget Titles
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setEditSectionTitleDialogState(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack height={"100%"} p={"32px"} justifyContent={"center"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter new title"}
                onChange={({ target }) => {
                  manageFormState({
                    ...formState,
                    widgetTitle: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => setEditSectionTitleDialogState(false)}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "45px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      {/* URL */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={urlDialog}
        onClose={() => setUrlDialog(false)}
      >
        <Stack width={"640px"} height={""} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Add URL
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setUrlDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack
            height={"100%"}
            p={"32px"}
            gap={"20px"}
            justifyContent={"center"}
          >
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter URL title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>

            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                URL Address
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"https://"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    link: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("URL");
                setUrlDialog(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "25px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      {/* Map */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
        maxWidth={"md"}
        open={mapDialog}
        onClose={() => setMapDialog(false)}
      >
        <Stack width={"640px"} height={""} bgcolor={"#fff"}>
          <Stack
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minHeight={"55px"}
            px={"32px"}
            bgcolor={"#003163"}
            py={"8px"}
            direction={"row"}
          >
            <Typography
              color={"#fff"}
              fontSize={16}
              fontFamily={"PT Sans"}
              fontWeight={"700"}
            >
              Add Map
            </Typography>
            <IconButton
              disableRipple
              onClick={(e) => {
                setMapDialog(false);
              }}
              sx={{ aspectRatio: "1/1", color: "#fff" }}
            >
              <i
                className="bi bi-x-lg"
                style={{ fontSize: 14, color: "#fff" }}
              ></i>
            </IconButton>
          </Stack>
          <Stack
            height={"100%"}
            p={"32px"}
            gap={"20px"}
            justifyContent={"center"}
          >
            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Title
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"Enter title"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    title: target.value,
                  });
                }}
              />
            </Stack>

            <Stack width={"100%"} gap={"6px"}>
              <Typography
                variant="p"
                fontSize={12}
                fontFamily={"Pt Sans"}
                color={"#646464"}
              >
                Map link
              </Typography>

              <Form
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: 14,
                    "& fieldset": {
                      borderColor: "#003163",
                    },

                    "&:hover fieldset": {
                      borderColor: "#003163",
                    },

                    "&.Mui-focused fieldset": {
                      borderColor: "#003163",
                    },
                  },
                }}
                fullWidth
                placeholder={"https://"}
                onChange={({ target }) => {
                  setNewWidgetForm({
                    ...newWidgetForm,
                    link: target.value,
                  });
                }}
              />
            </Stack>
            <Button
              onClick={() => {
                handleAddSectionPageTitleSubmit("MAP");
                setMapDialog(false);
              }}
              disableElevation
              variant="contained"
              sx={{
                "&.MuiButton-contained": {
                  background: "#003163",
                  width: "fit-content",
                  height: "45px",
                  px: "28px",
                  py: "14px",
                  marginTop: "25px",
                  fontFamily: "PT Sans",
                  fontSize: 14,
                  textTransform: "none",
                  borderRadius: 0,
                },
              }}
            >
              Save{" "}
              {loading && <CircularProgress className="w-3 h-3 text-white" />}
            </Button>
          </Stack>
        </Stack>
      </Dialog>

      <DialogComponent
        title={"Delete Section"}
        content={"Are you sure you want to delete this section?"}
        dialogState={deleteSection}
        handleClose={() => setDeleteSection(false)}
        primaryBtnAction={() => setDeleteSection(false)}
        secondaryBtnAction={() => setDeleteSection(false)}
        primaryActionBtnText={"Yes"}
        primaryActionBtnColor={"#DA1E28"}
        secondaryActionBtnColor={"#003163"}
        secondaryActionBtnText={"No"}
      />

      <Stack gap={"32px"} width={"100%"}>
        <Breadcrumb
          rootLinkPath={"/dashboard"}
          rootLink={"Dashboard"}
          firstLinkPath={"/occurrence/section"}
          rootLinkIcon={dashboardIcon}
          firstLinkIcon={fileIconPrimary}
          firstLink={"Occurrence"}
          secondLink={data?.data?.occurrence}
          secondLinkPath={`/occurrence/section/manage-occurrence/${data?.data?.occurrence_id}`}
          secondLinkIcon={fileIconPrimary}
          thirdLink={data?.data?.title}
        />
      </Stack>
      <Stack
        direction={"row"}
        borderRadius={"4px 4px 0 0"}
        height={"87px"}
        justifyContent={"space-between"}
        gap={"8px"}
        alignItems={"center"}
        bgcolor={"#003163"}
        px={"42px"}
        py={"18px"}
      >
        <img src={fileIconLight} alt="" />
        <Typography
          marginRight={"auto"}
          fontFamily={"PT Sans"}
          fontSize={16}
          color={"#fff"}
          fontWeight={700}
        >
          {data?.data?.title}
        </Typography>

        <Button
          disableElevation
          variant="contained"
          onClick={() =>
            History(
              `/occurrence/section/manage-occurrence/${data?.data?.occurrence_id}`
            )
          }
          sx={{
            "&.MuiButton-contained": {
              backgroundColor: "none",
              height: "41px",
              fontFamily: "PT Sans",
              p: "14px 28px",
              width: "fit-content",
              borderRadius: 0,
              border: "1px solid #fff",
              textTransform: "none",
              fontSize: 14,
              "&:hover": {
                background: "none",
              },
            },
          }}
        >
          Back to Overview
        </Button>

        {editOccureenceContentRole || createOccureenceContentRole ? (
          <Button
            disableElevation
            variant="contained"
            sx={{
              "&.MuiButton-contained": {
                backgroundColor: "#fff",
                height: "41px",
                fontFamily: "PT Sans",
                p: "14px 28px",
                width: "fit-content",
                marginLeft: "26px",
                borderRadius: 0,
                color: "#003163",
                border: 0,
                textTransform: "none",
                fontSize: 14,
              },
            }}
          >
            Save
          </Button>
        ) : null}
      </Stack>

      <Stack
        direction={"row"}
        marginTop={"-16px"}
        justifyContent={"space-between"}
        gap={"17px"}
        alignItems={"flex-start"}
      >
        <Stack borderRadius={"4px"} gap={"32px"} flex={0.74}>
          <Stack>
            <Stack
              bgcolor={"#fff"}
              py={"40px"}
              gap={"20px"}
              borderRadius={"4px"}
              // display={(!textWidget) && (!videoWidget) && (!imageWidget) && (!mapWidget) && (!urlWidget) && (!tableWidget) ? 'none' : 'flex'}
            >
              <Stack
                px={"32px"}
                sx={
                  {
                    // display: textWidget ? '' : 'none'
                  }
                }
                className="space-y-4"
              >
                {pageContents?.map((item, index) =>
                  item.page_type === "TEXT" ? (
                    <Accordion
                      square={false}
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                      key={index}
                    >
                      <AccordionSummary
                        sx={{
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            {item.title}
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() =>
                                setEditSectionTitleDialogState(true)
                              }
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          paddingBottom: "40px",
                          marginTop:
                            editOccureenceContentRole ||
                            createOccureenceContentRole
                              ? "-23px"
                              : "-40px",
                        }}
                      >
                        <Stack
                          marginTop={"20px"}
                          sx={{ "& .tox-tinymce": { border: "" } }}
                        >
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.content}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                console.log(pageContents);
                                const updatedPageContents = [...pageContents];
                                updatedPageContents[index].content = data;
                                setPageContents(updatedPageContents);
                                // setPageContents({...pageContents})
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          ) : (
                            // <Editor
                            //     apiKey="7ae0vxik6t087k9a6ciwtmaz19o50e1trri5oz6ljmdre8vc"
                            //     onInit={(evt, editor) => editorRef.current = editor}
                            //     init={{
                            //         min_height: 400,
                            //         placeholder: 'Type in your text',
                            //         width: '100%',
                            //         menubar: false,
                            //         plugins: [
                            //             'advlist', 'autolink',
                            //             'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                            //             'fullscreen', 'insertdatetime', 'media', 'table', 'help', ''
                            //         ],
                            //         toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                            //             'alignleft aligncenter alignright alignjustify | ' +
                            //             'bullist numlist  outdent indent | removeformat |  code table help',
                            //         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            //         link_default_target: '_blank'
                            //     }}
                            // />
                            <Stack
                              bgcolor={"#F4F4F4"}
                              p={"32px"}
                              mx={"-16px"}
                              mb={"-40px"}
                              gap={"12px"}
                            >
                              {/* {sanitizeHtml(item.content)} */}

                              <SanitizeHTML html={`${item.content}`} />
                              {/* <Typography fontFamily={'PT Sans'} fontSize={'18px'} fontWeight={700} color={'#161616'}>
                                                            Introduction
                                                        </Typography>
                                                        <Typography fontFamily={'PT Sans'} color={'#161616'} fontSize={'14px'} lineHeight={'160%'}>
                                                            While attempting to land in a football stadium, VIP chartered flight Nigeria 002 (NGR002) encountered brownout conditions and experienced a hard landing on the right main landing gear and rolled over to the right.

                                                            There were 12 persons onboard, including the Vice President of the Federal Republic of Nigeria and his entourage, and three crew members (Captain, Co-Pilot and an Engineer). All occupants of the helicopter were evacuated uninjured.
                                                        </Typography> */}
                            </Stack>
                          )}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Stack
                              direction={"row"}
                              marginTop={"26px"}
                              justifyContent={"space-between"}
                            >
                              <Button
                                onClick={() =>
                                  handleAddSectionPageContentSubmit(
                                    index,
                                    item.id
                                  )
                                }
                                disableElevation
                                variant="contained"
                                sx={{
                                  "&.MuiButton-contained": {
                                    borderRadius: 0,
                                    background: "#003163",
                                    fontFamily: "PT Sans",
                                    fontSize: 14,
                                    textTransform: "none",
                                    width: "200px",
                                    height: "50px",
                                  },
                                }}
                              >
                                Update
                              </Button>

                              <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                  "&.MuiButton-contained": {
                                    borderRadius: 0,
                                    background: "#DA1E28",
                                    fontFamily: "PT Sans",
                                    fontSize: 14,
                                    textTransform: "none",
                                    width: "200px",
                                    height: "50px",
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </Stack>
                          ) : null}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ) : item.page_type === "IMAGE" ? (
                    <Accordion
                      square={true}
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            {item.title}
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() =>
                                setEditSectionTitleDialogState(true)
                              }
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          pb: "40px",
                          marginTop: "-23px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {item.url ? (
                          <Stack alignItems={"center"} gap={"10px"}>
                            <img
                              src={item.url}
                              width={"300px"}
                              style={{
                                maxHeight: "300px",
                                borderRadius: "4px",
                              }}
                            />
                            <Stack
                              p={"10px"}
                              minHeight={"42px"}
                              bgcolor={"#F4F4F4"}
                              maxWidth={"400px"}
                              borderLeft={"4px solid #F1C21B"}
                              fontFamily={"PT Sans"}
                              color={"#161616"}
                              fontSize={14}
                            >
                              {item.image_alt_text}
                            </Stack>
                          </Stack>
                        ) : editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                          <ImageUploader
                            widget={true}
                            whiteBg={true}
                            mediaForm={medialPageForm}
                            setMediaForm={setMedialPageForm}
                            formSubmit={() => handleAddMediaPageSubmit(item.id)}
                          />
                        ) : (
                          <div className="text-center">No media upload yet</div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : item.page_type === "DATATABLE" ? (
                    <Accordion
                      square={false}
                      className="my-2"
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                      key={index}
                    >
                      <AccordionSummary
                        sx={{
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            {item.title}
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() =>
                                setEditSectionTitleDialogState(true)
                              }
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          paddingBottom: "40px",
                          marginTop:
                            editOccureenceContentRole ||
                            createOccureenceContentRole
                              ? "-23px"
                              : "-40px",
                        }}
                      >
                        <Stack
                          marginTop={"20px"}
                          sx={{ "& .tox-tinymce": { border: "" } }}
                        >
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <CKEditor
                              editor={ClassicEditor}
                              data={item.content}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                                console.log(pageContents);
                                const updatedPageContents = [...pageContents];
                                updatedPageContents[index].content = data;
                                setPageContents(updatedPageContents);
                                // setPageContents({...pageContents})
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          ) : (
                            // <Editor
                            //     apiKey="7ae0vxik6t087k9a6ciwtmaz19o50e1trri5oz6ljmdre8vc"
                            //     onInit={(evt, editor) => editorRef.current = editor}
                            //     init={{
                            //         min_height: 400,
                            //         placeholder: 'Type in your text',
                            //         width: '100%',
                            //         menubar: false,
                            //         plugins: [
                            //             'advlist', 'autolink',
                            //             'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                            //             'fullscreen', 'insertdatetime', 'media', 'table', 'help', ''
                            //         ],
                            //         toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                            //             'alignleft aligncenter alignright alignjustify | ' +
                            //             'bullist numlist  outdent indent | removeformat |  code table help',
                            //         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            //         link_default_target: '_blank'
                            //     }}
                            // />
                            <Stack
                              bgcolor={"#F4F4F4"}
                              p={"32px"}
                              mx={"-16px"}
                              mb={"-40px"}
                              gap={"12px"}
                            >
                              {/* {sanitizeHtml(item.content)} */}

                              <SanitizeHTML html={`${item.content}`} />
                            </Stack>
                          )}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Stack
                              direction={"row"}
                              marginTop={"26px"}
                              justifyContent={"space-between"}
                            >
                              <Button
                                onClick={() =>
                                  handleAddSectionPageContentSubmit(
                                    index,
                                    item.id
                                  )
                                }
                                disableElevation
                                variant="contained"
                                sx={{
                                  "&.MuiButton-contained": {
                                    borderRadius: 0,
                                    background: "#003163",
                                    fontFamily: "PT Sans",
                                    fontSize: 14,
                                    textTransform: "none",
                                    width: "200px",
                                    height: "50px",
                                  },
                                }}
                              >
                                Update
                              </Button>

                              <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                  "&.MuiButton-contained": {
                                    borderRadius: 0,
                                    background: "#DA1E28",
                                    fontFamily: "PT Sans",
                                    fontSize: 14,
                                    textTransform: "none",
                                    width: "200px",
                                    height: "50px",
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </Stack>
                          ) : null}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ) : item.page_type === "MAP" ? (
                    <Accordion
                      square={true}
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          borderTop: 0,
                          borderRadius: "4px",
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            {item.title}
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() =>
                                setEditSectionTitleDialogState(true)
                              }
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          pb: "1px",
                          mx: "-16px",
                          marginTop: "-10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <iframe
                          referrerpolicy="no-referrer-when-downgrade"
                          src={item.link}
                          style={{
                            height: "375px",
                            borderRadius: "0 0 4px 4px",
                          }}
                        ></iframe>
                      </AccordionDetails>
                    </Accordion>
                  ) : item.page_type === "URL" ? (
                    <Accordion
                      square={true}
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          borderTop: 0,
                          borderRadius: "4px",
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            URL
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() =>
                                setEditSectionTitleDialogState(true)
                              }
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          marginTop: "-23px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Stack p={"32px"}>
                          <Stack
                            height={"52px"}
                            alignItems={"center"}
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Stack
                              height={"100%"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                fontSize={14}
                                color={"#161616"}
                                fontWeight={700}
                                fontFamily={"PT Sans"}
                              >
                                {item.title}
                              </Typography>

                              <Link
                                to={item.link}
                                style={{
                                  fontSize: 14,
                                  fontFamily: "PT Sans",
                                  color: "#003163",
                                }}
                              >
                                {item.link}
                              </Link>
                            </Stack>
                            <Stack direction={"row"}>
                              {editOccureenceContentRole ||
                              createOccureenceContentRole ? (
                                <Button
                                  variant="contained"
                                  disableElevation
                                  sx={{
                                    p: 0,
                                    minWidth: "32px",
                                    height: "32px",
                                    maxWidth: "fit-content",
                                    fontFamily: "PT Sans",
                                    marginRight: "14px",
                                    color: "#ffffff",
                                    backgroundColor: "#003163",
                                    borderRadius: 0,
                                    fontFamily: "PT Sans",
                                    textTransform: "none",
                                    fontSize: 14,
                                    "&.MuiButton-contained": {
                                      background: "#003163",
                                    },
                                  }}
                                >
                                  <img src={editNoteIcon} alt="" />
                                </Button>
                              ) : null}
                              {editOccureenceContentRole ||
                              createOccureenceContentRole ? (
                                <IconButton
                                  sx={{
                                    width: "32px",
                                    aspectRatio: "1/1",
                                    color: "#DA1E28",
                                    border: "1px solid #DA1E28",
                                    borderRadius: 0,
                                    p: "7px",
                                  }}
                                >
                                  <i
                                    className="bi bi-trash-fill"
                                    style={{
                                      fontSize: 14,
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      p: 0,
                                    }}
                                  ></i>
                                </IconButton>
                              ) : null}
                            </Stack>
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  ) : item.page_type === "VIDEO" ? (
                    <Accordion
                      square={true}
                      sx={{
                        "&.MuiAccordion-root": {
                          boxShadow: "0px 2px 6px 0px #E0E0E0",
                          borderRadius: "4px",
                        },
                        "&.MuiAccordion-root::before": {
                          backgroundColor: "transparent !important",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          borderTop: 0,
                          borderRadius: "4px",
                          py: "20px",
                          "& .MuiAccordionSummary-contentGutters": {
                            p: 0,
                            margin: 0,
                          },
                        }}
                        expandIcon={<i className="bi bi-chevron-down"></i>}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          gap={"18px"}
                        >
                          <Typography
                            fontFamily={"PT Sans"}
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={400}
                          >
                            {item.title}
                          </Typography>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              onClick={() => setEditVideoWidgetDialog(true)}
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              onClick={() => setDeleteSection(true)}
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          pt: 0,
                          pb: "50px",
                          marginTop:
                            editOccureenceContentRole ||
                            createOccureenceContentRole
                              ? "-23px"
                              : "",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {item.url ? (
                          <Stack alignItems={"center"} gap={"10px"}>
                            <video
                              style={{
                                width: "352px",
                                height: "215px",
                                borderRadius: "8px",
                                border: 0,
                              }}
                              controls
                            >
                              <source src={item.url} type="video/mp4" />
                            </video>
                            <Stack
                              p={"10px"}
                              minHeight={"42px"}
                              bgcolor={"#F4F4F4"}
                              maxWidth={"400px"}
                              borderLeft={"4px solid #F1C21B"}
                              fontFamily={"PT Sans"}
                              color={"#161616"}
                              fontSize={14}
                            >
                              {item.image_alt_text}
                            </Stack>
                          </Stack>
                        ) : editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                          <VideoUploader
                            widget={true}
                            whiteBg={true}
                            mediaForm={medialPageForm}
                            setMediaForm={setMedialPageForm}
                            formSubmit={() => handleAddMediaPageSubmit(item.id)}
                          />
                        ) : (
                          <div className="text-center">
                            No media has been uploaded yet
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : null
                )}
              </Stack>

              <Stack
                px={"32px"}
                py={"20"}
                sx={{
                  display: textWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        Text Widget
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditTextWidgetDialog(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      pb: "40px",
                      marginTop: "-23px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="text-center">
                      Please give suitable title to continue
                    </div>
                    {/* {(editOccureenceContentRole || createOccureenceContentRole) ? <ImageUploader widget={true} whiteBg={true} /> : <Stack alignItems={'center'} gap={'10px'}>
                                            <img src={One} width={'300px'} style={{ maxHeight: '300px', borderRadius: '4px' }} />
                                            <Stack p={'10px'} minHeight={'42px'} bgcolor={'#F4F4F4'} maxWidth={'400px'} borderLeft={'4px solid #F1C21B'} fontFamily={'PT Sans'} color={'#161616'} fontSize={14}>
                                                Image: Photo showing part of the helicopter wreckage.
                                            </Stack>
                                        </Stack>} */}
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Stack
                px={"32px"}
                py={"20"}
                sx={{
                  display: imageWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        Image
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditImageWidgetDialog(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      pb: "40px",
                      marginTop: "-23px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="text-center">
                      Please add a suitable title to continue
                    </div>
                    {/* {(editOccureenceContentRole || createOccureenceContentRole) ? <ImageUploader widget={true} whiteBg={true} /> : <Stack alignItems={'center'} gap={'10px'}>
                                            <img src={One} width={'300px'} style={{ maxHeight: '300px', borderRadius: '4px' }} />
                                            <Stack p={'10px'} minHeight={'42px'} bgcolor={'#F4F4F4'} maxWidth={'400px'} borderLeft={'4px solid #F1C21B'} fontFamily={'PT Sans'} color={'#161616'} fontSize={14}>
                                                Image: Photo showing part of the helicopter wreckage.
                                            </Stack>
                                        </Stack>} */}
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Stack
                px={"32px"}
                py={"20"}
                sx={{
                  display: mapWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      borderTop: 0,
                      borderRadius: "4px",
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        Map
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditSectionTitleDialogState(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      pb: "1px",
                      mx: "-16px",
                      marginTop: "-10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <iframe
                      referrerpolicy="no-referrer-when-downgrade"
                      src="https://www.google.com/maps/@6.586368,3.3554432,12z?entry=ttu"
                      style={{ height: "375px", borderRadius: "0 0 4px 4px" }}
                    ></iframe>
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Stack
                px={"32px"}
                py={"20"}
                sx={{
                  display: urlWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      borderTop: 0,
                      borderRadius: "4px",
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        URL
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditSectionTitleDialogState(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      marginTop: "-23px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Stack p={"32px"}>
                      <Stack
                        height={"52px"}
                        alignItems={"center"}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Stack height={"100%"} justifyContent={"space-between"}>
                          <Typography
                            fontSize={14}
                            color={"#161616"}
                            fontWeight={700}
                            fontFamily={"PT Sans"}
                          >
                            Brownout Video Example 1
                          </Typography>

                          <Link
                            to={"https://www.youtube.com/watch?v=AIQY1O2wXts"}
                            style={{
                              fontSize: 14,
                              fontFamily: "PT Sans",
                              color: "#003163",
                            }}
                          >
                            https://www.youtube.com/watch?v=AIQY1O2wXts
                          </Link>
                        </Stack>
                        <Stack direction={"row"}>
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <Button
                              variant="contained"
                              disableElevation
                              sx={{
                                p: 0,
                                minWidth: "32px",
                                height: "32px",
                                maxWidth: "fit-content",
                                fontFamily: "PT Sans",
                                marginRight: "14px",
                                color: "#ffffff",
                                backgroundColor: "#003163",
                                borderRadius: 0,
                                fontFamily: "PT Sans",
                                textTransform: "none",
                                fontSize: 14,
                                "&.MuiButton-contained": {
                                  background: "#003163",
                                },
                              }}
                            >
                              <img src={editNoteIcon} alt="" />
                            </Button>
                          ) : null}
                          {editOccureenceContentRole ||
                          createOccureenceContentRole ? (
                            <IconButton
                              sx={{
                                width: "32px",
                                aspectRatio: "1/1",
                                color: "#DA1E28",
                                border: "1px solid #DA1E28",
                                borderRadius: 0,
                                p: "7px",
                              }}
                            >
                              <i
                                className="bi bi-trash-fill"
                                style={{
                                  fontSize: 14,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  p: 0,
                                }}
                              ></i>
                            </IconButton>
                          ) : null}
                        </Stack>
                      </Stack>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Stack
                px={"32px"}
                py={"20"}
                sx={{
                  display: videoWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      borderTop: 0,
                      borderRadius: "4px",
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        Video
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditVideoWidgetDialog(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      pb: "50px",
                      marginTop:
                        editOccureenceContentRole || createOccureenceContentRole
                          ? "-23px"
                          : "",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="text-center">
                      Please add a suitable title to continue
                    </div>
                    {/* {(editOccureenceContentRole || createOccureenceContentRole) ? <VideoUploader widget={true} whiteBg={true} /> : <Stack alignItems={'center'} gap={'10px'}>
                                            <video style={{ width: '352px', height: '215px', borderRadius: '8px', border: 0 }}>
                                                <source src='https://www.taxmann.com/emailer/images/CompaniesAct.mp4' type='video/mp4' />
                                            </video>
                                            <Stack p={'10px'} minHeight={'42px'} bgcolor={'#F4F4F4'} maxWidth={'400px'} borderLeft={'4px solid #F1C21B'} fontFamily={'PT Sans'} color={'#161616'} fontSize={14}>
                                                Video: Photo showing part of the helicopter wreckage.
                                            </Stack>
                                        </Stack>} */}
                  </AccordionDetails>
                </Accordion>
              </Stack>

              <Stack
                px={"32px"}
                sx={{
                  display: tableWidget ? "" : "none",
                }}
              >
                <Accordion
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      boxShadow: "0px 2px 6px 0px #E0E0E0",
                      borderRadius: "4px",
                    },
                    "&.MuiAccordion-root::before": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      py: "20px",
                      "& .MuiAccordionSummary-contentGutters": {
                        p: 0,
                        margin: 0,
                      },
                    }}
                    expandIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"18px"}>
                      <Typography
                        fontFamily={"PT Sans"}
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={400}
                      >
                        Data Table
                      </Typography>
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <Button
                          onClick={() => setEditDataTableWidgetDialog(true)}
                          variant="contained"
                          disableElevation
                          sx={{
                            p: 0,
                            minWidth: "32px",
                            height: "32px",
                            maxWidth: "fit-content",
                            fontFamily: "PT Sans",
                            marginRight: "14px",
                            color: "#ffffff",
                            backgroundColor: "#003163",
                            borderRadius: 0,
                            fontFamily: "PT Sans",
                            textTransform: "none",
                            fontSize: 14,
                            "&.MuiButton-contained": {
                              background: "#003163",
                            },
                          }}
                        >
                          <img src={editNoteIcon} alt="" />
                        </Button>
                      ) : null}
                      {editOccureenceContentRole ||
                      createOccureenceContentRole ? (
                        <IconButton
                          onClick={() => setDeleteSection(true)}
                          sx={{
                            width: "32px",
                            aspectRatio: "1/1",
                            color: "#DA1E28",
                            border: "1px solid #DA1E28",
                            borderRadius: 0,
                            p: "7px",
                          }}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{
                              fontSize: 14,
                              marginLeft: "auto",
                              marginRight: "auto",
                              p: 0,
                            }}
                          ></i>
                        </IconButton>
                      ) : null}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      pt: 0,
                      marginTop: "-23px",
                      paddingBottom: "40px",
                    }}
                  >
                    <div className="text-center">
                      Please add a suitable title to continue
                    </div>
                    {/* {(editOccureenceContentRole || createOccureenceContentRole) ? <Stack marginTop={'20px'} sx={{ '& .tox-tinymce': { border: '', } }}>
                                            <Editor
                                                apiKey="7ae0vxik6t087k9a6ciwtmaz19o50e1trri5oz6ljmdre8vc"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                init={{
                                                    min_height: 400,
                                                    placeholder: 'Type in your text',
                                                    width: '100%',
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink',
                                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                        'fullscreen', 'insertdatetime', 'media', 'table', 'help', ''
                                                    ],
                                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                        'alignleft aligncenter alignright alignjustify | ' +
                                                        'bullist numlist checklist outdent indent | removeformat | code table help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                    link_default_target: '_blank'
                                                }}
                                            />
                                            <Stack direction={'row'} marginTop={'26px'} justifyContent={'space-between'}>
                                                <Button onClick={log} disableElevation variant='contained' sx={{ '&.MuiButton-contained': { borderRadius: 0, background: '#003163', fontFamily: 'PT Sans', fontSize: 14, textTransform: 'none', width: '200px', height: '50px' } }}>
                                                    Update
                                                </Button>

                                                <Button disableElevation variant='contained' sx={{ '&.MuiButton-contained': { borderRadius: 0, background: '#DA1E28', fontFamily: 'PT Sans', fontSize: 14, textTransform: 'none', width: '200px', height: '50px' } }}>
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </Stack> : <Stack marginTop={'20px'} fontSize={14}>
                                            <Typography fontFamily={'inherit'} fontSize={16} fontWeight={700} marginBottom={'20px'}>
                                                Occurrence Data
                                            </Typography>
                                            <table style={{ borderCollapse: 'separate', width: '82.8526%', height: '43.2px', }} border={1}><colgroup><col style={{ width: '47.2981%' }} /><col style={{ width: '52.7231%' }} /></colgroup>
                                                <tbody>
                                                    <tr style={{ height: '23.6px' }}>
                                                        <td style={{ height: '23.6px' }}><strong>Airline</strong></td>
                                                        <td style={{ height: '23.6px' }}>VP Aircraft</td>
                                                    </tr>
                                                    <tr style={{ height: '19.6px' }}>
                                                        <td style={{ height: '19.6px' }}><strong>Aircraft Manufacturer</strong></td>
                                                        <td style={{ height: '19.6px' }}>AugustaWestland</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </Stack>} */}
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
            {!userRole === 1 || !userRole === 2 ? (
              <Stack
                bgcolor={"#fff"}
                p={"3px"}
                display={
                  textWidget &&
                  videoWidget &&
                  imageWidget &&
                  mapWidget &&
                  urlWidget &&
                  tableWidget
                    ? "none"
                    : "block"
                }
              >
                <FileUploader content={true} whiteBg={true} />
              </Stack>
            ) : null}
          </Stack>

          {/* comments */}
          <Stack
            p={"32px"}
            bgcolor={"#fff"}
            gap={"24px"}
            borderRadius={"4px"}
            boxShadow={"0px 2px 6px 0px #E0E0E0"}
          >
            <Stack
              borderBottom={"1px solid #CBCBCB"}
              paddingBottom={"12px"}
              marginBottom={"-12px"}
            >
              <Typography
                fontFamily={"PT Sans"}
                fontSize={12}
                color={"#161616"}
                fontWeight={700}
              >
                {dataComments?.data?.length} Comment(s)
              </Typography>
            </Stack>
            <Stack width={"100%"} gap={"6px"}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Add comments"
                variant="filled"
                value={commentText.content}
                fullWidth
                size="small"
                multiline={Infinity}
                rows={6}
                onChange={(e) => {
                  setCommentText({ ...commentText, content: e.target.value });
                }}
              />

              <Button
                variant="contained"
                className="bg-primary capitalize py-2.5 rounded-none px-7  text-sm font-normal my-6 "
                onClick={handleCommentSubmit}
              >
                Comment
              </Button>
            </Stack>
            {/* Comment */}
            <div className="space-y-6">
              {dataComments?.data?.map((item, index) => (
                <div className="space-y-2">
                  <p className="font-bold text-xs">
                    {item.name}
                    <span className="font-normal italic text-secondary ml-4">
                      {item.time}
                    </span>
                  </p>
                  <p className="text-sm">{item.content}</p>

                  <button
                    className="text-xs text-primary/60 italic"
                    onClick={() => {
                      setReply(!reply);
                      setSelectedCommentIndex(index);
                    }}
                  >
                    Reply ({item.reply_count})
                  </button>

                  {reply && selectedCommentIndex === index && (
                    <>
                      <div>
                        <TextField
                          id="input-with-icon-textfield"
                          placeholder="Add reply"
                          variant="filled"
                          value={replyText.content}
                          fullWidth
                          size="small"
                          multiline={Infinity}
                          rows={6}
                          onChange={(e) => {
                            setReplyText({
                              ...replyText,
                              content: e.target.value,
                            });
                          }}
                        />
                        <Button
                          variant="contained"
                          className="bg-primary capitalize py-2.5 rounded-none px-7  text-sm font-normal my-6 "
                          onClick={() => handleReplySubmit(item.id)}
                        >
                          Reply
                        </Button>
                      </div>

                      {item.comment_reply?.length > 0
                        ? item.comment_reply?.map((item, idx) => (
                            <div className="ml-8 border-l-2 border-primary px-4">
                              <p className="font-bold text-xs">
                                {item.name}
                                <span className="font-normal italic text-secondary ml-4">
                                  {item.time}
                                </span>
                              </p>
                              <p className="text-sm">{item.content}</p>

                              {/* <button className='text-xs text-primary/60 italic'>Reply</button> */}
                            </div>
                          ))
                        : null}
                    </>
                  )}

                  <hr />
                </div>
              ))}
            </div>
          </Stack>

          {/* <Stack width={'100%'} marginTop={'8px'} >
                        <Stack gap={'4px'} direction={'row'} alignItems={'center'} sx={{
                            background: '#F1C21B',
                            width: '100%',
                            padding: '24px 42px',
                            borderRadius: '4px 4px 0 0'
                        }}>
                            <img src={historyIcon} alt="" />
                            <Typography color={'#161616'} fontSize={'12px'} fontFamily={'PT Sans'} fontWeight={700} >
                                Audit Trail
                            </Typography>
                        </Stack>
                        <Stack minHeight={'200px'} sx={{
                            p: '18px 42px 38px 42px',
                            background: '#fff'
                        }}>
                            <Stack gap={'32px'} sx={{
                                borderLeft: '1px solid',
                                borderColor: '#7E7E7E'
                            }}>
                                {auditTrail.map((eachAudit) => (
                                    <Stack key={eachAudit.id} direction={'row'} gap={'8px'} position={'relative'} paddingLeft={'48px'}>
                                        <img src={pointIcon} style={{
                                            position: 'absolute',
                                            left: '-12px'
                                        }} alt="" />
                                        <Typography fontFamily={'PT Sans'} fontWeight={700} fontSize={14}>
                                            {eachAudit.userName}
                                        </Typography>
                                        <Typography fontFamily={'PT Sans'} fontStyle={'italic'} fontWeight={400} fontSize={14}>
                                            {eachAudit.activity}
                                        </Typography>
                                        <Typography fontFamily={'PT Sans'} fontWeight={400} fontSize={14} sx={{
                                            marginLeft: '8px'
                                        }}>
                                            {eachAudit.details}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack> */}
        </Stack>

        <Stack gap={"32px"} flex={0.25} pt={"12px"}>
          {userRole === 4 ? (
            <Stack
              minHeight={""}
              direction={"row"}
              flexWrap={"wrap"}
              p={"24px"}
              borderRadius={"8px"}
              bgcolor={"#fff"}
              justifyContent={"space-between"}
              gap={"8px"}
              height={"fit-content !important"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"16px"}
                width={"100%"}
                marginBottom={"8px"}
                py={"12px"}
                borderBottom={"1px solid #E3E3E3"}
              >
                <Typography
                  color={"#7E7E7E"}
                  fontSize={12}
                  fontFamily={"PT Sans"}
                  fontWeight={"600"}
                >
                  Status
                </Typography>
                <Chip
                  sx={{
                    "& .MuiChip-label": {
                      fontFamily: "PT Sans",
                      color: "#003163",
                      fontSize: 12,
                      fontStyle: "italic",
                      fontWeight: 400,
                    },
                    background: "#F0F5FF",
                    borderRadius: "8px",
                    px: "10px",
                    "&.MuiChip-root": {
                      p: 0,
                      height: "23px",
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                    },
                  }}
                  label={"Pending"}
                />
              </Stack>
              <Typography
                color={"#7E7E7E"}
                fontSize={12}
                fontFamily={"PT Sans"}
                fontWeight={"600"}
                width={"100%"}
              >
                Last Updated
              </Typography>

              <Typography
                color={"#161616"}
                fontSize={14}
                fontFamily={"PT Sans"}
                fontWeight={"400"}
              >
                13/11/22
              </Typography>

              <Typography
                color={"#161616"}
                fontSize={14}
                fontFamily={"PT Sans"}
                fontWeight={"400"}
              >
                12:00:00 PM
              </Typography>
            </Stack>
          ) : (
            <Stack
              sx={{ aspectRatio: "1/0.313" }}
              minHeight={""}
              direction={"row"}
              flexWrap={"wrap"}
              p={"16px 24px"}
              borderRadius={"8px"}
              bgcolor={"#fff"}
              justifyContent={"space-between"}
              gap={"8px"}
              height={"fit-content !important"}
            >
              <Typography
                color={"#7E7E7E"}
                fontSize={12}
                fontFamily={"PT Sans"}
                fontWeight={"600"}
                width={"100%"}
              >
                Last Updated
              </Typography>

              <Typography
                color={"#161616"}
                fontSize={14}
                fontFamily={"PT Sans"}
                fontWeight={"400"}
              >
                13/11/22
              </Typography>

              <Typography
                color={"#161616"}
                fontSize={14}
                fontFamily={"PT Sans"}
                fontWeight={"400"}
              >
                12:00:00 PM
              </Typography>
            </Stack>
          )}

          {editOccureenceContentRole || createOccureenceContentRole ? (
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              rowGap={"32px"}
              justifyContent={"space-between"}
            >
              <Typography
                marginBottom={"-4px"}
                width={"100%"}
                color={"#161616"}
                fontFamily={"PT Sans"}
                fontSize={14}
                fontWeight={700}
              >
                Content Widgets
              </Typography>
              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  onClick={() => setTextWidget(!textWidget)}
                  width={"45%"}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={TextIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    Text
                  </Typography>
                </Stack>
              ) : null}

              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  width={"45%"}
                  onClick={() => setTableWidget(!tableWidget)}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={DataTableIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    Data Table
                  </Typography>
                </Stack>
              ) : null}

              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  onClick={() => setImageWidget(!imageWidget)}
                  width={"45%"}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={ImageIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    Image
                  </Typography>
                </Stack>
              ) : null}

              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  onClick={() => setVideoWidget(!videoWidget)}
                  width={"45%"}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={VideoIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    Video
                  </Typography>
                </Stack>
              ) : null}

              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  onClick={() =>
                    mapWidget ? setMapWidget(false) : setMapDialog(true)
                  }
                  width={"45%"}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={MapIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    Map
                  </Typography>
                </Stack>
              ) : null}

              {editOccureenceContentRole || createOccureenceContentRole ? (
                <Stack
                  onClick={() =>
                    urlWidget ? setUrlWidget(false) : setUrlDialog(true)
                  }
                  width={"45%"}
                  bgcolor={"#fff"}
                  borderRadius={"8px"}
                  boxShadow={"0px 2px 6px 0px #E0E0E0"}
                  minHeight={"97px"}
                  sx={{ aspectRatio: "1/0.822", cursor: "pointer" }}
                  justifyContent={"center"}
                  color={"#003163"}
                  gap={"10px"}
                  alignItems={"center"}
                >
                  <img src={UrlIcon} alt="" />
                  <Typography
                    color={"#161616"}
                    fontSize={12}
                    fontFamily={"PT Sans"}
                  >
                    URL
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
}
