import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { StyledTableRow } from "../../pages/user-data";
import useFetch from "../../helpers/useFetch";

export const stakeholderColumns = [
  {
    id: "occurrence_number",
    label: "Occurrence Number",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
  },
  {
    id: "occurrence_category",
    label: "Category",
    minWidth: 150,
  },
  {
    id: "icao_deadline_date",
    label: "ICAO Deadline",
    minWidth: 150,
  },
  {
    id: "occurrence_type",
    label: "Type",
    minWidth: 100,
    minWidth: 150,
  },
  {
    id: "action",
    label: "Action",
  },
];

const StakeholderHome = () => {
  const { data, isLoading } = useFetch(
    "api/occurrence-manager/stakeholders/occurrence_by_stakeholders/"
  );
  const [reviewedData, setReviewedData] = React.useState([]);
  const [unreviewedData, setUnreviewedData] = React.useState([]);
  React.useEffect(() => {
    if (data.data) {
      const reviewedOccurrence = data.data.filter(
        (occurrence) => occurrence.is_reviewed === true
      );
      const unreviewedOccurrence = data.data.filter(
        (occurrence) => occurrence.is_reviewed === false
      );
      setReviewedData(reviewedOccurrence);
      setUnreviewedData(unreviewedOccurrence);
    }
  }, [data]);
  const navigate = useNavigate();

  return (
    <Stack gap={"42px"}>
      <Stack className="mt-10">
        <div className="bg-primary py-6 px-8 rounded-t-2xl text-white flex justify-between items-center font-semibold">
          <h2 className="text-xl">Occurrences</h2>
          <Link to="/occurrence" className="text-sm">
            See all
          </Link>
        </div>
        <TableContainer className="bg-white rounded-b-2xl">
          <Table>
            <TableHead>
              <TableRow>
                {stakeholderColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className="text-[#AEAEAE] font-medium text-sm">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell
                    colSpan={stakeholderColumns?.length}
                    align="center"
                  >
                    <div className="mt-10 mb-10">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : unreviewedData?.length > 0 ? (
                unreviewedData?.slice(0, 6)?.map((item, index) => (
                  <StyledTableRow key={index}>
                    {stakeholderColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        className="text-gray-900 font-medium text-sm"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "action" ? (
                          <div className="flex gap-4 items-center justify-center">
                            <Button
                              variant="contained"
                              className="bg-primary text-white text-sm shadow-none font-bold rounded-xl capitalize"
                              onClick={() => navigate(`/reviews/${item?.id}`)}
                            >
                              Review
                            </Button>
                            <Button
                              variant="outlined"
                              className="text-sm shadow-none font-bold rounded-xl capitalize"
                              onClick={() =>
                                navigate(
                                  `/occurrence/manage-occurrence/${item?.occurrence?.id}`
                                )
                              }
                            >
                              Preview
                            </Button>
                          </div>
                        ) : (
                          item?.occurrence[column.id]
                        )}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell
                    colSpan={stakeholderColumns?.length}
                    align="center"
                  >
                    <div className="mt-10 mb-10 text-lg">
                      <p>No new Occurrence added yet</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack className="mt-10">
        <div className="bg-primary py-6 px-8 rounded-t-2xl text-white flex justify-between items-center font-semibold">
          <h2 className="text-xl">Occurrence Reviews</h2>
          <Link to="/reviews" className="text-sm">
            See all
          </Link>
        </div>
        <TableContainer className="bg-white rounded-b-2xl">
          <Table>
            <TableHead>
              <TableRow>
                {stakeholderColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography className="text-[#AEAEAE] font-medium text-sm">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell
                    colSpan={stakeholderColumns?.length}
                    align="center"
                  >
                    <div className="mt-10 mb-10">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : reviewedData?.length > 0 ? (
                reviewedData?.slice(0, 6)?.map((item, index) => (
                  <StyledTableRow key={index}>
                    {stakeholderColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        className="text-gray-900 font-medium text-sm"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "action" ? (
                          <Button
                            variant="contained"
                            className="bg-primary text-white text-sm shadow-none font-bold rounded-xl capitalize"
                            onClick={() => navigate(`/reviews/${item?.id}`)}
                          >
                            View
                          </Button>
                        ) : (
                          item?.occurrence[column.id]
                        )}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell
                    colSpan={stakeholderColumns?.length}
                    align="center"
                  >
                    <div className="mt-10 mb-10 text-lg">
                      <p>No Reviewed Occurrence yet</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default StakeholderHome;
