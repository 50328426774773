import React from "react";
import { Button, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";

const Appendices = ({ information, goToNext }) => {
  const { occurrenceId } = useParams();
  const [subSections, setSubSections] = React.useState([]);
  React.useState([]);
  React.useEffect(() => {
    if (information) {
      const sections = information.occurrence_section_group?.find(
        (group) => group?.section_name === "APPENDICES"
      )?.occurence_section;
      setSubSections(sections);
    }
  }, [information]);
  function getSectionOwner(id) {
    if (subSections) {
      const section = subSections.find((section) => section.id === id);
      if (section) {
        const owner = section.occurence_section_member?.find(
          (member) => member?.is_owner
        );
        return owner;
      }
    }
  }

  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Appendices</h2>
      </div>
      <div className="bg-white py-6 px-8 rounded-2xl">
        <div
          className={`grid ${
            subSections && subSections.length > 0
              ? "grid-cols-2"
              : "grid-cols-1"
          } gap-6 xl:gap-10 mb-10 last:mb-0`}
        >
          {subSections && subSections.length > 0 ? (
            subSections?.map((item, index) => (
              <Link
                to={`/occurrence/manage-occurrence/${occurrenceId}/factual-info/${item?.id}?section_name=Appendix`}
                // to={`/occurrence/manage-occurrence/${occurrenceId}/appendices/${item?.id}?section_name=${item?.section_name}`}
                key={index}
              >
                <div className="rounded-xl border overflow-hidden flex justify-between flex-col text-sm cursor-pointer">
                  <div className="h-44 overflow-hidden px-4 py-4">
                    <h2 className="font-semibold pb-3">{item?.section_name}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.occurrence_page_section[0]?.content,
                      }}
                    />
                  </div>
                  <div className="flex justify-between gap-4 bg-gray-50 py-2 px-4">
                    <div>
                      <h4 className="text-[#C0C0C0]">Section Owner:</h4>
                      <h2 className="font-semibold">
                        {getSectionOwner(item.id)?.user}
                      </h2>
                    </div>
                    <div>
                      <h4 className="text-[#C0C0C0]">Date Assigned</h4>
                      <h2 className="font-semibold">
                        {new Date(item?.created_at)?.toLocaleDateString()}
                      </h2>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="text-center py-10">
              <h2 className="font-semibold text-2xl">No Appendices</h2>
              <p>Occurrence manager is yet to add any section</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end">
        <Button
          className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </Stack>
  );
};

export default Appendices;
