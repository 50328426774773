import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Input } from "../user-management/ManageProfile";
import { error, success } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const index = () => {
  const [oldPasswordState, setOldPasswordState] = React.useState(false);
  const user = useSelector((state) => state.users.user);
  const [newPasswordState, setNewPasswordState] = React.useState(false);
  const [confirmPasswordState, setConfirmPasswordState] = React.useState(false);
  const [formData, setFormData] = React.useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);
  async function changePassword() {
    if (formData.new_password !== formData.confirm_password) {
      error("New password and confirm password does not match");
      return;
    }
    if (formData.old_password === formData.new_password) {
      error("Old password and new password cannot be same");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const data = {
        email: user.email,
        old_password: formData.old_password,
        new_password: formData.new_password,
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/user/change_password_first_time/`,
        data,
        config
      );
      success("Password changed successfully");
      localStorage.removeItem("changePassword");
      navigate("/dashboard");
    } catch (err) {
      error(err.response?.data?.message || "Failed to change password");
    }
    setLoading(false);
  }

  React.useEffect(() => {
    const shouldChangePassword = localStorage.getItem("changePassword");
    if (!shouldChangePassword) {
      navigate("/dashboard");
    }
  });
  return (
    <div className="bg-white rounded-2xl py-8 px-8">
      <div>
        <h2 className="text-xl font-semibold text-center">
          You are required to change your password
        </h2>

        <Stack className="mt-8">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Old Password
          </Typography>
          <Input
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#003163",
                },
              },
            }}
            fullWidth
            type={oldPasswordState ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setOldPasswordState((prev) => !prev)}
                  sx={{ alignSelf: "center" }}
                >
                  <i
                    className={
                      oldPasswordState
                        ? "bi bi-eye-fill"
                        : "bi bi-eye-slash-fill"
                    }
                    style={{ fontSize: 16, color: "#747474" }}
                  ></i>
                </IconButton>
              ),
            }}
            value={formData?.old_password}
            onChange={({ target }) => {
              setFormData((prev) => ({
                ...prev,
                old_password: target.value,
              }));
            }}
            placeholder="Old Password"
          />
        </Stack>

        <Stack className="mt-8">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            New Password
          </Typography>
          <Input
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#003163",
                },
              },
            }}
            fullWidth
            type={newPasswordState ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setNewPasswordState((prev) => !prev)}
                  sx={{ alignSelf: "center" }}
                >
                  <i
                    className={
                      newPasswordState
                        ? "bi bi-eye-fill"
                        : "bi bi-eye-slash-fill"
                    }
                    style={{ fontSize: 16, color: "#747474" }}
                  ></i>
                </IconButton>
              ),
            }}
            value={formData?.new_password}
            onChange={({ target }) => {
              setFormData((prev) => ({
                ...prev,
                new_password: target.value,
              }));
            }}
            placeholder="New Password"
          />
        </Stack>

        <Stack className="mt-8">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Confirm Password
          </Typography>
          <Input
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#003163",
                },
              },
            }}
            fullWidth
            type={confirmPasswordState ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setConfirmPasswordState((prev) => !prev)}
                  sx={{ alignSelf: "center" }}
                >
                  <i
                    className={
                      confirmPasswordState
                        ? "bi bi-eye-fill"
                        : "bi bi-eye-slash-fill"
                    }
                    style={{ fontSize: 16, color: "#747474" }}
                  ></i>
                </IconButton>
              ),
            }}
            value={formData?.confirm_password}
            onChange={({ target }) => {
              setFormData((prev) => ({
                ...prev,
                confirm_password: target.value,
              }));
            }}
            placeholder="Confirm Password"
          />
        </Stack>
        <Stack className="mt-8">
          <Button
            disabled={
              !formData?.old_password ||
              !formData?.new_password ||
              !formData?.confirm_password ||
              formData?.new_password !== formData?.confirm_password
            }
            variant="contained"
            className="w-fit py-2.5 capitalize bg-primary px-12 rounded-xl font-bold text-sm shadow-none text-white disabled:bg-gray-400 disbled:cursor-not-allowed"
            onClick={changePassword}
          >
            {loading ? <CircularProgress color="inherit" size={20} /> : "Save"}
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default index;
