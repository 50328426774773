import axios from "axios";
import { store } from "../store";
import { refreshAuthToken } from "../redux/slices/users/authSlice";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = store.getState().auth.accessToken;

    if (accessToken) {
      config.headers.Authorization = `JWT ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        await store.dispatch(refreshAuthToken());
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // redirect to login
        const history = useNavigate();
        history("/login");
        console.error("Error refreshing token:", refreshError);
        throw refreshError;
      }
    }
    throw error;
  }
);

export default axiosInstance;
