import React from 'react'
import sanitizeHtml from 'sanitize-html';



const defaultOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'td', 'table'],
    allowedAttributes: {
        'a': ['href']
    },
    allowedIframeHostnames: ['www.youtube.com']
};

const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty,
        // options: { ...defaultOptions, ...options }
    )
});

function SanitizeHTML({ html, options }) {
    return (
        <div dangerouslySetInnerHTML={sanitize(html, options)} />
    )
}

export default SanitizeHTML
