import React from "react";
import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Dialog,
} from "@mui/material";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import useFetch from "../../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";
import { Input } from "../../../pages/user-management/ManageProfile";
import Loading from "../../../common/Loading";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../common/ConfirmDialog";
import DoneDialog from "../../../common/DoneDialog";

// const categories = [
//   {
//     label: "Airline Operator",
//     value: "AIRLINE",
//   },
//   {
//     label: "Aircraft Manufacturer",
//     value: "AIRCRAFT",
//   },
//   {
//     label: "NCAA",
//     value: "NCAA",
//   },
//   {
//     label: "Others",
//     value: "OTHERS",
//   },
// ];

const EditStakeholder = () => {
  const { occurrenceId } = useParams();
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/stakeholders/${sectionId}/`
  );

  React.useEffect(() => {
    if (data.data) {
      setFormData((prev) => ({
        ...prev,
        category: data.data.category,
        stakeholder:
          data.data?.occurence_stakeholder?.first_name +
          " " +
          data.data?.occurence_stakeholder?.last_name,
      }));
    }
  }, [data]);
  const [formData, setFormData] = React.useState({
    category: "",
    stakeholder: "",
  });

  // const [loading, setLoading] = React.useState(false);
  // async function handleUpdate() {
  //   if (!formData.category) {
  //     error("Please fill all the fields");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const config = {
  //       headers: {
  //         Authorization: `JWT ${accessToken}`,
  //         "Content-Type": "Application/json",
  //       },
  //     };
  //     await axios.put(
  //       `${process.env.REACT_APP_BASE_API}api/occurrence-manager/stakeholders/${sectionId}/`,
  //       formData,
  //       config
  //     );
  //     navigate(-1);
  //     success("Stakeholder Details updated successfully");
  //   } catch (err) {
  //     error(err.response?.data?.message);
  //   }
  //   setLoading(false);
  // }

  //DELETE
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [loadingDeletion, setLoadingDeletion] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  async function handleDelete() {
    setLoadingDeletion(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.delete(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/stakeholders/${sectionId}/`,
        config
      );
      setConfirmDelete(false);
      setIsDone(true);
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoadingDeletion(false);
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"32px"}>
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={true}
          confirmText="Delete"
          title="Delete Stakeholder"
          isLoading={loadingDeletion}
          description="Are you sure you want to delete stakeholder? This stakeholder won't be able to access this occurrence section."
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={handleDelete}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setIsDone(false), navigate(-1);
        }}
      >
        <DoneDialog
          title="Stakeholder deleted successfully"
          description="You have successfully deleted the stakeholder."
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Update Stakeholder</h2>
      </div>
      <div className="bg-white rounded-2xl py-4 px-8">
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Category
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.category}
              disabled
              placeholder="category"
            />
            {/* <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.category}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    category: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Category"}</p>
                </MenuItem>
                {categories?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Stakeholder
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.stakeholder}
              disabled
              placeholder="Stakeholder"
            />
            {/* <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.stakeholder_id}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    stakeholder_id: e.target.value,
                  }));
                }}
                disabled
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Stakeholder"}</p>
                </MenuItem>
                {stakeholders?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.id}
                    className="text-secondary"
                  >
                    {item.first_name + " " + item.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Stack>
        </div>

        <div className="flex justify-end mt-6 ">
          <Button
            variant="contained"
            className="capitalize rounded-xl font-bold text-sm shadow-none px-12 py-2.5 w-fit mr-5 bg-red-500"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
          {/* <Button
            variant="outlined"
            className="border-primary px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none w-fit"
            onClick={handleUpdate}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Update"
            )}
          </Button> */}
        </div>
      </div>
    </Stack>
  );
};

export default EditStakeholder;
