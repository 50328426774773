import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { ArrowLeft } from "iconsax-react";
import { StyledTableRow } from "../user-data";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";

function ICAOInterim() {
  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/"
  );
  const navigate = useNavigate();

  // PAGINATION

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Stack gap={"24px"}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"8px"}
        justifyContent={"space-between"}
      >
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack
        minHeight={"500px"}
        marginTop={"24px"}
        sx={{
          background: "#fff",
          borderRadius: "16px",
          px: "42px",
          py: "18px",
        }}
      >
        <TableContainer className="mt-6">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-[#AEAEAE] min-w-[180px]">
                  Occurrence Number
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[250px]">
                  Occurrence Name
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[200px]">
                  Occurrence Manager/IIC
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[150px]">
                  Deadline
                </TableCell>
                <TableCell
                  className="text-[#AEAEAE] min-w-[180px]"
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    <div className=" min-h-[20rem] flex items-center justify-center ">
                      <div>
                        <CircularProgress />
                        <p className="text-center animate-pulse mt-2">
                          Loading...
                        </p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : data?.data?.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    <div className="mt-10 mb-10">
                      <p className="text-center font-semibold">No ICAO</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_number}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.title}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_owner?.first_name}{" "}
                        {row?.occurrence_owner?.last_name}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.icao_deadline_date?.replace(/-/g, "/")}
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <Button
                            variant="contained"
                            className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                            onClick={() => navigate(`/icao/users/${row?.id}`)}
                          >
                            View
                          </Button>
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {data?.data?.length && (
        <div className="py-4 flex justify-center mt-6">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(data?.data?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            color="primary"
            page={page + 1}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Stack>
  );
}

export default ICAOInterim;
