import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { FormControlLabel, Checkbox, MenuItem } from "@mui/material";

const CustomCheckBox = styled(Checkbox)(() => ({
  color: "#161616",
  "&.Mui-disabled": {
    color: "#646464",
  },
}));

const CustomFormControlLabel = styled(FormControlLabel)(() => ({
  width: "fit-content",
  marginRight: 0,
  fontSize: "20px",
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
    color: "#161616",
    "&.Mui-disabled": {
      color: "#646464",
    },
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
}));

const CustomMenuItem = styled(MenuItem)({
  outline: "0px",
  "&.MuiMenuItem-root": {
    backgroundColor: "#ffffff",
    height: 49,
    padding: "16px 14px",
    border: 0,
    fontSize: 14,
    borderBottom: "1px solid #CBCBCB",
    // borderBottomColor: error ? '#DA1E28' : border ? '#CBCBCB' : '#003163',
    "&:last-child": { border: 0 },
    "&:active": {
      backgroundColor: "#ffffff",
    },
    "&:hover": {
      backgroundColor: "#f4f4f4",
    },
    "&:focus-visible": {
      outline: 0,
    },
  },
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 657,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export { CustomCheckBox, CustomFormControlLabel, StyledMenu, CustomMenuItem };
