import React, { useState } from "react";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { Alert } from "../../components";
import { Icon } from "@iconify/react";

import { Input } from "./ManageProfile";
import { ArrowLeft } from "iconsax-react";
import useFetch from "../../helpers/useFetch";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { swalDeactivate } from "../../components/common/AlertSwal";

export default function ManageRole() {
  const { id } = useParams();
  const { data, isLoading, refetch } = useFetch(
    `api/identity-manager/roles/${id}/`
  );
  // const {
  //   data: auditData,
  //   isLoading: auditIsLoading,
  //   refetch: auditRefresh,
  // } = useFetch(`api/audit/get_identity_management_trails/`);

  const [updateFormState, setUpdateFormState] = useState();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const userRoles = useSelector((state) => state.users?.user?.roles);

  const lists = ["occurence_manager", "user_management", "media_library"];
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading) {
      setUpdateFormState(data.data);
    }
  }, [data, isLoading]);

  function handleFormChange(e, dictTop, dictLow, field) {
    const { checked } = e.target;
    setUpdateFormState((prev) => {
      if (dictLow) {
        let updatedFormState = {
          ...prev,
          [dictTop]: {
            ...prev[dictTop],
            [dictLow]: {
              ...prev[dictTop][dictLow],
              [field]: checked,
            },
          },
        };
        return updatedFormState;
      } else {
        let updatedFormState = {
          ...prev,
          [dictTop]: {
            ...prev[dictTop],
            [field]: checked,
          },
        };
        console.log(field, "field");
        return updatedFormState;
      }
    });
  }

  const [dialogState, setDialogState] = React.useState(false);

  function showDialog() {
    setDialogState(!dialogState);
  }

  const [checkboxState, setCheckboxState] = React.useState(true);

  function handleEditForm() {
    setCheckboxState(!checkboxState);
  }

  return (
    <Stack gap={"24px"}>
      <Alert
        display={false}
        iconClass={"bi bi-check-circle"}
        color={"#42BE65"}
        title={"Alert"}
        message={"Successful"}
      />

      <Stack marginBottom={"16px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack
        gap={"20px"}
        width={"100%"}
        borderRadius={"16px"}
        bgcolor={"#fff"}
        px={"24px"}
        py={"32px"}
      >
        <Stack width={"288px"} gap={"6px"}>
          <Typography variant="p" color={"#D8D8D8"} className="font-semibold">
            Role Assigned
          </Typography>
          <Input
            disabled={true}
            value={updateFormState?.role}
            // onChange={(e) =>
            //   setUpdateFormState((prev) => ({
            //     ...prev,
            //     role: e.target.value,
            //   }))
            // }
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#D9D9D9",
                },
              },
            }}
            placeholder="Type in role title"
          />
        </Stack>
        {isLoading ? (
          <Stack className="flex justify-center items-center text-center py-20">
            <div>
              <CircularProgress />
              <p>Loading...</p>
            </div>
          </Stack>
        ) : (
          <Stack>
            <Stack>
              <h2 className="font-semibold text-[#D6D6D6] capitalize">
                User Management
              </h2>
              <Stack>
                <div className="mt-2">
                  <h4 className="pl-4 text-sm font-medium text-[#9c9c9c]">
                    User Roles
                  </h4>
                </div>
                <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                  <ul className="flex flex-wrap gap-3">
                    {Object.keys(
                      Object.fromEntries(
                        Object.entries(
                          data?.data?.user_management?.user_roles
                        )?.filter(([, val]) => val == true)
                      )
                    ).map((key) => (
                      <li key={key}>
                        <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                          <span>{key}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Stack>
              <Stack className="mt-6">
                <div>
                  <h4 className="pl-4 text-sm font-medium text-[#9c9c9c]">
                    User Profiles
                  </h4>
                </div>
                <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                  <ul className="flex flex-wrap gap-3">
                    {Object.keys(
                      Object.fromEntries(
                        Object.entries(
                          data?.data?.user_management?.user_profiles
                        )?.filter(([, val]) => val == true)
                      )
                    ).map((key) => (
                      <li key={key}>
                        <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                          <span>{key}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Stack>
            </Stack>
            {/* OCCURENCE SECTION */}
            <Stack className="mt-10">
              <h2 className="font-semibold text-[#D6D6D6] capitalize">
                Occurence Manager
              </h2>
              <Stack>
                <div className="mt-2">
                  <h4 className="pl-4 text-sm font-medium text-[#9c9c9c]">
                    Report Management
                  </h4>
                </div>
                <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                  <ul className="flex flex-wrap gap-3">
                    {Object.keys(
                      Object.fromEntries(
                        Object.entries(
                          data?.data?.occurence_manager?.report_management
                        )?.filter(([, val]) => val == true)
                      )
                    ).map((key) => (
                      <li key={key}>
                        <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                          <span>{key}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Stack>
              <Stack className="mt-6">
                <div>
                  <h4 className="pl-4 text-sm font-medium text-[#9c9c9c]">
                    Content Management
                  </h4>
                </div>
                <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                  <ul className="flex flex-wrap gap-3">
                    {Object.keys(
                      Object.fromEntries(
                        Object.entries(
                          data?.data?.occurence_manager?.content_management
                        )?.filter(([, val]) => val == true)
                      )
                    ).map((key) => (
                      <li key={key}>
                        <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                          <span>{key}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </Stack>
            </Stack>
            <Stack className="mt-6">
              <div>
                <h4 className=" text-sm font-semibold text-[#9c9c9c]">
                  Media Management
                </h4>
              </div>
              <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                <ul className="flex flex-wrap gap-3">
                  {Object.keys(
                    Object.fromEntries(
                      Object.entries(data?.data?.media_library)?.filter(
                        ([, val]) => val == true
                      )
                    )
                  ).map((key) => (
                    <li key={key}>
                      <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                        <span>{key}</span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </Stack>
          </Stack>
        )}
      </Stack>
      {/* <Stack
        gap={"39px"}
        width={"100%"}
        borderRadius={"4px"}
        bgcolor={"#fff"}
        px={"24px"}
        py={"32px"}
      >
      </Stack> */}

      {/* <Stack
        direction={"row"}
        borderRadius={"4px"}
        justifyContent={"space-between"}
        width={"100%"}
        gap={"32px"}
        sx={{
          background: "#fff",
          px: "32px",
          py: "16px",
          height: "fit-content",
        }}
      > */}
      {/* {data?.data?.system_role ? (
          <Button
            disableElevation
            variant="contained"
            onClick={checkboxState ? handleEditForm : handleFormSubmit}
            sx={{
              width: "127px",
              height: "53px",
              textTransform: "none",
              borderRadius: 0,
              "&.MuiButton-contained": {
                background: "#003163",
              },
            }}
          >
            {checkboxState ? "Edit Profile" : "Save"}
          </Button>
        ) : (
          <div></div>
        )} */}

      {/* {data?.data?.system_role ?
                    <Button
                        disableElevation
                        variant='contained'
                        onClick={showDialog}
                        sx={{
                            px: '28px',
                            width: 'fit-content',
                            height: '53px',
                            textTransform: 'none',
                            borderRadius: 0,
                            fontFamily: 'PT Sans',
                            marginRight: 'auto',
                            display: checkboxState ? 'block' : 'none',
                            '&.MuiButton-contained': {
                                background: 'transparent',
                                color: '#003163',
                                border: '1px solid #003163'
                            }
                        }}
                    >
                        Duplicate Role
                    </Button> : <div></div>
                } */}
      {/* {data?.data?.system_role ? (
          <Button
            disableElevation
            onClick={() =>
              swalDeactivate("role", false, () =>
                activateOrDeactivate(id, false)
              )
            }
            variant="contained"
            sx={{
              width: "fit-content",
              px: "28px",
              height: "53px",
              textTransform: "none",
              borderRadius: 0,
              display: checkboxState ? "block" : "none",
              "&.MuiButton-contained": {
                background: "#DA1E28",
              },
            }}
          >
            Deactivate Role
          </Button>
        ) : (
          <Button
            disableElevation
            onClick={() =>
              swalDeactivate("role", true, () => activateOrDeactivate(id, true))
            }
            variant="contained"
            sx={{
              px: "28px",
              width: "fit-content",
              height: "53px",
              textTransform: "none",
              borderRadius: 0,
              marginRight: "auto",
              display: checkboxState ? "block" : "none",
              "&.MuiButton-contained": {
                background: "transparent",
                color: "#003163",
                border: "1px solid #003163",
              },
            }}
          >
            Activate Role
          </Button>
        )} */}

      {/* <Button
          disableElevation
          onClick={handleEditForm}
          variant="contained"
          sx={{
            width: "fit-content",
            px: "28px",
            height: "53px",
            textTransform: "none",
            borderRadius: 0,
            display: checkboxState ? "none" : "false",
            "&.MuiButton-contained": {
              border: "1px solid #DA1E28",
              color: "#DA1E28",
              background: "transparent",
            },
          }}
        >
          Cancel
        </Button> */}
      {/* </Stack> */}

      {/* {userRoles?.role?.toLowerCase()?.includes("super-administrator") && (
        <Stack width={"100%"}>
          <Stack
            gap={"4px"}
            direction={"row"}
            alignItems={"center"}
            sx={{
              background: "#F1C21B",
              width: "100%",
              padding: "24px 42px",
              borderRadius: "4px 4px 0 0",
            }}
          >
            <img src={historyIcon} alt="" />
            <Typography color={"#161616"} fontSize={"12px"} fontWeight={700}>
              Audit Trail
            </Typography>
          </Stack>
          <Stack
            minHeight={"200px"}
            sx={{
              p: "18px 42px 38px 42px",
              background: "#fff",
            }}
          >
            <Stack
              gap={"32px"}
              sx={{
                borderLeft: "1px solid",
                borderColor: "#7E7E7E",
              }}
            >
              {auditData?.data?.map((eachAudit) => (
                <Stack
                  key={eachAudit.id}
                  direction={"row"}
                  gap={"8px"}
                  position={"relative"}
                  paddingLeft={"48px"}
                >
                  <img
                    src={pointIcon}
                    style={{
                      position: "absolute",
                      left: "-12px",
                    }}
                    alt=""
                  />
                  <Typography fontWeight={700} fontSize={14}>
                    {eachAudit.fullname}
                  </Typography>
                  <Typography
                    fontStyle={"italic"}
                    fontWeight={400}
                    fontSize={14}
                  >
                    {eachAudit.action}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={14}
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    {eachAudit.time}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )} */}
    </Stack>
  );
}
