import React from "react";
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { generateRandomNumbersBetween } from "../../utils/helpers";
import useFetch from "../../helpers/useFetch";
import { isUpperCase } from "../../utils/helpers";
import { ArrowLeft } from "iconsax-react";

// IMAGES
import firstDp from "../../assets/emojis/black-girl.svg";
import secondDp from "../../assets/emojis/boy-with-cap.svg";
import thirdDp from "../../assets/emojis/light-girl-1.svg";
import fourthDp from "../../assets/emojis/man-with-hat.svg";

const columns = [
  {
    id: "sn",
    label: "S/N",
  },
  {
    id: "photo",
    label: "Profile Photo",
    minWidth: 140,
  },
  {
    id: "fullname",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "role",
    label: "Role",
    minWidth: 200,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
  },
];

const pictures = [firstDp, secondDp, thirdDp, fourthDp];

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  a: {
    border: 0,
  },
  "td, th": {
    border: 0,
  },
}));

const UserData = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useFetch(
    `api/identity-manager/roles/${id}/users`
  );

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();
  return (
    <Stack>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack className="bg-white rounded-2xl py-8 px-10 mt-6">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography fontSize={13} color={"#AEAEAE"} variant="body2">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <CircularProgress />
                      <div>
                        <p className="text-center animate-pulse">Loading...</p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (data?.data?.length === 0 || !data?.data) && !isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <Typography
                        fontSize={14}
                        color={"#161616"}
                        variant="body2"
                      >
                        No users found
                      </Typography>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          fontWeight={500}
                          component="a"
                          href={`/user-data/${row.id}`}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            variant="body2"
                          >
                            {column?.id === "sn" ? (
                              index + 1 + "."
                            ) : column?.id === "photo" ? (
                              <div className="w-10 h-10 rounded-full bg-primary grid place-content-center">
                                <img
                                  src={
                                    pictures[generateRandomNumbersBetween(0, 3)]
                                  }
                                  alt="user"
                                />
                              </div>
                            ) : column?.id == "role" ? (
                              <span
                                className={`${
                                  isUpperCase(row[column?.id])
                                    ? "capitalize"
                                    : ""
                                }`}
                              >
                                {isUpperCase(row[column.id])
                                  ? row[column.id]
                                      ?.replace("-", " ")
                                      ?.toLowerCase()
                                  : row[column.id]?.replace("-", " ")}
                              </span>
                            ) : column?.id === "status" ? (
                              <span>
                                {row?.is_active ? "Active" : "Inactive"}
                              </span>
                            ) : (
                              row[column.id]
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {!isLoading && (
        <div className="pb-4 pt-10 flex justify-center">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(data.data?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page + 1}
            color="primary"
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // onChange={handlePageChange}
          />
        </div>
      )}
    </Stack>
  );
};

export default UserData;

// import React from "react";
// import ConfirmDialog from "../../common/ConfirmDialog";
// import DoneDialog from "../../common/DoneDialog";

// import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import useFetch from "../../helpers/useFetch";
// import { isUpperCase } from "../../utils/helpers";
// import {
//   Button,
//   CircularProgress,
//   Stack,
//   Typography,
//   Dialog,
// } from "@mui/material";
// import { Input } from "../user-management/ManageProfile";
// import { error } from "../../common/Notifications";
// import { ArrowLeft } from "iconsax-react";
// import axios from "axios";

// const RoleDetail = () => {
//   const { id } = useParams();
//   const { data, isLoading, refetch } = useFetch(
//     `api/identity-manager/roles/${id}/`
//   );
//   const navigate = useNavigate();
//   const userRoles = useSelector((state) => state.users?.user?.roles);
//   const accessToken = useSelector((state) => state.auth.accessToken);

//   const [isSuperUser, setIsSuperUser] = React.useState(false);

//   React.useEffect(() => {
//     if (userRoles?.role?.toLowerCase()?.includes("super-user")) {
//       setIsSuperUser(true);
//     }
//   }, [userRoles]);

//   // ACTIONS
//   const [confirmActivation, setConfirmActivation] = React.useState(false);
//   const [confirmDelete, setConfirmDelete] = React.useState(false);
//   const [confirmDeactivation, setConfirmDeactivation] = React.useState(false);
//   const [isDone, setIsDone] = React.useState(false);
//   const [doneDetails, setDoneDetails] = React.useState({
//     title: "Request Successful",
//     message: "Your request has been set",
//   });
//   const [requestIsLoading, setRequestLoading] = React.useState(false);

//   async function handleAction(type) {
//     setRequestLoading(true);
//     try {
//       const config = {
//         headers: {
//           Authorization: `JWT ${accessToken}`,
//           "Content-Type": "multipart/form-data",
//         },
//       };
//       const payload = {
//         ...data?.data,
//         system_role: type === "activate" ? true : false,
//       };

//       if (type !== "delete") {
//         await axios.put(
//           `${process.env.REACT_APP_BASE_API}api/identity-manager/roles/${id}/activate_and_deactivate_role/`,
//           payload,
//           config
//         );
//         setConfirmActivation(false);
//         setConfirmDeactivation(false);
//         setDoneDetails({
//           title:
//             type === "activate"
//               ? "Successfully Activated"
//               : "Successfully Deactivated",
//           message:
//             type === "activate"
//               ? "You have successfully activated this user"
//               : "You have successfully deactivated this user",
//         });
//       } else {
//         await axios.delete(
//           `${process.env.REACT_APP_BASE_API}api/identity-manager/roles/${id}/`,
//           config
//         );
//         setConfirmDelete(false);
//         setDoneDetails({
//           title: "Successfully Deleted",
//           message: "You have successfully deleted this user",
//         });
//       }

//       setIsDone(true);
//     } catch (err) {
//       error(err?.response?.data?.error || "Something went wrong");
//     }
//     setRequestLoading(false);
//   }

//   return (
//     <Stack>
//       <Dialog
//         open={confirmActivation}
//         onClose={() => setConfirmActivation(false)}
//         sx={{
//           "& .MuiDialog-paper": {
//             borderRadius: "16px",
//             width: "90%",
//             maxWidth: "450px",
//           },
//         }}
//       >
//         <ConfirmDialog
//           isDelete={false}
//           confirmText="Activate"
//           title="Activate User"
//           isLoading={requestIsLoading}
//           description="Are you sure you want to activate this user?"
//           handleClose={() => setConfirmActivation(false)}
//           handleConfirm={() => handleAction("activate")}
//         />
//       </Dialog>
//       <Dialog
//         open={confirmDelete}
//         onClose={() => setConfirmDelete(false)}
//         sx={{
//           "& .MuiDialog-paper": {
//             borderRadius: "16px",
//             width: "90%",
//             maxWidth: "450px",
//           },
//         }}
//       >
//         <ConfirmDialog
//           isDelete={true}
//           confirmText="Delete"
//           title="Delete Role"
//           isLoading={requestIsLoading}
//           description="Are you sure you want to delete this role?"
//           handleClose={() => setConfirmDelete(false)}
//           handleConfirm={() => handleAction("delete")}
//         />
//       </Dialog>
//       <Dialog
//         open={confirmDeactivation}
//         onClose={() => setConfirmDeactivation(false)}
//         sx={{
//           "& .MuiDialog-paper": {
//             borderRadius: "16px",
//             width: "90%",
//             maxWidth: "450px",
//           },
//         }}
//       >
//         <ConfirmDialog
//           isDelete={true}
//           confirmText="Deactivate"
//           title="Deactivate User"
//           isLoading={requestIsLoading}
//           description="Are you sure you want to deactivate this role?"
//           handleClose={() => setConfirmDeactivation(false)}
//           handleConfirm={() => handleAction("deactivate")}
//         />
//       </Dialog>
//       <Dialog
//         open={isDone}
//         sx={{
//           "& .MuiDialog-paper": {
//             borderRadius: "16px",
//             width: "90%",
//             maxWidth: "550px",
//           },
//         }}
//         onClose={() => {
//           setIsDone(false), navigate(-1);
//         }}
//       >
//         <DoneDialog
//           title={doneDetails.title}
//           description={doneDetails.message}
//         />
//       </Dialog>
//       <Stack>
//         <div
//           className="flex items-center gap-1 cursor-pointer px-6"
//           onClick={() => navigate(-1)}
//         >
//           <ArrowLeft size="22" color="#000000" />
//           <p>Back</p>
//         </div>
//       </Stack>

//       <Stack className="bg-white rounded-2xl pt-8 pb-16 px-10 mt-6">
//         <div className="border-b pb-4">
//           <h2 className="font-semibold text-xl">Role Setting</h2>
//         </div>
//         {isLoading ? (
//           <div className="min-h-[24rem] flex items-center justify-center text-center">
//             <div>
//               <CircularProgress />
//               <p>Loading...</p>
//             </div>
//           </div>
//         ) : (
//           <>
//             <Stack
//               direction={"row"}
//               gap={"30px"}
//               marginTop={"24px"}
//               className="lg:max-w-[80%]"
//             >
//               <Stack className="flex-1">
//                 <Typography
//                   variant="p"
//                   color={"#D8D8D8"}
//                   className="font-semibold pb-1.5"
//                 >
//                   Role Title
//                 </Typography>
//                 <Input
//                   disabled={true}
//                   value={
//                     isUpperCase(data?.data?.role)
//                       ? data?.data?.role?.replace("-", " ")?.toLowerCase()
//                       : data?.data?.role?.replace("-", " ")
//                   }
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "#D9D9D9",
//                       },
//                     },
//                   }}
//                   placeholder="Type in role title"
//                 />
//               </Stack>
//               <Stack className="flex-1">
//                 <Typography
//                   variant="p"
//                   color={"#D8D8D8"}
//                   className="font-semibold pb-1.5"
//                 >
//                   Number of Users Attached
//                 </Typography>
//                 <Input
//                   disabled={true}
//                   value={data?.data?.user_count}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "#D9D9D9",
//                       },
//                     },
//                   }}
//                   placeholder="Number of Users Attached"
//                 />
//               </Stack>
//             </Stack>
//             <Stack
//               direction={"row"}
//               gap={"30px"}
//               marginTop={"32px"}
//               className="lg:max-w-[80%]"
//             >
//               <Stack className="flex-1">
//                 <Typography
//                   variant="p"
//                   color={"#D8D8D8"}
//                   className="font-semibold pb-1.5"
//                 >
//                   Admin who Created Role
//                 </Typography>
//                 <Input
//                   disabled={true}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "#D9D9D9",
//                       },
//                     },
//                   }}
//                   placeholder="Admin name"
//                 />
//               </Stack>
//               <Stack className="flex-1">
//                 <Typography
//                   variant="p"
//                   color={"#D8D8D8"}
//                   className="font-semibold pb-1.5"
//                 >
//                   Phone number of Admin
//                 </Typography>
//                 <Input
//                   disabled={true}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "#D9D9D9",
//                       },
//                     },
//                   }}
//                   placeholder="Admin phone number"
//                 />
//               </Stack>
//             </Stack>
//             <Stack className=" lg:max-w-[80%] ">
//               <Stack marginTop={"32px"} className="w-[50%] pr-4">
//                 <Typography
//                   variant="p"
//                   color={"#D8D8D8"}
//                   className="font-semibold pb-1.5"
//                 >
//                   Date Created
//                 </Typography>
//                 <Input
//                   disabled={true}
//                   value={new Date(data?.data?.created_at).toLocaleString(
//                     "en-US",
//                     {
//                       day: "2-digit",
//                       month: "2-digit",
//                       year: "numeric",
//                     }
//                   )}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "#D9D9D9",
//                       },
//                     },
//                   }}
//                   placeholder="Date Created"
//                 />
//               </Stack>
//             </Stack>
//           </>
//         )}

//         {isSuperUser && !isLoading && (
//           <>
//             {data?.data?.system_role ? (
//               <Stack direction={"row"} gap={"30px"} marginTop={"52px"}>
//                 <Button
//                   variant="outlined"
//                   className="text-red-500 py-2 font-bold px-12 rounded-xl capitalize shadow-none border-2 border-red-500"
//                   onClick={() => setConfirmDeactivation(true)}
//                 >
//                   Deactivate Role
//                 </Button>
//                 <Button
//                   variant="contained"
//                   className="bg-red-500 py-2 shadow-none border-2 border-red-500 text-white font-bold rounded-xl px-12 capitalize"
//                   onClick={() => setConfirmDelete(true)}
//                 >
//                   Delete Role
//                 </Button>
//               </Stack>
//             ) : (
//               <Stack direction={"row"} gap={"30px"} marginTop={"42px"}>
//                 <Button
//                   variant="contained"
//                   className="bg-primary-500 py-2 shadow-none border-2 border-primary-500 text-white font-bold rounded-xl px-12 capitalize"
//                   onClick={() => setConfirmActivation(true)}
//                 >
//                   Activate Role
//                 </Button>
//               </Stack>
//             )}
//           </>
//         )}
//       </Stack>
//     </Stack>
//   );
// };

// export default RoleDetail;
