import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import DrawerList from "./DrawerList";
import { Drawer, AppBar } from "./Drawer";

import logo from "../../assets/general/nsib_logo.png";
import argue from "../../assets/general/argue.png";
import report from "../../assets/general/report.png";

import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import SingleIndexDrawerList from "./SingleOverviewDrawerList";
import SingleOverviewDrawerList from "./SingleOverviewDrawerList";
import { useParams } from "react-router-dom";
import useFetch from "../../helpers/useFetch";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Argue
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 256;

const defaultTheme = createTheme();

function OccurrenceIndexLayout({ singleIndex, singleOverview }) {
  const location = useLocation();
  const { pathname } = location;
  const { section_name } = useParams();
  const [open, setOpen] = React.useState(true);
  const { occurrenceId } = useParams();
  const [publishedData, setPublishedData] = React.useState([]);
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/retrieve_occurrence_index_information/`
  );

  React.useEffect(() => {
    if (!isLoading) {
      const published_status = data?.data?.occurrence_section?.filter(
        (obj) => obj?.published_status === true
      );
      setPublishedData(published_status);
    }
  }, [isLoading, data]);

  console.log(publishedData, "published data");

  function handleFilterSearch() {
    setFilteredData();
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          className="bg-white shadow-none py-1 z-[9999] border-b"
        >
          <Toolbar>
            <div className="flex justify-between items-center w-full px-16">
              <div className="flex gap-1">
                <img
                  src={argue}
                  alt=""
                  className="w-[96px] h-[24px] transition-all "
                />
                {open && (
                  <img
                    src={report}
                    alt=""
                    className="h-[24px] transition-all"
                  />
                )}
              </div>

              <div className="flex gap-2 items-center">
                <div className="flex gap-4 items-center">
                  <img src={logo} alt="" className="h-[120px]" />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <List
            component="nav"
            className={`transition-all flex flex-col justify-between h-[90vh]`}
          >
            {singleIndex ? (
              <SingleIndexDrawerList
                open={open}
                pathName={section_name}
                data={publishedData}
              />
            ) : singleOverview ? (
              <SingleOverviewDrawerList open={open} pathName={pathname} />
            ) : (
              <DrawerList open={open} pathName={pathname} />
            )}
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Outlet context={[publishedData, data, isLoading, refetch]} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default OccurrenceIndexLayout;
