import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";

const columns = [
  {
    id: "occurence_number",
    label: "Occurence Number",
    minWidth: 170,
    align: "center",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "center",
  },
  {
    id: "category",
    label: "Category",
    minWidth: 80,
    align: "center",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 60,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 50,
    align: "center",
  },
];

const OccurrencePendingTable = ({ occurrences, loading }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="bg-primary rounded-t-2xl px-4 py-6 text-white">
        <h2>New Occurrence ({occurrences?.length || 0})</h2>
      </div>
      <TableContainer className="bg-white rounded-b-2xl px-5">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography className="text-[#AEAEAE] font-medium text-sm">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && !occurrences ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <CircularProgress />
                    <div>
                      <p className="text-center animate-pulse">Loading...</p>
                    </div>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : occurrences?.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <p className="text-center">No pending occurence</p>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : (
              occurrences?.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.occurrence_number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.occurrence_category}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-xs">
                      {item.occurrence_type}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      className="text-sm bg-primary font-bold capitalize shadow-none"
                      onClick={() =>
                        navigate(
                          item?.is_submitted
                            ? `/occurrence/manage-occurrence/${item.id}`
                            : `/occurrence/manage-occurrence/${item.id}/manage-sections`
                        )
                      }
                    >
                      {item?.is_submitted ? "View" : "Edit"}
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OccurrencePendingTable;
