import React from "react";
import SanitizeHTML from "../../occurence-management/section-owner/SanitizeHTML";

function SafetyRecommendation({ sectionData }) {
  return (
    <div className="">
      <div>
        <p className="font-bold text-2xl">Safety Recommendation</p>
      </div>

      <div className="mt-6 bg-white p-8 rounded-xl shadow-md">
        <div className="flex justify-between">
          <p className="font-bold text-base">{sectionData.title}</p>
          <p className="text-xs text-secondary">
            Status:{" "}
            <span className="text-sm text-primary font-bold">
              {sectionData.status}
            </span>
          </p>
        </div>
        <hr className="my-3" />
        <SanitizeHTML html={sectionData.recommendation} />
      </div>
    </div>
  );
}

export default SafetyRecommendation;
