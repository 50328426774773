import React from "react";
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { generateRandomNumbersBetween } from "../../utils/helpers";
import useFetch from "../../helpers/useFetch";
import { isUpperCase } from "../../utils/helpers";
import { ArrowLeft } from "iconsax-react";

// IMAGES
import firstDp from "../../assets/emojis/black-girl.svg";
import secondDp from "../../assets/emojis/boy-with-cap.svg";
import thirdDp from "../../assets/emojis/light-girl-1.svg";
import fourthDp from "../../assets/emojis/man-with-hat.svg";

const columns = [
  {
    id: "sn",
    label: "S/N",
  },
  {
    id: "photo",
    label: "Profile Photo",
    minWidth: 140,
  },
  {
    id: "fullname",
    label: "Name",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "role",
    label: "Role",
    minWidth: 200,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
  },
];

const pictures = [firstDp, secondDp, thirdDp, fourthDp];

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  a: {
    border: 0,
  },
  "td, th": {
    border: 0,
  },
}));

const UserData = () => {
  const { data, isLoading, refetch } = useFetch("api/user/");

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();
  return (
    <Stack>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack className="bg-white rounded-2xl py-8 px-10 mt-6">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography fontSize={13} color={"#AEAEAE"} variant="body2">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <CircularProgress />
                      <div>
                        <p className="text-center animate-pulse">Loading...</p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (data?.data?.length === 0 || !data?.data) && !isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <Typography
                        fontSize={14}
                        color={"#161616"}
                        variant="body2"
                      >
                        No users found
                      </Typography>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          fontWeight={500}
                          component="a"
                          href={`/user-data/${row.id}`}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            variant="body2"
                          >
                            {column?.id === "sn" ? (
                              index + 1 + "."
                            ) : column?.id === "photo" ? (
                              <div className="w-10 h-10 rounded-full bg-primary grid place-content-center">
                                <img
                                  src={
                                    pictures[generateRandomNumbersBetween(0, 3)]
                                  }
                                  alt="user"
                                />
                              </div>
                            ) : column?.id == "role" ? (
                              <span
                                className={`${
                                  isUpperCase(row[column?.id])
                                    ? "capitalize"
                                    : ""
                                }`}
                              >
                                {isUpperCase(row[column.id])
                                  ? row[column.id]
                                      ?.replace("-", " ")
                                      ?.toLowerCase()
                                  : row[column.id]?.replace("-", " ")}
                              </span>
                            ) : column?.id === "status" ? (
                              <span>
                                {row?.is_active ? "Active" : "Inactive"}
                              </span>
                            ) : (
                              row[column.id]
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {!isLoading && (
        <div className="pb-4 pt-10 flex justify-center">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(data.data?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page + 1}
            color="primary"
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // onChange={handlePageChange}
          />
        </div>
      )}
    </Stack>
  );
};

export default UserData;
