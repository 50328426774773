import * as React from "react";
// import div from '@mui/material/div';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";

// const navLinksq = [
//   { id: 1, name: 'Dashboard', path: 'dashboard', icon: <DashboardIcon className={`${pathName.includes('dashboard') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} /> },
// ]
function SingleOverviewDrawerList({ open, pathName, data }) {
  const navigate = useNavigate();
  const { occurrenceId } = useParams();

  const navLinks = [
    ...(data || []).map((item, index) => ({
      id: item.id,
      name: item.section_name,
      link: `/occurence-index/${occurrenceId}/${item.id}/${item.slug_name}/`,
      pathName: item.slug_name,
    })),
  ];

  // { id: 2, name: 'Aircraft Details', link: '', pathName: 'aircraft' },
  // { id: 3, name: 'Recorders', link: '', pathName: 'recorders' },
  // { id: 4, name: 'Systems', link: '', pathName: 'systems' },
  // { id: 5, name: 'Flight Controls', link: '', pathName: 'flight-controls' },
  // { id: 6, name: 'Weather', link: '', pathName: 'weather' },
  // { id: 7, name: 'Fire', link: '', pathName: 'fire' },
  // { id: 8, name: 'Aerodrome/Crash Site', link: '', pathName: 'crash' },
  // { id: 9, name: 'ATC', link: '', pathName: 'atc' },
  // { id: 10, name: 'Flight History', link: '', pathName: 'flight-history' },
  // { id: 11, name: 'SOPs', link: '', pathName: 'sops' },
  // { id: 12, name: 'Transcript', link: '', pathName: 'transcript' },
  // { id: 13, name: 'Operations', link: '', pathName: 'operations' },
  // { id: 14, name: 'Structures', link: '', pathName: 'structures' },
  // { id: 15, name: 'Crew/Survivability', link: '', pathName: 'crew' },
  // { id: 16, name: 'FOD/Bird/Drone Strike', link: '', pathName: 'fod' },
  // { id: 17, name: 'Witnesses', link: '', pathName: 'witness' },
  // { id: 18, name: 'Flight Data Analytics', link: '', pathName: 'flight-data' },
  // { id: 19, name: 'Conclusion', link: '', pathName: 'connclusion' },
  // { id: 20, name: 'Recommendation', link: '/occurence-index/1/safety-recommendation', pathName: 'recommendation' },
  // { id: 21, name: 'Similar Occurrences (2)', link: '/occurence-index/1/similar-occurence', pathName: 'similar' },

  return (
    <div className="mt-[70px]">
      <div className="pl-16 pr-8">
        <div className="mt-10 flex flex-col">
          <Link to={`/`}>
            <Button className="border border-solid border-primary text-primary capitalize rounded-none py-3 mt-9">
              <ChevronLeftIcon />
              Occurrence Overview
            </Button>
          </Link>
        </div>

        <div className="mt-7">
          {navLinks.map((links, index) => (
            <Link to={links.link} key={links.id} className="">
              <p
                className={`py-4 my-2  font-bold ${
                  pathName === links.pathName
                    ? "text-primary hover:text-primary border-r-2 border-primary"
                    : "text-black group-hover:text-gray-600"
                }`}
              >
                {links.name}
              </p>
              {index !== navLinks.length - 1 ? <hr /> : null}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SingleOverviewDrawerList;
