import axios from "axios";

const API = process.env.REACT_APP_BASE_API;

export const loginUser = async (data, thunkAPI) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`${API}api/xauth/signin/`, data, config);
    return res?.data;
    // await thunkAPI.dispatch(fetchUser())
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

export const verifyUser = async (data, thunkAPI) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`${API}api/xauth/verify_code/`, data, config);
    return res?.data;
    // await thunkAPI.dispatch(fetchUser())
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

export const logoutUser = async (thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.accessToken;
  try {
    const config = {
      headers: {
        Authorization: `JWT ${accessToken}`,
      },
    };
    const res = await axios.get(`${API}api/xauth/signOut/`, config);
    return res?.data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

export const refreshTokenUser = async (thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.accessToken;

  const email = thunkAPI.getState().users.user.email;

  try {
    const config = {
      headers: {
        Authorization: `JWT ${accessToken}`,
      },
    };
    const res = await axios.put(
      `${API}api/xauth/refresh_token/`,
      { email: `${email}` },
      config
    );
    return res?.data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue(error.response.message);
  }
};

export const onboardUser = async (formdata) => {
  const response = await axios.post("/api/user", {
    formdata,
  });
  return response.data;
};

export const passwordResetcomplete = async (password, re_password, token) => {
  const response = await axios.post(
    `/api/user/password_reset_complete/?token=${token}`,
    {
      password,
      re_password,
    }
  );

  return response.data;
};

export const passwordChange = async (old_password, new_password) => {
  const response = await axios.post("/api/user/change_password", {
    old_password,
    new_password,
  });

  return response.data;
};

export const firstPasswordChange = async (
  email,
  old_password,
  new_password
) => {
  const response = await axios.post("/api/user/change_password_first_time", {
    email,
    old_password,
    new_password,
  });

  return response.data;
};
