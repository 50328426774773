import React from "react";
import {
  Stack,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableBody,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MediaList = ({ deleteImage, images, download }) => {
  const userRoles = useSelector((state) => state.users?.user?.roles);

  function downloadImage(imageUrl, fileName) {
    // Creating an anchor element to trigger the download
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8, " + encodeURIComponent(imageUrl)
    );
    element.setAttribute("download", fileName);
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }

  return (
    <Stack
      minHeight={"500px"}
      justifyContent={images.length < 1 ? "center" : "flex-start"}
      sx={{
        background: "#fff",
        borderRadius: "0px 0px 4px 4px",
      }}
    >
      <TableContainer
        component={Stack}
        display={images.length < 1 ? "none" : "block"}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  px: 0,
                  py: "0px",
                  borderWidth: 0,
                  height: "fit-content",
                }}
              >
                <p className="text-secondary text-xs ">File</p>
              </TableCell>

              <TableCell
                sx={{
                  px: 0,
                  py: "0px",
                  borderWidth: 0,
                  height: "fit-content",
                }}
                align="left"
              >
                <p className="text-secondary text-xs ">Uploaded by</p>
              </TableCell>

              <TableCell
                sx={{
                  px: 0,
                  py: "0px",
                  borderWidth: 0,
                  height: "fit-content",
                }}
                align="left"
              >
                <p className="text-secondary text-xs ">Date</p>
              </TableCell>

              <TableCell
                sx={{
                  px: 0,
                  py: "0",
                  borderWidth: 0,
                  width: "100px",
                  height: "fit-content",
                }}
                align="left"
              >
                <p className="text-secondary text-xs ">Action</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mt-8">
            {images.map((row, index) => (
              <TableRow key={index} sx={{ height: "68px" }}>
                <TableCell
                  sx={{
                    px: 0,
                    py: "10px",
                    minHeight: "32px",
                    minWidth: "50px",
                    borderWidth: 0,
                  }}
                  component="th"
                  scope="row"
                >
                  <div className="flex gap-6 items-center">
                    {row.file_type === ".mp4" ? (
                      <video src={row.url} className="w-16  h-16"></video>
                    ) : (
                      <img
                        src={row.url}
                        alt={`${row.alt_text}`}
                        className="w-16  h-16"
                      />
                    )}

                    <div>
                      <p className="font-bold text-sm ">{row.title}</p>
                      <p className="">{row.file_name}</p>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{
                    px: 0,
                    py: "10px",
                    minHeight: "32px",
                    borderWidth: 0,
                  }}
                  component="th"
                  scope="row"
                >
                  <p className=" font-bold">{row.uploader}</p>
                </TableCell>

                <TableCell
                  sx={{
                    px: 0,
                    py: "10px",
                    minHeight: "32px",
                    borderWidth: 0,
                  }}
                  component="th"
                  scope="row"
                >
                  <p className="">{row.formatted_upload_date}</p>
                </TableCell>

                <TableCell
                  sx={{
                    px: 0,
                    py: "10px",
                    minHeight: "32px",
                    borderWidth: 0,
                  }}
                  component="th"
                  scope="row"
                >
                  <div className="flex gap-5">
                    <Link to={`/media/${row.id}`}>
                      <p className="">View</p>
                    </Link>
                    {userRoles?.media_library?.import && (
                      <button
                        onClick={() =>
                          downloadImage(
                            row.url,
                            `${row?.title}${row?.file_type}`
                          )
                        }
                        className=" text-primary"
                      >
                        Download
                      </button>
                    )}
                    {userRoles?.media_library?.delete && (
                      <button onClick={deleteImage} className=" text-red-600">
                        Delete
                      </button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack
        justifySelf={"center"}
        display={images.length > 0 ? "none" : "block"}
        justifyItems={"center"}
        gap={"7px"}
        alignItems={"center"}
        alignSelf={"center"}
      >
        {/* <img src={fileIconGrey} width={'32px'} style={{ margin: '0px auto' }} height={'32px'} alt="" /> */}
        <Typography
          fontFamily={"PT Sans"}
          color={"#CBCBCB"}
          fontSize={"16px"}
          lineHeight={"19.2px"}
          letterSpacing={"0.32px"}
        >
          No Media, Add new media
        </Typography>
      </Stack>
    </Stack>
  );
};

// MediaList.propTypes = {
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       url: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   selectedImages: PropTypes.arrayOf(PropTypes.string).isRequired,
//   onImageSelect: PropTypes.func.isRequired,
// };

{
  /* {images.map((image) => (
        <ListItem key={image.id}>
          <ListItemText primary={`Image ${image.id}`} />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => onImageSelect(image.id)}
              edge="end"
              style={{ color: selectedImages.includes(image.id) ? 'green' : 'gray' }}
            >
              {selectedImages.includes(image.id) ? (
                <CheckCircleIcon fontSize="large" />
              ) : (
                <PhotoIcon fontSize="large" />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))} */
}
export default MediaList;
