import { useNavigate } from "react-router-dom";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";

const columns = [
  { id: "sn", label: "S/N" },
  { id: "full_name", label: "Full Name", minWidth: 140 },
  {
    id: "date",
    label: "Date Requested",
    minWidth: 150,
    align: "center",
    format: (value) =>
      new Date(value).toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  " td,  th": {
    border: 0,
  },
}));

function requestTable({ requestsData, isLoadingRequests }) {
  const navigate = useNavigate();

  return (
    <div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className="text-gray-500 font-medium"
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody className="min-h-[60dvh]">
            {isLoadingRequests ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10">
                    <CircularProgress />
                    <div>
                      <p className="text-center animate-pulse">Loading...</p>
                    </div>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : !requestsData?.length ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10">
                    <p className="text-center">No data available</p>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : (
              requestsData?.slice(0, 10)?.map((row, index) => {
                console.log(row);
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row?.code}
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/reset-requests/${row?.id}`);
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell key={column?.id} align={column?.align}>
                          <span className="font-medium text-sm py-4">
                            {column?.id === "sn" ? (
                              <p>{index + 1}</p>
                            ) : column?.id === "status" ? (
                              <div
                                className={`${
                                  row?.is_done === false
                                    ? "bg-yellow-50"
                                    : row?.is_done === true
                                    ? "bg-green-50"
                                    : "bg-red-50"
                                } px-4 w-fit mx-auto py-1 rounded-full`}
                              >
                                <p
                                  className={`${
                                    row?.is_done === false
                                      ? "text-yellow-500"
                                      : row?.is_done === true
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {row?.is_done === false
                                    ? "Pending"
                                    : "Resolved"}
                                </p>
                              </div>
                            ) : column?.id === "message" ? (
                              <p className="truncate">{row[column?.id]}</p>
                            ) : column?.format ? (
                              column?.format(row?.created_at)
                            ) : (
                              <p>{row[column?.id]}</p>
                            )}
                          </span>
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default requestTable;
