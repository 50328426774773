import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Dialog,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import { demoAvatar } from "../../assets";
import { Form, Input } from "./ManageProfile";
import { success, error, warning } from "../../common/Notifications";
import axios from "axios";
import useFetch from "../../helpers/useFetch";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowLeft, Profile } from "iconsax-react";
import { CheckBox } from "@mui/icons-material";
import { stakeholderCategories } from "../../components/occurrence/manager-edits/StakeholderPermission";
import CustomSelect from "../../components/common/CustomSelect";

const roles = [
  {
    id: "1",
    value: "Super Admin",
  },
];

export default function CreateNewUser() {
  const { data: aircraftManufacturerData, isLoading: manufacturerIsLoading } =
    useFetch(`api/settings/manufacturer/`);
  // const { data: aircraftTypesData } = useFetch(`api/settings/aircraft-type/`);
  const { data: aircraftTypesData, isLoading: aircraftTypesIsLoading } =
    useFetch(`api/settings/airline/`);
  const inputRef = React.useRef(null);
  const navigate = useNavigate();
  const onButtonClick = () => {
    inputRef.current.click();
  };
  const initialData = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    roles: "",
    password: "",
    confirmPassword: "",
    tenancy: "AIBNIGERIA",
    require_change_password: false,
    generate_password: false,
    user_type: "MANAGEMENT",
    profile_image: null,
  };

  const [generalFormState, setGeneralFormState] = useState(initialData);
  // const [passwordState, setPasswordState] = useState(false);
  // const [confirmPasswordState, setConfirmPasswordState] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [shouldShowSubCategory, setShouldShowSubCategory] = useState(false);
  const [shouldShowCategory, setShouldShowCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [allStakeholder, setAllStakeholder] = useState([]);
  const [stakeholderFormState, setStakeholderFormState] = useState({
    code: "",
    code_name: "",
    sub_code: "",
    sub_code_name: "",
  });

  React.useEffect(() => {
    // CHECK FOR SUB CATEGORY
    if (
      (stakeholderFormState?.code.toString() &&
        stakeholderFormState?.code.toString()?.toUpperCase() === "MANUFACTURER") ||
      stakeholderFormState?.code.toString()?.toUpperCase() === "AIRLINE"
    ) {
      if (stakeholderFormState?.code.toString()?.toUpperCase() === "MANUFACTURER") {
        setSubCategories(aircraftManufacturerData?.data);
      } else if (stakeholderFormState?.code.toString()?.toUpperCase() === "AIRLINE") {
        setSubCategories(aircraftTypesData?.data);
      } else {
        setSubCategories([]);
      }
      setShouldShowSubCategory(true);
    } else {
      setShouldShowSubCategory(false);
      setStakeholderFormState((prev) => ({ ...prev, sub_code: null }));
    }

    // CHECK FOR CATEGORY
    if (generalFormState.roles && generalFormState?.roles === "STAKEHOLDER") {
      setShouldShowCategory(true);
    } else {
      setShouldShowCategory(false);
      setStakeholderFormState((prev) => ({ ...prev, code: 0 }));
    }

    // SET SELCETED CATEGORY
    if (stakeholderFormState?.code.toString()) {
      const category = allStakeholder.find(
        (item) =>
          item.value?.toUpperCase() ===
          stakeholderFormState?.code.toString()?.toUpperCase()
      );
      setStakeholderFormState((prev) => ({
        ...prev,
        code_name: category?.label,
      }));
      setSelectedCategory(category);
    }

    // SET SELECTED SUB CATEGORY
    if (stakeholderFormState?.sub_code) {
      const subCategory = subCategories?.find(
        (item) => item.id === stakeholderFormState?.sub_code
      );
      setStakeholderFormState((prev) => ({
        ...prev,
        sub_code_name: subCategory?.name,
      }));
      setSelectedSubCategory(subCategory);
    }
  }, [stakeholderFormState, generalFormState]);

  const [formState, setFormState] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectFormWidth, setSelectFormWidth] = useState(0);
  const [userRoles, setUserRoles] = useState(roles);
  const { data, isLoading, refetch } = useFetch(
    `api/identity-manager/select-roles/`
  );

  React.useEffect(() => {
    if (!isLoading) {
      setUserRoles(data.data);
    }
    if (stakeholderCategories) {
      setAllStakeholder([
        ...stakeholderCategories,
        { label: "Manufacturer", value: "MANUFACTURER" },
        { label: "Airline", value: "AIRLINE" },
      ]);
    }
  }, [data, isLoading, stakeholderCategories]);

  const accessToken = useSelector((state) => state.auth.accessToken);

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (!e.target.files[0]?.type?.includes("image")) {
        warning("Please select an image file");
        return;
      }
      setGeneralFormState((prev) => ({
        ...prev,
        profile_image: e.target.files[0],
      }));
    }
  };
  function removePicture() {
    setGeneralFormState((prev) => ({ ...prev, profile_image: null }));
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loading, setLoading] = React.useState(false);
  const [confirmCreate, setConfirmCreate] = useState(false);
  const [isDone, setIsDone] = useState(false);

  async function handleFormSubmit() {
    if (generalFormState.password !== generalFormState.confirmPassword) {
      warning("Password is not the same as confirm password");
    } else if (generalFormState.phone_number.length !== 11) {
      warning("Phone number should not be greater than or less than 11 digits");
    } else if (
      generalFormState.roles === "STAKEHOLDER" &&
      !stakeholderFormState.code
    ) {
      warning("Please select stakeholder category");
    } else if (
      (stakeholderFormState.code.toString()?.toUpperCase() === "MANUFACTURER" ||
        stakeholderFormState.code.toString()?.toUpperCase() === "AIRLINE") &&
      !stakeholderFormState.sub_code
    ) {
      warning("Please select stakeholder sub category");
    } else {
      setLoading(true);
      try {
        const config = {
          headers: {
            Authorization: `JWT ${accessToken}`,
            "Content-Type": "application/json",
          },
        };
        let data = generalFormState;
        if (generalFormState.roles === "STAKEHOLDER") {
          data = {
            ...generalFormState,
            user_type: "STAKEHOLDER",
            ...stakeholderFormState,
          };
          if (!stakeholderFormState.sub_code) {
            data.sub_code = null;
          }
        }
        await axios.post(
          `${process.env.REACT_APP_BASE_API}api/user/`,
          data,
          config
        );
        setConfirmCreate(false);
        setIsDone(true);
        setLoading(false);
        setGeneralFormState(initialData);
      } catch (err) {
        setLoading(false);
        error(err.response?.data?.message || "Failed: Check your inputs");
        console.log(err);
      }
    }
  }

  const selectFormRef = useRef(null);
  useEffect(() => {
    if (selectFormRef.current) {
      const selectWidth = selectFormRef.current.offsetWidth;
      setSelectFormWidth(selectWidth);
    }
  }, []);
  return (
    <Stack gap={"24px"}>
      <Dialog
        open={confirmCreate}
        onClose={() => setConfirmCreate(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Create"
          title="Create New User?"
          isLoading={loading}
          description="Are you sure you want to create this user? The details will be uploaded to the super user’s profile. "
          handleClose={() => setConfirmCreate(false)}
          handleConfirm={handleFormSubmit}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setIsDone(false)}
      >
        <DoneDialog
          title="User Successfully Created"
          description="You have successfully created this user."
        />
      </Dialog>
      <Stack marginBottom={"16px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack
        sx={{
          backgroundColor: "#FFFFFF",
          paddingX: "24px",
          paddingY: "32px",
          borderRadius: "16px",
        }}
      >
        <div className="text-xl font-semibold pb-3 border-b">
          <h2>Create User</h2>
        </div>
        <Stack
          width={"100%"}
          gap={"60px"}
          direction={"row"}
          flexWrap={"wrap"}
          marginTop={"32px"}
          marginBottom={"16px"}
        >
          <Stack flexGrow={1} gap={"32px"} className=" xl:max-w-[70%]">
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  First Name
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  value={generalFormState.first_name}
                  fullWidth
                  placeholder={"Enter First Name"}
                  onChange={({ target }) => {
                    setGeneralFormState((prev) => ({
                      ...prev,
                      first_name: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Last Name
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  value={generalFormState.last_name}
                  fullWidth
                  placeholder={"Enter Last Name"}
                  onChange={({ target }) => {
                    setGeneralFormState((prev) => ({
                      ...prev,
                      last_name: target.value,
                    }));
                  }}
                />
              </Stack>
            </Stack>

            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Email Address
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  placeholder={"Enter user email"}
                  type="email"
                  value={generalFormState.email}
                  onChange={({ target }) => {
                    setGeneralFormState((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Phone Number
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  type="number"
                  placeholder={"081 0504 044 04"}
                  value={generalFormState.phone_number}
                  onChange={({ target }) => {
                    setGeneralFormState((prev) => ({
                      ...prev,
                      phone_number: target.value,
                    }));
                  }}
                  InputProps={{ inputProps: { min: 0, max: 11 } }}
                  ype="number"
                />
              </Stack>
            </Stack>
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack className="flex-1" gap={"8px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Role Assigned
                </Typography>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={generalFormState.roles}
                    onChange={(e) => {
                      setGeneralFormState((prevState) => ({
                        ...prevState,
                        roles: e.target.value,
                      }));
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="rounded-lg font-semibold"
                  >
                    <MenuItem value="">
                      <Typography color={"inherit"} fontWeight={600}>
                        Select Role
                      </Typography>
                    </MenuItem>
                    {userRoles?.map((eachItem) => (
                      <MenuItem key={eachItem.id} value={eachItem.value}>
                        <Typography color={"inherit"} fontWeight={600}>
                          {eachItem.value}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack className="flex-1" gap={"8px"}>
                {shouldShowCategory && (
                  <>
                    <Typography variant="p" color={"#D8D8D8"}>
                      Stakeholder Category
                    </Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={stakeholderFormState.code}
                        onChange={(e) => {
                          setStakeholderFormState((prevState) => ({
                            ...prevState,
                            code: e.target.value,
                            sub_code: "",
                          }));
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="rounded-lg font-semibold"
                      >
                        <MenuItem value="">
                          <Typography color={"inherit"} fontWeight={600}>
                            Select Category
                          </Typography>
                        </MenuItem>
                        {allStakeholder?.map((eachItem) => (
                          <MenuItem key={eachItem.id} value={eachItem.value}>
                            <Typography color={"inherit"} fontWeight={600}>
                              {eachItem.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Stack>
            </Stack>
            {shouldShowSubCategory && (
              <div className="max-w-[50%] pr-6">
                <Stack className="flex-1" gap={"8px"}>
                  <Typography variant="p" color={"#D8D8D8"}>
                    Sub Category
                  </Typography>
                  <CustomSelect
                    value={stakeholderFormState.sub_code}
                    isLoading={aircraftTypesIsLoading || manufacturerIsLoading}
                    searchValue="value"
                    value_attribute="id"
                    list={subCategories || []}
                    maxHeight="max-h-[20rem]"
                    isTop
                    placeholder="Select Category"
                    onChange={(e) => {
                      setStakeholderFormState((prevState) => ({
                        ...prevState,
                        sub_code: e,
                      }));
                    }}
                  />
                </Stack>
              </div>
            )}
          </Stack>

          <Stack alignSelf={"flex-start"} gap={"20px"}>
            <div className="w-[184px] h-[184px] ">
              {!generalFormState.profile_image ? (
                <div
                  className="w-full h-full bg-gray-200 rounded-full grid place-content-center cursor-pointer"
                  onClick={onButtonClick}
                >
                  <Profile size="130" color="#a2a2a2" variant="Bold" />
                </div>
              ) : (
                <img
                  src={
                    generalFormState.profile_image
                      ? URL.createObjectURL(generalFormState.profile_image)
                      : demoAvatar
                  }
                  style={{
                    borderRadius: "50%",
                  }}
                  alt=""
                  className="w-full h-full object-cover cursor-pointer"
                  onClick={onButtonClick}
                />
              )}
            </div>
            <Stack
              height={"fit-content"}
              gap={"6px"}
              visibility={formState ? "hidden" : "none"}
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                className="hidden h-full"
                onChange={handleChange}
              />
              <Typography
                fontSize={14}
                color={"#161616"}
                fontWeight={700}
                className="text-center font-semibold text-[#D8D8D8]"
              >
                Upload profile photo
              </Typography>
              {generalFormState.profile_image && (
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    "&.MuiButton-contained": {
                      background: "#003163",
                      marginX: "auto",
                      height: "fit-content",
                      borderRadius: 0,
                      textTransform: "none",
                      paddingX: "14px",
                      paddingY: "5px",
                      marginTop: "8px",
                    },
                  }}
                  onClick={removePicture}
                >
                  Remove
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack marginTop={"38px"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={generalFormState.require_change_password}
                onChange={() =>
                  setGeneralFormState((prev) => ({
                    ...prev,
                    require_change_password: !prev.require_change_password,
                  }))
                }
              />
            }
            label="Require this user to change their password when they first sign in"
          />
        </Stack>

        <Stack marginTop={"32px"}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => setConfirmCreate(true)}
            sx={{
              height: "45px",
              textTransform: "none",
              borderRadius: 0,
              "&.MuiButton-contained": {
                background: "#003163",
              },
            }}
            className="disabled:text-gray-400 bg-primary rounded-xl px-16 w-fit font-bold"
            disabled={
              generalFormState.first_name === "" ||
              generalFormState.last_name === "" ||
              generalFormState.email === "" ||
              generalFormState.phone_number === "" ||
              generalFormState.roles === ""
            }
          >
            Create User{" "}
            {loading && (
              <CircularProgress className="w-2 h-2 ml-2 text-white" />
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
