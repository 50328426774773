import React, { useEffect } from "react";
import { MoreVertRounded } from "@mui/icons-material";
import {
  Stack,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableBody,
  Button,
  Pagination,
  Box,
  Container,
} from "@mui/material";
import { OccurenceIndexData } from "../../helpers/OccurenceIndexData";
import { Link } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import OccurenceHome from "./Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppBar, Drawer } from "../../layout/occurrenceIndex/Drawer";
import DrawerList from "../../layout/occurrenceIndex/DrawerList";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import logo from "../../assets/general/nsib_logo.png";
import argue from "../../assets/general/argue.png";
import report from "../../assets/general/report.png";
import { useParams, Outlet, useLocation } from "react-router-dom";
import Loading from "../../common/Loading";

const defaultTheme = createTheme();

function OccurenceIndex() {
  const [open, setOpen] = React.useState(true);

  const location = useLocation();
  const [reportIsActive, setReportIsActive] = React.useState(false);
  const [activeData, setActiveData] = React.useState([]);

  const { data: reportData, isLoading: reportIsLoading } = useFetch(
    "api/occurrence-report/"
  );

  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/list_index/"
  );

  const [filteredData, setFilteredData] = React.useState([]);
  const [filterForm, setFilterForm] = React.useState({
    occurrence_number: "",
    location: "",
    state: "",
    status: "",
    injury_level: "",
    type: "",
    category: "",
  });

  const [filterTitle, setFilterTitle] = React.useState({
    title: "",
  });

  useEffect(() => {
    if (reportIsActive) {
      if (!reportIsLoading) {
        setActiveData(reportData?.data);
        setFilteredData(reportData?.data);
      }
    } else {
      if (!isLoading) {
        setActiveData(data?.data);
        setFilteredData(data?.data);
      }
    }
  }, [reportIsActive, reportIsLoading, isLoading, data, reportData]);
  // React.useEffect(() => {
  //   if (!isLoading) {
  //     setFilteredData(data?.data);
  //     console.log(data?.data);
  //   }
  // }, [isLoading, data]);

  const submitFilterTitleSearch = () => {
    // const filteredData = data?.data?.filter((item) => {
    const filteredData = activeData?.filter((item) => {
      return item?.title
        ?.toLowerCase()
        .includes(filterTitle?.title?.toLowerCase());
    });

    setFilteredData(filteredData);
    // reset filterForm
    setFilterForm({
      occurrence_number: "",
      location: "",
      state: "",
      status: "",
      injury_level: "",
      type: "",
      category: "",
    });
  };

  const submitFilterSearch = () => {
    // const filteredData = data?.data?.filter((item) => {
    const filteredData = activeData?.filter((item) => {
      if (reportIsActive) {
        return (
          item?.occurrence_number
            ?.toLowerCase()
            .includes(filterForm?.occurrence_number?.toLowerCase()) &&
          (item?.location
            ?.toLowerCase()
            .includes(filterForm?.location?.toLowerCase()) ||
            item?.location == null) &&
          (item?.state
            ?.toLowerCase()
            .includes(filterForm?.state?.toLowerCase()) ||
            item?.state == null) &&
          item?.occurrence_report_status
            ?.toLowerCase()
            .includes(filterForm?.status?.toLowerCase()) &&
          item?.occurrence_type
            ?.toLowerCase()
            .includes(filterForm?.type?.toLowerCase()) &&
          item?.occurrence_category
            ?.toLowerCase()
            .includes(filterForm?.category?.toLowerCase())
        );
      }
      return (
        item?.occurrence_number
          ?.toLowerCase()
          .includes(filterForm?.occurrence_number?.toLowerCase()) &&
        (item?.occurrence_details?.location
          ?.toLowerCase()
          .includes(filterForm?.location?.toLowerCase()) ||
          item?.occurrence_details?.location == null) &&
        (item?.occurrence_details?.state
          ?.toLowerCase()
          .includes(filterForm?.state?.toLowerCase()) ||
          item?.occurrence_details?.location == null) &&
        item?.occurrence_report_status
          ?.toLowerCase()
          .includes(filterForm?.status?.toLowerCase()) &&
        (item?.occurrence_details?.high_injury_level
          ?.toLowerCase()
          .includes(filterForm?.injury_level?.toLowerCase()) ||
          item?.occurrence_details?.location == null) &&
        item?.occurrence_type
          ?.toLowerCase()
          .includes(filterForm?.type?.toLowerCase()) &&
        item?.occurrence_category
          ?.toLowerCase()
          .includes(filterForm?.category?.toLowerCase())
      );
    });
    console.log(filteredData);

    // Update the filtered data state
    setFilteredData(filteredData);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          className="bg-white shadow-none z-[9999] border-b"
        >
          <Toolbar>
            <div className="flex justify-between items-center w-full px-16">
              <div className="flex gap-1">
                <img
                  src={argue}
                  alt=""
                  className="w-[96px] h-[24px] transition-all "
                />
                {open && (
                  <img
                    src={report}
                    alt=""
                    className="h-[24px] transition-all"
                  />
                )}
              </div>

              <div className="flex gap-2 items-center">
                <div className="flex gap-4 items-center">
                  <img src={logo} alt="" className="h-[120px]" />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <List
            component="nav"
            className={`transition-all flex flex-col justify-between h-[90vh]`}
          >
            <DrawerList
              filterForm={filterForm}
              setFilterForm={setFilterForm}
              submitFilterSearch={submitFilterSearch}
              filterTitle={filterTitle}
              setFilterTitle={setFilterTitle}
              submitFilterTitleSearch={submitFilterTitleSearch}
              data={activeData}
              reportIsActive={reportIsActive}
              filterData={filteredData}
            />
            {/* data={data?.data} */}
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <div className="flex justify-end mb-4">
              <Button
                variant="contained"
                className={` ${
                  reportIsActive ? "bg-[#c9ba73]" : "bg-primary"
                } py-2 shadow-none text-white font-bold rounded-xl px-12 capitalize`}
                onClick={() => setReportIsActive(!reportIsActive)}
              >
                View {reportIsActive ? "Current Report" : "Legacy Report"}
              </Button>
            </div>
            <OccurenceHome
              data={filteredData}
              reportIsActive={reportIsActive}
              isLoading={isLoading || reportIsLoading}
            />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default OccurenceIndex;
