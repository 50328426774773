import React from "react";

import { Verify } from "iconsax-react";
const DoneDialog = ({ title, description }) => {
  return (
    <div className="bg-green-50">
      <div className="w-[80%] max-w-[45rem] py-10 mx-auto text-center">
        <div>
          <Verify size="45" className="text-green-500 mx-auto" variant="Bold" />
        </div>
        <div className="mt-6">
          <h2 className="font-semibold text-xl">{title}</h2>
          <p className="pt-2 text-gray-400 font-medium text-sm">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DoneDialog;
