import React from "react";
import UserManagement from "./user-managament";

// UTILS
import backgroundBlur from "../../assets/backgrounds/user-managament-count.svg";
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import { Folder } from "iconsax-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";

const SuperUserHome = ({
  userData,
  counts,
  userDataIsLoading,
  roleData,
  roleDataIsLoading,
}) => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/"
  );

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-8">
        <div className="relative overflow-hidden bg-primary flex items-center rounded-2xl px-8 py-6 min-h-[15rem] text-white">
          <div className="relative z-10">
            <h2 className="text-2xl font-semibold pb-1">
              Create New Occurrence
            </h2>
            <p>Add new occurence to your system</p>
            <Link to="/occurrence/create-occurrence">
              <Button
                variant="contained"
                className="font-bold text-[15px] bg-white text-primary-700 px-10 rounded-xl capitalize mt-6 shadow-none"
              >
                Create Occurrence
              </Button>
            </Link>
          </div>
          <img
            src={backgroundBlur}
            className="absolute left-0 -top-10 h-[24rem] opacity-40"
            alt=""
          />
          <div className="w-32 h-32 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full blur-2xl opacity-25"></div>
          <Folder
            size="280"
            className="text-primary-700 absolute -bottom-20 right-2 opacity-50"
            variant="Bold"
          />
        </div>
        <div className="rounded-2xl bg-white px-4 pt-8 pb-6 mt-8">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold">Occurrence</h2>
            <Link to="/occurrence" className="text-sm font-medium text-primary">
              See all
            </Link>
          </div>
          <TableContainer className="mt-6">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-[#AEAEAE] min-w-[180px]">
                    Occurrence Number
                  </TableCell>
                  <TableCell className="text-[#AEAEAE] min-w-[200px]">
                    Title
                  </TableCell>
                  <TableCell className="text-[#AEAEAE] min-w-[200px]">
                    Occurrence Manager/IIC
                  </TableCell>
                  <TableCell className="text-[#AEAEAE]">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell colSpan={4} align="center">
                      <div className=" min-h-[20rem] flex items-center justify-center ">
                        <div>
                          <CircularProgress />
                          <p className="text-center animate-pulse mt-2">
                            Loading...
                          </p>
                        </div>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  data?.data?.slice(0, 6)?.map((row) => (
                    <StyledTableRow
                      hover
                      key={row?.id}
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/occurrence/manage-occurrence/${row?.id}`)
                      }
                    >
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_number}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.title}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_owner?.first_name}{" "}
                        {row?.occurrence_owner?.last_name}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        <span
                          className={`capitalize px-3 py-2 rounded-full ${
                            row?.occurrence_status?.toLowerCase() === ""
                              ? "text-orange-700 bg-orange-50"
                              : row?.occurrence_status?.toLowerCase() ===
                                "published"
                              ? "text-red-700 bg-red-50"
                              : "text-orange-700 bg-orange-50"
                          }`}
                        >
                          {row?.occurrence_status?.toLowerCase() === ""
                            ? "Processing"
                            : row?.occurrence_status?.toLowerCase()}
                        </span>
                      </TableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="col-span-4">
        <div className="rounded-2xl bg-white px-4 pt-8 pb-6">
          <UserManagement
            title="User Data"
            isSuperUser={true}
            noMainCounts={true}
            counts={counts}
            loading={userDataIsLoading}
            userList={userData}
            roleList={roleData}
            userRoleIsLoading={roleDataIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperUserHome;
