import React from "react";
import InformationSection from "../InformationSection";

import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import useFetch from "../../../helpers/useFetch";
import { Input } from "../../../pages/user-management/ManageProfile";
import { InputField } from "../../../components";
import { useSelector } from "react-redux";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import CustomSelect from "../../common/CustomSelect";

const OccurrenceDetails = ({
  information,
  goToNext,
  occurrenceId,
  OccurrenceDetailsId,
  refresh,
}) => {
  const { data: airlineData, isLoading: airlineIsLoading } = useFetch(
    `api/settings/airline/`
  );
  const { data: aircraftManufacturerData, isLoading: manufacturerIsLoading } =
    useFetch(`api/settings/manufacturer/`);
  const { data: aircraftTypesData } = useFetch(`api/settings/aircraft-type/`);
  const { data: statesData } = useFetch(`api/settings/location/state/`);
  const { data: countryData } = useFetch(`api/settings/location/country/`);
  const { data: flightPhaseData } = useFetch(`api/settings/flight_phase/`);
  const { data: typeOfOperationData } = useFetch(
    `api/settings/type-of-operation/`
  );
  const { data: damageAircraftData } = useFetch(
    `api/settings/damages_to_aircraft/`
  );
  const { data: highestInjuryData } = useFetch(
    `api/settings/occurrence/injury/`
  );

  const user = useSelector((state) => state.users.user);
  const [userIsOccurenceManager, setUserIsOccurenceManager] =
    React.useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);

  React.useEffect(() => {
    if (user) {
      if (user?.roles?.role?.toLowerCase()?.includes("occurence-manager/iic")) {
        setUserIsOccurenceManager(true);
      }
    }
    setFormData((prev) => ({
      ...prev,
      occurrence: occurrenceId,
    }));
  }, [user]);

  const [formData, setFormData] = React.useState({
    registered_owner: "",
    airline: "",
    aircraft_manufacturer: "",
    aircraft_type: "",
    aircraft_registration_number: "",
    certificate_of_airworthiness: "",
    certificate_of_registration: "",
    aircraft_serial_number: "",
    location: "",
    type_of_operation: "",
    damage_to_aircraft: "",
    flight_destination: "",
    flight_departure: "",
    total_airframe_time: "",
    state: "",
    country: "",
    flight_phase: "",
    high_injury_level: "",
  });

  React.useEffect(() => {
    if (information) {
      setFormData(information.occurrence_details);
    }
  }, [information]);

  const [sortedAircraftTypes, setSortedAircraftTypes] = React.useState();
  React.useEffect(() => {
    setSortedAircraftTypes(aircraftTypesData?.data);
    if (
      formData.aircraft_manufacturer &&
      aircraftManufacturerData?.data &&
      aircraftManufacturerData?.data?.length &&
      aircraftTypesData?.data &&
      aircraftTypesData?.data?.length
    ) {
      const selectedManufacturer = aircraftManufacturerData?.data?.find(
        (manufacturer) => manufacturer?.value == formData.aircraft_manufacturer
      );
      const selectedType = aircraftTypesData?.data?.find(
        (type) => type?.value == formData.aircraft_type
      );
      if (selectedManufacturer) {
        if (selectedType) {
          if (selectedType?.iata_code != selectedManufacturer?.id) {
            setFormData((prev) => ({
              ...prev,
              aircraft_type: "",
            }));
          }
        }
        setSortedAircraftTypes(
          aircraftTypesData?.data?.filter(
            (type) => type?.iata_code == selectedManufacturer?.id
          )
        );
      }
    }
  }, [formData, aircraftManufacturerData, aircraftTypesData]);

  const [formIsValid, setFormIsValid] = React.useState(false);
  React.useEffect(() => {
    if (
      formData.registered_owner &&
      formData.airline &&
      formData.aircraft_manufacturer &&
      formData.aircraft_type &&
      formData.aircraft_registration_number &&
      formData.certificate_of_airworthiness &&
      formData.certificate_of_registration &&
      formData.aircraft_serial_number &&
      formData.location &&
      formData.type_of_operation &&
      formData.damage_to_aircraft &&
      formData.flight_destination &&
      formData.flight_departure &&
      formData.total_airframe_time &&
      formData.state &&
      formData.country &&
      formData.flight_phase &&
      formData.high_injury_level
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [formData]);

  const [loading, setLoading] = React.useState(false);

  async function handleSave() {
    if (!formIsValid) {
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/detail/${OccurrenceDetailsId}/`,
        formData,
        config
      );

      success("Occurrence Details updated successfully");
      goToNext();
      refresh();
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <Stack gap={"32px"}>
      <InformationSection information={information} />

      <Stack
        bgcolor={"#fff"}
        px={"32px"}
        pt={"14px"}
        pb={"32px"}
        className="rounded-2xl"
      >
        <h2 className="border-b pb-2 font-semibold px-3 text-xl">
          Occurrence Details
        </h2>

        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Registered Owner
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.registered_owner}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  registered_owner: target.value,
                }));
              }}
              placeholder="Registered owner"
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Airline
            </Typography>
            <CustomSelect
              value={formData?.airline}
              list={airlineData?.data || []}
              isLoading={airlineIsLoading}
              placeholder="Select airline"
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  airline: e,
                }));
              }}
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Aircraft Manufacturer
            </Typography>
            <CustomSelect
              value={formData?.aircraft_manufacturer}
              list={aircraftManufacturerData?.data || []}
              isLoading={manufacturerIsLoading}
              placeholder="Select aircraft manufacturer"
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  aircraft_manufacturer: e,
                }));
              }}
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Aircraft Type / Model
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.aircraft_type}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    aircraft_type: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select aircraft type"}</p>
                </MenuItem>
                {sortedAircraftTypes?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Flight Phase
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.flight_phase}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    flight_phase: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Flight phase"}</p>
                </MenuItem>
                {flightPhaseData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Aircraft Registration Number
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.aircraft_registration_number}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  aircraft_registration_number: target.value,
                }));
              }}
              placeholder="Aircraft registration number"
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Certificate of Registration Date
            </Typography>
            <InputField
              fieldType={"date"}
              placeholder={"Select Date"}
              border={true}
              value={formData?.certificate_of_registration}
              onChange={(e) => {
                const selectedDate = new Date(e);
                const year = selectedDate.getFullYear();
                const month = (selectedDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const day = selectedDate.getDate().toString().padStart(2, "0");
                const formattedDate = `${year}-${month}-${day}`;
                setFormData((prevState) => ({
                  ...prevState,
                  certificate_of_registration: formattedDate,
                }));
              }}
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Certificate of Airworthiness Valid till
            </Typography>
            <InputField
              fieldType={"date"}
              placeholder={"Select Date"}
              border={true}
              value={formData?.certificate_of_airworthiness}
              onChange={(e) => {
                const selectedDate = new Date(e);
                const year = selectedDate.getFullYear();
                const month = (selectedDate.getMonth() + 1)
                  .toString()
                  .padStart(2, "0");
                const day = selectedDate.getDate().toString().padStart(2, "0");
                const formattedDate = `${year}-${month}-${day}`;
                setFormData((prevState) => ({
                  ...prevState,
                  certificate_of_airworthiness: formattedDate,
                }));
              }}
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Aircraft Serial Number
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.aircraft_serial_number}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  aircraft_serial_number: target.value,
                }));
              }}
              placeholder="Aircraft serial number"
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Incident Location
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.location}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  location: target.value,
                }));
              }}
              placeholder="Location"
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Intended Destination
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.flight_destination}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  flight_destination: target.value,
                }));
              }}
              placeholder="Intended destination"
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Flight Origination
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.flight_departure}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  flight_departure: target.value,
                }));
              }}
              placeholder="Flight Origination"
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Country
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.country}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    country: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Country"}</p>
                </MenuItem>
                {countryData?.data
                  ?.filter((item) =>
                    item.name?.toLowerCase()?.includes("nigeria")
                  )
                  ?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}
                      className="text-secondary"
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              State
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.state}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    state: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select State"}</p>
                </MenuItem>
                {statesData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Highest Injury Level
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.high_injury_level}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    high_injury_level: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">
                    {"Select Highest Injury Level"}
                  </p>
                </MenuItem>
                {highestInjuryData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Total Airframe Time
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.total_airframe_time}
              onChange={({ target }) => {
                target.value = target.value.replace(/[^0-9:.]/g, "");
                setFormData((prev) => ({
                  ...prev,
                  total_airframe_time: target.value,
                }));
              }}
              placeholder="Total airframe time"
            />
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Damage To Aircraft
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.damage_to_aircraft}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    damage_to_aircraft: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">
                    {"Select Damage to Aircraft"}
                  </p>
                </MenuItem>
                {damageAircraftData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Type of Operation
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="standard-label"
                id="demo-standard"
                value={formData?.type_of_operation}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    type_of_operation: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">
                    {"Select Type of Operation"}
                  </p>
                </MenuItem>
                {typeOfOperationData?.data?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
      </Stack>
      {userIsOccurenceManager && (
        <div className="flex justify-end">
          <Button
            variant="contained"
            disabled={loading}
            className="bg-primary px-12 rounded-xl font-bold text-sm py-2.5 capitalize shadow-none"
            onClick={handleSave}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Save and Continue"
            )}
          </Button>
        </div>
      )}
    </Stack>
  );
};

export default OccurrenceDetails;
