import React from "react";

// UTILS
import useFetch from "../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { error } from "../../common/Notifications";
import axios from "axios";
import { Input } from "../user-management/ManageProfile";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import {
  Stack,
  Typography,
  Button,
  CircularProgress,
  Dialog,
} from "@mui/material";

function RequestDetail() {
  const { id } = useParams();
  const [confirmApproval, setConfirmApproval] = React.useState(false);
  const [confirmRejection, setConfirmRejection] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [doneDetails, setDoneDetails] = React.useState({
    title: "Request Successful",
    message: "Your request has been set",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const { data: request, isLoading: requestIsLoading } = useFetch(
    `api/user/all_request_form/${id}`
  );
  const navigate = useNavigate();

  async function handleAction(type) {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/approve_password_reset/`,
        {
          id: id,
          action: type || "approve",
        },
        config
      );

      if (type === "approve") {
        setConfirmApproval(false);
        setDoneDetails({
          title: "Request Approved",
          message: "A confirmation mail will be sent to the user",
        });
      } else {
        setConfirmRejection(false);
        setDoneDetails({
          title: "Request Declined",
          message: "A confirmation mail will be sent to the user",
        });
      }
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.error || "Something went wrong");
    }
    setIsLoading(false);
  }

  return (
    <div>
      <Dialog
        open={confirmApproval}
        onClose={() => setConfirmApproval(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Approve"
          title="Approve Request"
          isLoading={isLoading}
          description="Are you sure you want to approve request? The user will be able to log into his/her account."
          handleClose={() => setConfirmApproval(false)}
          handleConfirm={() => handleAction("approve")}
        />
      </Dialog>
      <Dialog
        open={confirmRejection}
        onClose={() => setConfirmRejection(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={true}
          confirmText="Decline"
          title="Decline Request"
          isLoading={isLoading}
          description="Are you sure you want to decline request? The user won't be able to log into account."
          handleClose={() => setConfirmRejection(false)}
          handleConfirm={() => handleAction("reject")}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setIsDone(false), navigate(-1);
        }}
      >
        <DoneDialog
          title={doneDetails.title}
          description={doneDetails.message}
        />
      </Dialog>
      <div>
        <h2 className="text-gray-500 pl-4">
          <span
            className="cursor-pointer"
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </span>
          /{" "}
          <span
            className="cursor-pointer"
            onClick={() => navigate("/reset-requests")}
          >
            Password Reset Requests
          </span>
        </h2>
      </div>

      <Stack
        sx={{
          mt: "30px",
          px: "32px",
          py: "40px",
          backgroundColor: "#fff",
          borderRadius: "16px",
        }}
      >
        <Stack>
          <div className="pb-3 border-b text-xl font-semibold">
            <h2>Password Reset Details</h2>
          </div>
        </Stack>

        {requestIsLoading ? (
          <Stack className="flex items-center justify-center py-20 text-center">
            <div>
              <CircularProgress />
              <p>Loading ...</p>
            </div>
          </Stack>
        ) : (
          <Stack
            marginTop={"32px"}
            gap={"24px"}
            className="lg:w-[90%] xl:w-[80%]"
          >
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Full Name
                </Typography>
                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  disabled={true}
                  placeholder={"Full Name"}
                  value={request?.data?.full_name}
                />
              </Stack>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Email
                </Typography>
                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  disabled={true}
                  placeholder={"Email"}
                  value={request?.data?.email}
                />
              </Stack>
            </Stack>
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Date Requested
                </Typography>
                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  disabled={true}
                  placeholder={"Date"}
                  value={new Date(
                    request?.data?.created_at
                  )?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                />
              </Stack>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Request Status
                </Typography>
                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  fullWidth
                  disabled={true}
                  placeholder={"Status"}
                  value={
                    request?.data?.is_done === false ? "Pending" : "Approved"
                  }
                />
              </Stack>
            </Stack>
            <Stack width={"100%"} gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Message
              </Typography>
              <textarea
                className="w-full border border-[#D9D9D9] rounded-md p-2 resize-none"
                disabled
                value={request?.data?.message}
                rows={5}
              />
            </Stack>

            <Stack direction={"row"} gap={"24px"} marginTop={"24px"}>
              <Button
                className="bg-red-500 text-white px-10 py-2.5 font-bold rounded-xl capitalize"
                onClick={() => setConfirmRejection(true)}
              >
                Decline Request
              </Button>
              <Button
                className="bg-primary text-white px-10 py-2.5 font-bold rounded-xl capitalize"
                onClick={() => setConfirmApproval(true)}
              >
                Approve Request
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
}

export default RequestDetail;
