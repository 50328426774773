import React from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

function CustomTextField({
  label,
  name,
  value,
  onChange,
  placeholder,
  multiline,
  error,
  helperText,
}) {
  return (
    <FormControl error={error} className="flex-1">
      <p className=" text-xs text-secondary mb-2">{label}</p>
      <FilledInput
        id={label}
        placeholder={placeholder}
        variant="filled"
        value={value}
        fullWidth
        name={name}
        onChange={onChange}
        className={`text-xs placeholder:!text-xs  focus-within:border-2 focus-within:rounded-none focus-within:border-primary after:focus-within:border-b-none after:focus-within:border-b-transparent after:focus-within:border-b-0 before:focus-within:border-b-none before:focus-within:border-b-transparent before:focus-within:border-b-0 ${
          error &&
          "border-2 border-red-600 rounded-none focus-within:border-red-600 after:border-b-none after:border-b-transparent after:border-b-0 before:border-b-none before:border-b-transparent before:border-b-0"
        } ${multiline && "h-20"}`}
        hiddenLabel
        multiline={multiline}
        rows={3}
        error={error}
        helperText={helperText}
        endAdornment={
          <InputAdornment position="end">
            {error && (
              <FontAwesomeIcon
                fontSize={16}
                icon={faCircleExclamation}
                className="text-red-600"
              />
            )}
          </InputAdornment>
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
      {/* {error && <p className="text-red-700 text-sm">{helperText}</p>} */}
    </FormControl>
  );
}

function CustomPasswordField({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  helperText,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl error={error} className="flex-1">
      <p className=" text-xs text-secondary mb-2">{label}</p>
      <FilledInput
        id="filled-adornment-password"
        className={`focus-within:border-2 focus-within:rounded-none focus-within:border-primary after:focus-within:border-b-none after:focus-within:border-b-transparent after:focus-within:border-b-0 before:focus-within:border-b-none before:focus-within:border-b-transparent before:focus-within:border-b-0 ${
          error &&
          "border-2 border-red-600 rounded-none focus-within:border-red-600 after:border-b-none after:border-b-transparent after:border-b-0 before:border-b-none before:border-b-transparent before:border-b-0"
        }
        `}
        type={showPassword ? "text" : "password"}
        name={name}
        endAdornment={
          <InputAdornment position="end">
            <>
              {error && (
                <FontAwesomeIcon
                  fontSize={16}
                  icon={faCircleExclamation}
                  className="text-red-600"
                />
              )}
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <FontAwesomeIcon
                  fontSize={16}
                  icon={showPassword ? faEyeSlash : faEye}
                />
              </IconButton>
            </>
          </InputAdornment>
        }
        fullWidth
        hiddenLabel
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        // error={error}
        // helperText={helperText}
      />
      <FormHelperText>{helperText}</FormHelperText>
      {/* {error && <p className="text-red-700 text-xs">{helperText}</p>} */}
    </FormControl>
  );
}

export default CustomTextField;
export { CustomPasswordField };
