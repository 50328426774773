import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useFetch from "../../../helpers/useFetch";
import { useSelector } from "react-redux";
import { ArrowLeft } from "iconsax-react";
import Loading from "../../../common/Loading";
import SectionForm from "../../../components/occurrence/section-owner/SectionForm";

const ManageSection = () => {
  const { occurrenceId, sectionId } = useParams();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = React.useState(null);
  const userId = useSelector((state) => state.users?.user?.id);
  const accessToken = useSelector((state) => state.auth.accessToken);
  // FETCHES
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/retrieve_occurrence_information_section_for_owner/`
  );
  const { data: sectionMembersData } = useFetch(
    `api/occurrence-manager/sections/members_list`
  );

  React.useEffect(() => {
    if (data?.data) {
      setActiveSection(
        data?.data?.occurrence_section?.find(
          (section) => section?.id === sectionId
        )
      );
    }
  }, [data]);

  const [isRefreshing, setIsRefreshing] = React.useState(false);

  if (isLoading && !isRefreshing) {
    return <Loading />;
  }
  return (
    <Stack gap={"40px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack>
        <Stack
          direction={"row"}
          className="bg-primary py-4 justify-between rounded-t-2xl"
        >
          <Typography className="text-white font-semibold text-lg px-8">
            {activeSection?.section_name}
          </Typography>
          <Typography className="text-white font-semibold text-lg px-8">
            Submission Date:{" "}
            {new Date(activeSection?.submission_date)?.toLocaleDateString()}
          </Typography>
        </Stack>
        <Stack className="bg-white rounded-b-2xl py-5 px-8">
          <SectionForm
            sectionMembersData={sectionMembersData?.data}
            data={activeSection}
            occurrenceId={occurrenceId}
            accessToken={accessToken}
            userId={userId}
            refetch={() => {
              refetch();
              setIsRefreshing(true);
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ManageSection;
