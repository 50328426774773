import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { mediaIcon, dashboardIcon } from "../../assets";
import {
  Stack,
  Button,
  Grid,
  IconButton,
  Typography,
  TablePagination,
  TextField,
  InputAdornment,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import PageIndicator from "../../components/common/PageIndicator";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { MediaGrid, MediaList, CustomTabPanel } from "../../components/media";
import { MoreVertRounded } from "@mui/icons-material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GridOnIcon from "@mui/icons-material/GridOn";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { images } from "../../helpers/Images";
import CustomModal from "../../components/media/Modal";
import AddFileModal from "./modals/AddFileModal";
import Alert from "../../components/common/Alert";
import { DialogComponent } from "../../components";
import useFetch from "../../helpers/useFetch";
import { useSelector } from "react-redux";
import Loading from "../../common/Loading";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Edit2, Grid1 } from "iconsax-react";

function MediaHome() {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState("grid");
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const [date, setDate] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openDownloadModal, setOpenDownloadModal] = React.useState(false);
  const [openDeleteModal, setOpenDelectModal] = React.useState(false);
  const {
    data: dataAll,
    isLoading: isLoadingAll,
    refetch: refetchAll,
  } = useFetch("api/media-library/");
  const {
    data: dataPersonal,
    isLoading: isLoadingPersonal,
    refetch: refetchPersonal,
  } = useFetch("api/media-library/my-gallery/");

  const userRoles = useSelector((state) => state.users?.user?.roles);

  const [allGallery, setAllGallery] = React.useState([]);
  const [myUploads, setMyUploads] = React.useState([]);

  const [searchImageTitle, setSearchImageTitle] = React.useState({
    title: "",
  });

  function SearchImageTitleChange(e) {
    setSearchImageTitle({ ...searchImageTitle, title: e });

    if (tabValue === 0) {
      const filteredData = dataAll?.data?.filter((item) => {
        return item.title.toLowerCase().includes(e.toLowerCase());
      });
      setAllGallery(filteredData);
    } else {
      const filteredData = dataPersonal?.data?.filter((item) => {
        return item.title.toLowerCase().includes(e.toLowerCase());
      });
      setMyUploads(filteredData);
    }
  }

  React.useEffect(() => {
    if (!isLoadingAll) {
      setAllGallery(dataAll.data);
    }

    if (!isLoadingPersonal) {
      setMyUploads(dataPersonal.data);
    }
  }, [isLoadingAll, dataAll, dataPersonal, isLoadingPersonal]);

  const handleDeleteModalOpen = () => setOpenDelectModal(true);
  const handleDeleteModalClose = () => setOpenDelectModal(false);

  const handleDownloadModalOpen = () => setOpenDownloadModal(true);
  const handleDownloadModalClose = () => setOpenDownloadModal(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const [gridRowPerPage, setGridRowPerPage] = React.useState(35);
  const [listRowPerPage, setListRowPerPage] = React.useState(10);

  const itemsPerPage = viewType === "grid" ? gridRowPerPage : listRowPerPage;
  const totalPages = Math.ceil(
    (viewType === "grid" ? allGallery?.length : myUploads?.length) /
      itemsPerPage
  );

  // useEffect(() => {
  //     if (currentPage > totalPages) {
  //         setCurrentPage(totalPages);
  //     }
  // }, [currentPage, totalPages]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    if (viewType === "grid") {
      setGridRowPerPage(+event.target.value);
    } else {
      setListRowPerPage(+event.target.value);
    }
    setCurrentPage(0);
  };

  const toggleGridView = () => {
    setViewType("grid");
  };

  const toggleListView = () => {
    setViewType("list");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImageSelect = (imageId) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imageId)
        ? prevSelected.filter((id) => id !== imageId)
        : [...prevSelected, imageId]
    );
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderMediaItems = (imagesData) => {
    const startIndex = currentPage * itemsPerPage;
    let visibleImages = [];
    if (imagesData) {
      visibleImages = imagesData.slice(startIndex, startIndex + itemsPerPage);
    }

    if (viewType === "grid") {
      return (
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-6">
          {visibleImages?.map((item, index) => (
            <div key={index} className="bg-white py-4 px-4 rounded-2xl">
              <img
                src={item?.url}
                alt={item?.alt_text}
                className="w-full h-[12rem] object-cover rounded-lg cursor-pointer"
                onClick={() => navigate(`/media/${item?.id}`)}
              />
              <div className="mt-2 flex justify-between items-center gap-2">
                <div className="min-w-0">
                  <h2 className="font-semibold truncate">{item?.title}</h2>
                  <p className="text-gray-600 truncate">{item?.slug}</p>
                </div>
                <Edit2
                  className="cursor-pointer"
                  size="24"
                  variant="Bold"
                  onClick={() => navigate(`/media/${item?.id}`)}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
    return (
      <MediaList
        images={visibleImages}
        selectedImages={selectedImages}
        onImageSelect={handleImageSelect}
        download={handleDownloadModalOpen}
        deleteImage={handleDeleteModalOpen}
      />
    );
  };

  // if (isLoadingAll || isLoadingPersonal) {
  //     return <Loading />
  // }

  return (
    <div>
      <Alert
        display={false}
        iconClass={"bi bi-check-circle"}
        color={"#42BE65"}
        title={"Alert"}
        message={"Image upload was successful!"}
      />
      <Stack direction={"row"} gap={"16px"} className="justify-between">
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
        <Button
          variant="contained"
          className="px-10 rounded-2xl bg-primary capitalize text-sm font-bold py-2.5 shadow-none"
          onClick={() => navigate("/media/add")}
        >
          Add Media
        </Button>
      </Stack>
      {/* <Stack gap={"32px"} width={"100%"}>
        <Breadcrumb
          rootLink={"Dashboard"}
          rootLinkPath={"/dashboard"}
          rootLinkIcon={dashboardIcon}
          firstLink={"Media Library"}
        />

        <div className="flex w-full justify-between">
          <PageIndicator pageName={"Media Library"} indicatorIcon={mediaIcon} />
          {userRoles?.media_library?.create && (
            <Button
              onClick={handleOpen}
              variant="contained"
              className={`bg-white border border-primary capitalize text-primary shadow-none py-4 rounded-none border-solid px-4 flex gap-6`}
            >
              <p>Add New Media</p>
              <FileUploadOutlinedIcon />
            </Button>
          )}
        </div>
      </Stack> */}

      <div className="bg-white w-full mt-10 p-8 flex items-center justify-between">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="All Gallery"
            {...a11yProps(0)}
            className="capitalize text-primary"
          />
          <Tab label="My Uploads" {...a11yProps(1)} className="capitalize" />
        </Tabs>

        <div className="flex mt-5 justify-between">
          <div className="flex items-center gap-8">
            <div className="flex gap-5">
              <button onClick={toggleGridView}>
                <Grid1 size="24" variant="Bold" />
              </button>

              <button onClick={toggleListView}>
                <FormatListBulletedIcon
                  className={`${
                    viewType !== "grid" ? "text-primary" : "text-secondary"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`${viewType == "grid" ? "" : "bg-white"} p-8 mt-8`}>
        <div className="min-h-[calc(100vh - 300px)]">
          <CustomTabPanel value={tabValue} index={0}>
            {isLoadingAll ? <CircularProgress /> : renderMediaItems(allGallery)}
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            {isLoadingPersonal ? (
              <CircularProgress />
            ) : (
              renderMediaItems(myUploads)
            )}
          </CustomTabPanel>
        </div>

        <div className="flex justify-center mt-8">
          <Pagination
            count={totalPages}
            page={currentPage + 1}
            onChange={handlePageChange}
          />
        </div>
      </div>

      {/* Add New Media Modal */}
      {/* <AddFileModal open={open} handleClose={handleClose} openAlert={handleOpenAlert} refetch={refetchAll} refetchPersonal={refetchPersonal} /> */}

      {/* Download Dialog Modal */}
      <DialogComponent
        title={"Download Image"}
        primaryActionBtnColor={"#003163"}
        primaryActionBtnText={"Yes"}
        secondaryActionBtnColor={"#003163"}
        secondaryActionBtnText={"No"}
        content={"Are you sure you want to download this image?"}
        dialogState={openDownloadModal}
        secondaryBtnAction={handleDownloadModalClose}
        primaryBtnAction={handleDownloadModalClose}
        handleClose={handleDownloadModalClose}
      />

      {/* Delete Dialog Modal */}
      {/* <DialogComponent
                title={'Delete Image'}
                primaryActionBtnColor={'#DA1E28'}
                primaryActionBtnText={'Yes'}
                secondaryActionBtnColor={'#003163'}
                secondaryActionBtnText={'No'}
                content={'Are you sure you want to delete this image?'}
                dialogState={openDeleteModal}
                secondaryBtnAction={handleDeleteModalClose}
                primaryBtnAction={handleDeleteModalClose}
                handleClose={handleDeleteModalClose}
            /> */}
    </div>
  );
}

export default MediaHome;
