import React from 'react'
import { Stack, Typography, Button } from '@mui/material'
import FormBg from '../../components/user-auth/FormBg'
import Logo from '../../assets/general/nsib_logo.png'
import bg from '../../assets/general/resetPasswordBg.png'
import FormHeaders from '../../components/user-auth/FormHeaders'
import success from '../../assets/general/successLogo.png'

function SuccessResetPassword() {

  return (
    <Stack
      direction={'row'}
      width={'100vw'}
      alignItems={'stretch'}
      minHeight={'100vh'}
    >
      <FormBg
        backgroundImage={bg}
      />
      <Stack
        direction={'column'}
        gap={'84px'}
        sx={{
          px: '64px',
          maxWidth: '50%',
          py: '52px'
        }}
        flex={1}
      >
        <Stack
          direction={'row'}
          gap={'8px'}
          alignItems={'center'}
        >
          <img
            src={Logo}
            // width={'55px'}
            // height={'54px'}
            alt=""
            className='h-[110px]'
          />
          {/* <p className='font-bold leading-6 text-ms break-all w-[245px] text-[#211357] tracking-wide uppercase'>Nigeria Safety <br /> Investigation Bureau</p> */}

          {/* <Typography
            variant='p'
            fontFamily={'Pt Sans'}
            letterSpacing={'0.16px'}
            fontSize={14}
            lineHeight={'16.8px'}
            color={'#211357'}
          >
            Accident Investigation
            <br /> Bureau, Nigeria
          </Typography> */}
        </Stack>

        <Stack
          direction={'column'}
          gap={'16px'}
          alignItems={'center'}
          alignSelf={'center'}
          marginTop={15}
        >
          <FormHeaders
            title={'Reset Password'}
            titleColor={'#161616'}
            titleLineHeight={35}
            titleSize={22}
            marginBottom={40}
          />

          <Stack alignItems={'center'} gap={'4px'}>
            <img
              src={success}
              style={{
                minWidth: '64px',
                maxWidth: '64px',
                aspectRatio: '1/1'
              }}
            />
            <Typography color={'#161616'} fontSize={16} fontWeight={700} lineHeight={'19.2px'} marginTop={3}>
              Successful
            </Typography>
            <Typography color={'#161616'} fontSize={14} fontWeight={400} lineHeight={'16.8px'} marginTop={3}>
              Your password has been successfully reset
            </Typography>
          </Stack>

          <Button
            variant='contained'
            disableElevation
            sx={{
              backgroundColor: '#003163',
              borderRadius: 0,
              width: '172px',
              textTransform: 'none',
              height: '53px',
              fontFamily: 'PT Sans',
              color: '#CFE0FF',
              fontSize: 14,
              mt: '16px',
              alignSelf: 'center',
              '&:hover': {
                backgroundColor: '#003163'
              }
            }}
            href='/login'
          >
            Login
          </Button>
        </Stack>

      </Stack>
    </Stack>
  )
}

export default SuccessResetPassword
