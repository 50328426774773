import React, { useState, useEffect, useRef } from 'react';

const KeyInput = ({ length, onComplete, }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current[0].focus();
    }, []);

    const handleInputChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (newOtp.every((digit) => digit !== '')) {
            onComplete(newOtp.join(''));
        }
    };

    const handleKeyDown = (event, index) => {
        const isValidInput =
            (event.key >= '0' && event.key <= '9') || 
            (event.key >= 'a' && event.key <= 'z') || 
            (event.key >= 'A' && event.key <= 'Z'); 

        if (isValidInput) {
            handleInputChange(index, event.key);
            if (index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }

        if (event.key === ('Backspace' || 'BACKSPACE') && index > 0) {
            handleInputChange(index, '');
            inputRefs.current[index - 1].focus();
        }

        event.preventDefault();
    };

    return (
        <div className='flex justify-between'>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    inputMode="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    className='border w-[82px] h-[82px] rounded-lg text-center font-bold text-3xl uppercase '
                />
            ))}
        </div>
    );
};

// KeyInput.propTypes = {
//   length: PropTypes.number.isRequired,
//   onComplete: PropTypes.func.isRequired,
// };

export default KeyInput;