import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Stack, Typography, Button, Dialog } from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import Editor from "../../common/Editor";
import Loading from "../../common/Loading";
import useFetch from "../../helpers/useFetch";

const AppendixDetails = () => {
  const navigate = useNavigate();
  const { sectionId, occurrenceId } = useParams();
  const { data, isLoading } = useFetch(
    `/api/occurrence-manager/information/${occurrenceId}/appendices/`
  );
  const [value, setValue] = React.useState("");
  const [section, setSection] = React.useState({});
  const [formData, setFormData] = React.useState({
    section_owner: "",
    section_members: [],
  });
  // const [sectionOwner, setSectionOwner] = React.useState("");
  React.useEffect(() => {
    if (data?.data) {
      const section = data?.data?.occurence_section?.find(
        (val) => val.id === sectionId
      );
      setSection(section);
      if (section) {
        const sectionOwner = section?.occurence_section_member?.find(
          (member) => member.is_owner
        );
        const sectionMembers = section?.occurence_section_member?.filter(
          (member) => !member.is_owner
        );
        // setSectionOwner(sectionOwner);
        setFormData((prevState) => ({
          ...prevState,
          section_owner: sectionOwner?.member,
        }));
        if (sectionMembers.length > 0) {
          setFormData((prevState) => ({
            ...prevState,
            section_members: sectionMembers.map((member) => member.member),
          }));
        }
      }
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"32px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>{section?.section_name}</h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        <div>
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Appendix Details
          </Typography>
          <div>
            <Editor value={value} onChange={(e) => setValue(e)} />
          </div>
        </div>

        {/* <div className="w-full h-[28rem] rounded-xl overflow-hidden mt-10">
          <img
            src="https://picsum.photos/200"
            className="w-full h-full object-cover"
            alt=""
          />
        </div> */}
      </div>

      <div className="flex justify-end">
        <Button
          className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </Stack>
  );
};

export default AppendixDetails;
