import React from "react";
import { useNavigate } from "react-router-dom";
import EditStakeholder from "../../components/occurrence/manager-edits/EditStakeholder";

const ManageSectionEdit = () => {
  const navigate = useNavigate();
  // GET QUERY FROM ROUTE WITH SECTION_NAME
  const query = new URLSearchParams(window.location.search).get("section_name");
  if (!query) {
    navigate(-1);
  }
  //   const sectionName = ().sec;
  return <div>{query === "Stakeholders" && <EditStakeholder />}</div>;
};

export default ManageSectionEdit;
