import React from "react";
import {
  Button,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { stakeholderColumns } from "../../components/dashboard/StakeholderHome";

const index = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useFetch(
    "api/occurrence-manager/stakeholders/occurrence_by_stakeholders/"
  );
  const [reviewedData, setReviewedData] = React.useState([]);
  React.useEffect(() => {
    if (data.data) {
      const reviewedOccurrence = data.data.filter(
        (occurrence) => occurrence.is_reviewed === true
      );
      setReviewedData(reviewedOccurrence);
    }
  }, [data]);
  return (
    <Stack gap={"40px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <TableContainer className="bg-white rounded-2xl min-h-[calc(100dvh-20rem)]">
        <Table>
          <TableHead>
            <TableRow>
              {stakeholderColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography className="text-[#AEAEAE] font-medium text-sm">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <TableCell colSpan={stakeholderColumns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <CircularProgress />
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : reviewedData?.length > 0 ? (
              reviewedData?.map((item, index) => (
                <StyledTableRow key={index}>
                  {stakeholderColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      className="text-gray-900 font-medium text-sm"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "action" ? (
                        <Button
                          variant="contained"
                          className="bg-primary text-white text-sm shadow-none font-bold rounded-xl capitalize"
                          onClick={() => navigate(`/reviews/${item?.id}`)}
                        >
                          View
                        </Button>
                      ) : (
                        item?.occurrence[column.id]
                      )}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={stakeholderColumns?.length} align="center">
                  <div className="mt-10 mb-10 text-lg">
                    <p>No reviewed occurrence added yet</p>
                  </div>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default index;
