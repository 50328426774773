import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DrawerList from "./DrawerList";
import { Drawer, AppBar } from "./Drawer";
import { Link } from "react-router-dom";
import { Logout, SearchNormal1, ArrowDown2 } from "iconsax-react";
import { Icon } from "@iconify/react";
import { useDetectClickOutside } from "react-detect-click-outside";

// IMAGES
import profileImg from "../../assets/emojis/girl-with-nose-ring.svg";
import logo from "../../assets/general/nsib_logo_half.png";
import argue from "../../assets/general/argue.png";
import report from "../../assets/general/report.png";
import avatar from "../../assets/general/avatar.png";
import {
  refreshAuthToken,
  userLogout,
} from "../../redux/slices/users/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../../redux/slices/users/authSlice";

import { Outlet, useLocation } from "react-router-dom";
import { clearUserData } from "../../redux/slices/users/userSlice";
import Notification from "../../components/common/Notification";
import { CircularProgress } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Argue
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 256;

const defaultTheme = createTheme();

function Dashboard() {
  const dashboardTitle = useSelector((state) => state.title?.title);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = React.useState(true);
  const [showProfileExtention, setShowProfileExtention] = React.useState(false);

  const drawerOpen = useSelector((state) => state.auth.drawerOpen);
  const user = useSelector((state) => state.users.user);
  console.log("user", user);
  const userRoles = useSelector((state) => state.users?.user?.roles);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const History = useNavigate();

  const handleLogout = async () => {
    // dispatch(userLogout())
    dispatch(userLogout())
      .unwrap()
      .then(() => dispatch(clearUserData()).unwrap);

    // dispatch(refreshAuthToken())
  };

  // CLICK OUTSIDE DROPDOWN
  const profileRef = useDetectClickOutside({
    onTriggered: () => setShowProfileExtention(false),
  });

  // TITLE
  const [title, setTitle] = React.useState("Dashboard");
  React.useEffect(() => {
    if (pathname === "/dashboard") {
      setTitle("Dashboard");
    } else if (pathname?.includes("/reset-requests")) {
      setTitle("Password Reset Requests");
    } else if (pathname === "/user-management") {
      setTitle("User Management");
    } else if (
      pathname === "/user-management/users" ||
      pathname === "/user-management/create-user" ||
      pathname?.includes("/user-management/manage-user/") ||
      pathname === "/user-management/profile-settings"
    ) {
      setTitle(
        pathname === "/user-management/profile-settings"
          ? "User Profile"
          : "User Profiles"
      );
    } else if (pathname === "/user-management/roles") {
      setTitle("Role Profiles");
    } else if (pathname?.includes("/user-management/manage-role")) {
      setTitle("Role Details");
    } else if (pathname === "/user-management/create-role") {
      setTitle("Create New Role");
    } else if (pathname?.includes("/icao")) {
      setTitle(
        `ICAO ${dashboardTitle ? dashboardTitle?.replace(/_/g, " ") : ""}`
      );
    } else if (pathname?.includes("/role-data")) {
      setTitle(
        "Role Data" + (pathname?.includes("/role-data/") ? " - Users" : "")
      );
    } else if (pathname?.includes("/user-data")) {
      setTitle("User Profiles");
    } else if (pathname?.includes("/occurrence")) {
      if (pathname?.includes("/occurrence/create-occurrence")) {
        setTitle("Create Occurrence");
      } else {
        setTitle("Occurrence");
      }
    } else if (pathname?.includes("/media")) {
      setTitle("Media Library");
    } else if (pathname?.includes("/change-password")) {
      setTitle("Change Password");
    } else {
      setTitle("Dashboard");
    }
  });

  // CHANGE PASSWORD
  const [shouldChangePassword, setShouldChangePassword] = React.useState(false);
  React.useEffect(() => {
    const shouldChangePassword = localStorage.getItem("changePassword");
    if (shouldChangePassword) {
      History("/change-password");
      setShouldChangePassword(true);
    } else {
      setShouldChangePassword(false);
    }
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <Drawer variant="permanent" open={drawerOpen}>
          <div className="min-w-[18rem] select-none">
            <div className="mt-[32px] flex gap-2 pl-[44px] items-center w-full">
              <img src={logo} alt="" className="w-[46px]" />
              <div
                className={`w-[141px] break-all ${
                  drawerOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                {/* <p className='font-normal leading-6 text-sm break-all w-[141px] text-[#211357] tracking-wide'>Nigerian Safety <br /> Investigation Bureau</p> */}
                <p className="font-bold leading-6 text-xs break-all w-[149px] text-[#211357] tracking-wide uppercase">
                  Nigeria Safety <br /> Investigation Bureau
                </p>
              </div>
            </div>
          </div>
          <List
            component="nav"
            className={`transition-all ${
              drawerOpen ? "md:mx-6" : "md:mx-4"
            } flex flex-col justify-between min-h-[90dvh] gap-6`}
          >
            {!shouldChangePassword && (
              <DrawerList open={drawerOpen} pathName={pathname} />
            )}

            <div
              className={`pl-6 flex items-center gap-1.5 text-red-600 font-semibold select-none cursor-pointer ${
                shouldChangePassword && "mt-32"
              }`}
              onClick={handleLogout}
            >
              <Logout size="24" variant="Bold" />
              <p>Log Out</p>
            </div>

            <div className=" -ml-2">
              <div className="flex justify-center gap-1 mb-11">
                <img
                  src={argue}
                  alt=""
                  className="w-[86px] h-[20px] transition-all "
                />
                {drawerOpen && (
                  <img
                    src={report}
                    alt=""
                    className="h-[20px] transition-all"
                  />
                )}
              </div>
              {/* <div className="flex gap-2 justify-center items-center">
                <IconButton
                  onClick={() => dispatch(toggleDrawer())}
                  className="p-0"
                >
                  {drawerOpen ? (
                    <ArrowCircleLeftOutlinedIcon className="text-black w-8 h-8" />
                  ) : (
                    <ArrowCircleRightOutlinedIcon className="text-black w-8 h-8" />
                  )}
                </IconButton>
                {drawerOpen && (
                  <p className="text-sm text-black">Collapse Menu</p>
                )}
              </div> */}
            </div>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="xl" sx={{ mb: 4, px: "30px" }}>
            <div className="pt-10 pb-2 sticky top-0 bg-gray-100 z-50">
              <div className="rounded-2xl bg-primary px-10 py-4 text-white">
                <div className="flex items-center justify-between gap-4">
                  <div>
                    <h2 className="text-xl font-semibold">{title}</h2>
                  </div>
                  <div className="flex items-stretch gap-3">
                    <div
                      ref={profileRef}
                      className="bg-primary-200 rounded-lg px-4 py-2 flex items-center gap-14 relative select-none"
                    >
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() =>
                          setShowProfileExtention(!showProfileExtention)
                        }
                      >
                        <img src={profileImg} className="h-8" />
                        <div className="flex items-center gap-1">
                          <p className="text-white text-sm font-semibold">
                            {user?.first_name}
                          </p>
                          <ArrowDown2 size={14} />
                        </div>
                      </div>
                      <div className="cusror-pointer">
                        <Icon icon="fa6-regular:bell" />
                      </div>
                      {showProfileExtention && (
                        <div className="absolute top-[100%] left-0 w-full font-semibold text-xs text-white">
                          <ul className="bg-primary rounded-b-2xl mx-auto w-[96%]">
                            <li>
                              <NavLink
                                to="/user-management/profile-settings"
                                className="py-3 bg-primary-700 px-6 block"
                                onClick={() => setShowProfileExtention(false)}
                              >
                                <p>Profile</p>
                              </NavLink>
                            </li>
                            <li
                              className="py-3 px-6 cursor-pointer"
                              onClick={handleLogout}
                            >
                              <p>Log Out</p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <Outlet />
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
