import * as React from "react";
// import div from '@mui/material/div';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { NavLink } from "react-router-dom";
import {
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useWebservice from "../../helpers/useWebservice";
import CustomSelectField, {
  AutoCompleteField,
} from "../../components/common/CustomSelectField";

function CustomTextForm({ label, placeholder, onChange, value }) {
  return (
    <div className="flex flex-col mt-2 space-y-1">
      <p className=" text-xs text-secondary">{label}</p>
      <TextField
        id="input-with-icon-textfield"
        placeholder={placeholder}
        variant="filled"
        fullWidth
        size="small"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

function DrawerList({
  filterForm,
  setFilterForm,
  submitFilterSearch,
  submitFilterTitleSearch,
  filterTitle,
  setFilterTitle,
  data,
  filterData,
  reportIsActive,
}) {
  const [locationData, setLocationData] = React.useState([]);
  const [stateData, setStateData] = React.useState([]);
  const [injuryData, setInjuryData] = React.useState([]);
  const [occurenceTypeData, setOccurrenceTypeData] = React.useState([]);
  const [occurenceCategoryData, setOccurrenceCategoryData] = React.useState([]);

  const {
    data: dataLocation,
    isLoading: isLoadingLocation,
    refetch: refetchLocation,
  } = useWebservice(`api/settings/location/city/`);
  const {
    data: dataState,
    isLoading: isLoadingState,
    refetch: refetchState,
  } = useWebservice(`api/settings/location/state/`);
  const {
    data: dataInjuryData,
    isLoading: isLoadingInjuryData,
    refetch: refetchInjuryData,
  } = useWebservice(`api/settings/occurrence/injury/`);
  const {
    data: dataOccurrenceType,
    isLoading: isLoadingOccurrenceType,
    refetch: refetchOccurrenceType,
  } = useWebservice(`api/settings/occurrence/type/`);
  const {
    data: dataOccurrenceCategory,
    isLoading: isLoadingOccurrenceCategory,
    refetch: refetchOccurrenceCategory,
  } = useWebservice(`api/settings/occurrence/category/`);

  React.useEffect(() => {
    const stateMappings = [
      {
        isLoading: isLoadingLocation,
        setState: setLocationData,
        data: dataLocation?.data,
      },
      {
        isLoading: isLoadingState,
        setState: setStateData,
        data: dataState?.data,
      },
      {
        isLoading: isLoadingOccurrenceCategory,
        setState: setOccurrenceCategoryData,
        data: dataOccurrenceCategory?.data,
      },
      {
        isLoading: isLoadingInjuryData,
        setState: setInjuryData,
        data: dataInjuryData?.data,
      },
      {
        isLoading: isLoadingOccurrenceType,
        setState: setOccurrenceTypeData,
        data: dataOccurrenceType?.data,
      },
    ];

    stateMappings.forEach(({ isLoading, setState, data }) => {
      if (!isLoading) {
        setState(data);
      }
    });
  }, [
    isLoadingLocation,
    dataLocation,
    isLoadingState,
    dataState,
    isLoadingInjuryData,
    dataInjuryData,
    isLoadingOccurrenceType,
    dataOccurrenceType,
    isLoadingOccurrenceCategory,
    dataOccurrenceCategory,
  ]);

  // occurrence_number: '',
  //       location: '',
  //       state: '',
  //       status: '',
  //       injury_level: '',
  //       type: '',
  //       category: ''

  return (
    <div className="mt-[90px]">
      <div className="pl-16 pr-6">
        <div className="mt-10 flex flex-col">
          <p className=" text-xs text-secondary">Search Occurrence</p>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Seach input text"
            value={filterTitle.title}
            onChange={(e) => {
              setFilterTitle({ ...filterTitle, title: e.target.value });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="filled"
            size="small"
            fullWidth
          />
          <Button
            className={` ${
              reportIsActive ? "bg-[#c9ba73]" : "bg-primary"
            } text-white capitalize rounded-none py-3 mt-9`}
            onClick={submitFilterTitleSearch}
          >
            {" "}
            {data?.length !== filterData?.length && filterTitle?.title === ""
              ? "Reset"
              : "Search"}
          </Button>
        </div>

        <div className="mt-14 flex flex-col space-y-2">
          <p className="font-bold  text-xs">Filter</p>

          <CustomTextForm
            label={`Occurrence Number`}
            placeholder={`Enter the occurrence number`}
            onChange={(e) =>
              setFilterForm({
                ...filterForm,
                occurrence_number: e.target.value,
              })
            }
            value={filterForm?.occurrence_number}
          />
          {reportIsActive ? (
            <CustomTextForm
              label={`Location`}
              placeholder={`Enter the Location`}
              onChange={(e) =>
                setFilterForm({
                  ...filterForm,
                  location: e.target.value,
                })
              }
              value={filterForm?.location}
            />
          ) : (
            <AutoCompleteField
              label={`Location (City)`}
              placeholder={`Select Location`}
              loading={isLoadingLocation}
              data={locationData}
              value={filterForm?.location}
              onChange={(event, newData) =>
                setFilterForm({ ...filterForm, location: newData })
              }
            />
          )}

          <AutoCompleteField
            label={`State`}
            placeholder={`Select State`}
            loading={isLoadingState}
            data={stateData}
            value={filterForm?.state}
            onChange={(event, data) =>
              setFilterForm({ ...filterForm, state: data })
            }
          />

          <AutoCompleteField
            label={`Occurrence Status`}
            placeholder={`Select Occurrence Status`}
            data={[
              { name: "PRELIMINARY_REPORT", value: "Preliminary Report" },
              { name: "INTERIM_STATEMENT", value: "Interim Report" },
              { name: "FINAL_REPORT", value: "Final Report" },
            ]}
            value={filterForm?.status}
            onChange={(event, newData) =>
              setFilterForm({ ...filterForm, status: newData })
            }
          />

          {reportIsActive ? null : (
            <AutoCompleteField
              label={`Highest Injury Level`}
              loading={isLoadingInjuryData}
              placeholder={`Select Highest Injury Level`}
              data={injuryData}
              value={filterForm?.injury_level}
              onChange={(event, newData) =>
                setFilterForm({ ...filterForm, injury_level: newData })
              }
            />
          )}
          <AutoCompleteField
            label={`Occurrence Type`}
            loading={isLoadingOccurrenceType}
            placeholder={`Select Occurrence Type`}
            data={occurenceTypeData}
            value={filterForm?.type}
            onChange={(event, newData) =>
              setFilterForm({ ...filterForm, type: newData })
            }
          />

          <AutoCompleteField
            label={`Occurrence Category`}
            loading={isLoadingOccurrenceCategory}
            placeholder={`Select Occurrence Category`}
            data={occurenceCategoryData}
            value={filterForm?.category}
            onChange={(event, newData) =>
              setFilterForm({ ...filterForm, category: newData })
            }
          />

          <div className="w-full">
            <Button
              className={` ${
                reportIsActive ? "bg-[#c9ba73]" : "bg-primary"
              } text-white capitalize rounded-none py-3 my-9 w-full disabled:text-gray-200`}
              onClick={submitFilterSearch}
              // disabled={
              //   filterForm?.occurrence_number === "" &&
              //   filterForm?.location === "" &&
              //   filterForm?.state === "" &&
              //   filterForm?.status === "" &&
              //   filterForm?.injury_level === "" &&
              //   filterForm?.type === "" &&
              //   filterForm?.category === ""
              // }
            >
              Apply Filter Search
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerList;
