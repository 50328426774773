import React from "react";
import { InputField } from "../../components";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Input } from "../user-management/ManageProfile";
import { ArrowLeft } from "iconsax-react";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";

import { useSelector } from "react-redux";
import axios from "axios";
import { success, error } from "../../common/Notifications";
import CircularProgress from "@mui/material/CircularProgress";
import useFetch from "../../helpers/useFetch";
import useWebservice from "../../helpers/useWebservice";

const transportModes = [
  {
    id: "A",
    value: "Aviation",
  },
  {
    id: "M",
    value: "Marine",
  },
  {
    id: "R",
    value: "Rail",
  },
  {
    id: "O",
    value: "Others",
  },
];

function CreateOccurence() {
  const navigate = useNavigate();

  const user = useSelector((state) => state.users.user);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [allUserData, setAlluserData] = React.useState([]);
  const [occurenceTypeData, setOccurrenceTypeData] = React.useState([]);
  const [occurenceCategoryData, setOccurrenceCategoryData] = React.useState([]);
  const {
    data: dataAllUsers,
    isLoading: isLoadingAllUsers,
    refetch: refetchAllUsers,
  } = useFetch(`api/user/`);
  const {
    data: dataOccurrenceType,
    isLoading: isLoadingOccurrenceType,
    refetch: refetchOccurrenceType,
  } = useWebservice(`api/settings/occurrence/type/`);
  const {
    data: dataOccurrenceCategory,
    isLoading: isLoadingOccurrenceCategory,
    refetch: refetchOccurrenceCategory,
  } = useWebservice(`api/settings/occurrence/category/`);

  React.useEffect(() => {
    if (!isLoadingAllUsers) {
      setAlluserData(
        dataAllUsers.data?.filter(
          (user) =>
            user?.role?.toLowerCase() == "OCCURENCE-MANAGER/IIC"?.toLowerCase()
        )
      );
    }

    if (!isLoadingOccurrenceType) {
      setOccurrenceTypeData(dataOccurrenceType.data);
    }

    if (!isLoadingOccurrenceCategory) {
      setOccurrenceCategoryData(dataOccurrenceCategory.data);
    }
  }, [
    dataAllUsers,
    isLoadingAllUsers,
    dataOccurrenceType,
    isLoadingOccurrenceType,
    isLoadingOccurrenceCategory,
    dataOccurrenceCategory,
  ]);

  const initialForm = {
    title: "",
    occurrence_type: "",
    occurrence_date: "",
    occurrence_category: "",
    occurrence_owner: "",
    transport_mode: "",
    icao_deadline_date: "",
    tenancy: "AIBNIGERIA",
    occurrence_creator: user?.email,
  };
  const [loading, setLoading] = React.useState(false);
  const [createOccurenceIsActive, setCreateOccurenceIsActive] =
    React.useState(false);
  const [isDone, setIsDone] = React.useState(false);

  const [formState, manageFormState] = React.useState(initialForm);

  React.useEffect(() => {
    if (formState.occurrence_date) {
      // add 30 days to the occurrence date
      const dateIn30Days = new Date(formState.occurrence_date).setDate(
        new Date(formState.occurrence_date).getDate() + 30
      );
      console.log(dateIn30Days);
      manageFormState({
        ...formState,
        icao_deadline_date: getDateFormat(dateIn30Days),
      });
    }
  }, [formState.occurrence_date]);

  function getDateFormat(date) {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/information/`,
        formState,
        config
      );
      success(res.data.message);
      setLoading(false);
      manageFormState(initialForm);
      setCreateOccurenceIsActive(false);
      setIsDone(true);
    } catch (err) {
      error(err.response?.data.message);
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Stack gap={"18px"}>
      <Dialog
        open={createOccurenceIsActive}
        onClose={() => setCreateOccurenceIsActive(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "450px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Create"
          title="Create New Occurence"
          isLoading={loading}
          description="Are you sure you want to create this new occurrence? "
          handleClose={() => setCreateOccurenceIsActive(false)}
          handleConfirm={handleSubmit}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setIsDone(false), navigate(-1);
        }}
      >
        <DoneDialog
          title="Successfully Created"
          description="You have successfully created this occurrence."
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack
        padding={"32px"}
        bgcolor={"#fff"}
        borderRadius={"16px"}
        gap={"32px"}
        marginTop={"10px"}
      >
        <Typography
          color={"#161616"}
          fontSize={16}
          fontWeight={700}
          borderBottom={"1px solid #E5E5E5"}
          paddingBottom={"10px"}
        >
          Occurence Meta Data
        </Typography>
        <Stack gap={"26px"}>
          <Stack direction={"row"} gap={"40px"}>
            <Stack width={"100%"} gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurrence Number
              </Typography>
              <div className="opacity-40">
                <Input
                  fullWidth
                  disabled={true}
                  placeholder={"Occurrence Number"}
                />
              </div>
            </Stack>

            <Stack width={"100%"} gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurrence Title
              </Typography>

              <Input
                fullWidth
                placeholder={"Occurrence Title"}
                value={formState.title}
                onChange={({ target }) => {
                  manageFormState({
                    ...formState,
                    title: target.value,
                  });
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={"40px"}>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurrence Type
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={formState.occurrence_type}
                  onChange={(e) => {
                    manageFormState((prevState) => ({
                      ...prevState,
                      occurrence_type: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <Typography color={"inherit"} fontWeight={600}>
                      Select Type
                    </Typography>
                  </MenuItem>
                  {occurenceTypeData?.map((eachItem) => (
                    <MenuItem key={eachItem.id} value={eachItem.value}>
                      <Typography color={"inherit"} fontWeight={600}>
                        {eachItem.value}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack className="flex-1">
              <Typography variant="p" color={"#D8D8D8"}>
                Occurence Date
              </Typography>
              <InputField
                fieldType={"date"}
                onChange={(e) => {
                  const selectedDate = new Date(e);
                  const year = selectedDate.getFullYear();
                  const month = (selectedDate.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const day = selectedDate
                    .getDate()
                    .toString()
                    .padStart(2, "0");
                  const formattedDate = `${year}-${month}-${day}`;
                  manageFormState((prevState) => ({
                    ...prevState,
                    occurrence_date: formattedDate,
                  }));
                }}
                placeholder={"Select Date"}
                border={true}
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} gap={"40px"}>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurence Category
              </Typography>
              <FormControl className="w-full">
                <Select
                  labelId="select-standard-label"
                  id="simple-select-standard"
                  value={formState.occurrence_category}
                  onChange={(e) => {
                    manageFormState((prevState) => ({
                      ...prevState,
                      occurrence_category: e.target.value,
                    }));
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                >
                  <MenuItem value="">
                    <Typography color={"inherit"} fontWeight={600}>
                      Select Category
                    </Typography>
                  </MenuItem>
                  {occurenceCategoryData?.map((eachItem) => (
                    <MenuItem key={eachItem.id} value={eachItem.value}>
                      <Typography color={"inherit"} fontWeight={600}>
                        {eachItem.value}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Occurence Manager/IIC
              </Typography>
              <Select
                labelId="select-standard-label"
                id="simple-select-standard"
                value={formState.occurrence_owner}
                onChange={(e) => {
                  console.log(e.target);
                  manageFormState((prevState) => ({
                    ...prevState,
                    occurrence_owner: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <Typography color={"inherit"} fontWeight={600}>
                    Select Occurrence Manager/IIC
                  </Typography>
                </MenuItem>
                {allUserData?.map((eachItem) => (
                  <MenuItem key={eachItem.id} value={eachItem.id}>
                    <Typography color={"inherit"} fontWeight={600}>
                      {eachItem.fullname}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={"40px"}>
            <Stack className="flex-1">
              <Typography variant="p" color={"#D8D8D8"}>
                ICAO Date{" "}
              </Typography>
              <div className="pointer-events-none opacity-40">
                <InputField
                  disabled={true}
                  fieldType={"date"}
                  placeholder={"Select Date"}
                  border={true}
                  onChange={(e) => {}}
                  value={formState.icao_deadline_date}
                />
              </div>
            </Stack>
            <Stack className="flex-1" gap={"6px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Transport Mode
              </Typography>
              <Select
                labelId="select-standard-label"
                id="simple-select-standard"
                value={formState.transport_mode}
                onChange={(e) => {
                  manageFormState((prevState) => ({
                    ...prevState,
                    transport_mode: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <Typography color={"inherit"} fontWeight={600}>
                    Select Transport Mode
                  </Typography>
                </MenuItem>
                {transportModes?.map((eachItem) => (
                  <MenuItem key={eachItem.id} value={eachItem.id}>
                    <Typography color={"inherit"} fontWeight={600}>
                      {eachItem.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={"row"} className=" justify-end gap-4">
          <Button
            onClick={() => navigate(-1)}
            disabled={loading}
            disableElevation
            variant="contained"
            className="bg-stone-300 capitalize py-2.5 px-12 rounded-xl font-bold text-sm"
          >
            Discard{" "}
          </Button>
          <Button
            disabled={
              !formState.title ||
              !formState.occurrence_date ||
              !formState.occurrence_type ||
              !formState.occurrence_category ||
              !formState.occurrence_owner ||
              !formState.icao_deadline_date ||
              !formState.transport_mode
            }
            onClick={() => setCreateOccurenceIsActive(true)}
            disableElevation
            variant="contained"
            className="bg-primary disabled:bg-stone-300 capitalize py-2.5 px-12 rounded-xl font-bold text-sm"
          >
            Create{" "}
            {loading && (
              <CircularProgress className="w-2 h-2 ml-2 text-white" />
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CreateOccurence;
