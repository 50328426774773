import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import FormBg from "../../components/user-auth/FormBg";
import Logo from "../../assets/general/nsib_logo.png";
import bg from "../../assets/general/resetPasswordBg.png";
import FormHeaders from "../../components/user-auth/FormHeaders";
import FormField from "../../components/user-auth/FormField";
import { Form } from "../user-management/ManageProfile";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { success, error } from "../../common/Notifications";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTextField from "../../components/common/CustomTextField";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function ResetPassword() {
  const dispatch = useDispatch();

  const [formState, manageFormState] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    message: "",
    tenancy: "AIBNIGERIA",
  });

  const [formErrors, setFormErrors] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    message: "",
  });

  const [loading, setLoading] = React.useState(false);

  async function handleSubmit() {
    setLoading(true);

    setFormErrors({
      email: "",
      first_name: "",
      last_name: "",
      message: "",
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/user/password_request_form`,
        formState
      );
      manageFormState({
        email: "",
        first_name: "",
        last_name: "",
        message: "",
        tenancy: "AIBNIGERIA",
      });
      success("Your password request has been successfully submitted.");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      err?.response?.status
        ? error("You have errors in your form")
        : error("Network error, try again later");
      Array.isArray(err)
        ? setFormErrors({
            email: err[0]?.hasOwnProperty("email") ? err[0]["email"] : "",
            first_name: err[0]?.hasOwnProperty("first_name")
              ? err[0]["first_name"]
              : "",
            last_name: err[0]?.hasOwnProperty("last_name")
              ? err[0]["last_name"]
              : "",
          })
        : setFormErrors({
            email: err?.response?.data?.hasOwnProperty("email")
              ? err?.response?.data["email"]
              : "",
            first_name: err?.response?.data?.hasOwnProperty("first_name")
              ? err?.response?.data["first_name"]
              : "",
            last_name: err?.response?.data?.hasOwnProperty("last_name")
              ? err?.response?.data["last_name"]
              : "",
          });
    }
  }

  return (
    <Stack
      direction={"row"}
      width={"100vw"}
      alignItems={"stretch"}
      minHeight={"100vh"}
    >
      <FormBg backgroundImage={bg} />
      <Stack
        direction={"column"}
        gap={"84px"}
        sx={{
          px: "64px",
          maxWidth: "50%",
          py: "52px",
        }}
        flex={1}
      >
        <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
          <img src={Logo} alt="" className="h-[100px]" />
        </Stack>

        <Stack
          direction={"column"}
          gap={"16px"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <FormHeaders
            title={"Submit Password Reset Request"}
            titleColor={"#161616"}
            titleLineHeight={35}
            titleSize={22}
            marginBottom={32}
          />
          <Stack width={"400px"} gap={"6px"}>
            <Typography
              variant="p"
              fontSize={12}
              fontFamily={"Pt Sans"}
              color={"#646464"}
            >
              Email Address
            </Typography>

            <CustomTextField
              fullWidth
              placeholder={"Enter your email address"}
              onChange={({ target }) => {
                manageFormState((prevState) => ({
                  ...prevState,
                  email: target.value,
                }));
              }}
              value={formState?.email}
              name={"email"}
              type="email"
              required="true"
              error={!!formErrors?.email}
              helperText={formErrors?.email}
            />
          </Stack>
          <Stack width={"400px"} gap={"6px"}>
            <Typography
              variant="p"
              fontSize={12}
              fontFamily={"Pt Sans"}
              color={"#646464"}
            >
              First Name
            </Typography>

            <CustomTextField
              fullWidth
              placeholder={"Enter your first name"}
              onChange={({ target }) => {
                manageFormState((prevState) => ({
                  ...prevState,
                  first_name: target.value,
                }));
              }}
              value={formState?.first_name}
              name={"first_name"}
              required="true"
              error={!!formErrors?.first_name}
              helperText={formErrors?.first_name}
            />
          </Stack>
          <Stack width={"400px"} gap={"6px"}>
            <Typography
              variant="p"
              fontSize={12}
              fontFamily={"Pt Sans"}
              color={"#646464"}
            >
              Last Name
            </Typography>

            <CustomTextField
              fullWidth
              placeholder={"Enter your last name"}
              onChange={({ target }) => {
                manageFormState((prevState) => ({
                  ...prevState,
                  last_name: target.value,
                }));
              }}
              value={formState?.last_name}
              name={"last_name"}
              required="true"
              error={!!formErrors?.last_name}
              helperText={formErrors?.last_name}
            />
          </Stack>
          <Stack width={"400px"} gap={"6px"}>
            <Typography
              variant="p"
              fontSize={12}
              fontFamily={"Pt Sans"}
              color={"#646464"}
            >
              Message
            </Typography>

            <CustomTextField
              fullWidth
              placeholder={"Enter message or reason for request"}
              onChange={({ target }) => {
                manageFormState((prevState) => ({
                  ...prevState,
                  message: target.value,
                }));
              }}
              value={formState?.message}
              name={"message"}
              multiline={true}
              required="true"
              error={!!formErrors?.message}
              helperText={formErrors?.message}
            />
          </Stack>
          <div className="flex w-full justify-end">
            <Link to={"/login"}>
              <Typography
                sx={{
                  borderBottom: "1px solid #003163",
                  paddingBottom: "-1px",
                }}
                variant="body2"
                fontSize={12}
                color={"#003163"}
              >
                Back to login
              </Typography>
            </Link>
          </div>

          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: "#003163 !important",
              borderRadius: 0,
              width: "124px",
              textTransform: "none",
              height: "42px",
              fontFamily: "PT Sans",
              color: "#CFE0FF",
              fontSize: 14,
              mt: "16px",
              alignSelf: "flex-start",
              "&:hover": {
                backgroundColor: "#003163",
              },
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress className="w-5 h-5 text-white" />
            ) : (
              "Continue"
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ResetPassword;
