import React from "react";

// import DoneDialog from "../../common/DoneDialog";
// import ConfirmDialog from "../../common/ConfirmDialog";

import {
  Stack,
  Button,
  Typography,
  Dialog,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ArrowLeft } from "iconsax-react";
import { Input } from "../user-management/ManageProfile";
import { useSelector } from "react-redux";
import useFetch from "../../helpers/useFetch";
import { error, success } from "../../common/Notifications";
import axios from "axios";

const AddMedia = () => {
  const navigate = useNavigate();
  const { data: occurrenceList, isLoading } = useFetch(
    "api/occurrence-manager/information/"
  );
  const { data: sectionList, isLoading: sectionLoading } = useFetch(
    "api/occurrence-manager/sections/"
  );
  const userId = useSelector((state) => state.users?.user?.id);
  const userRole = useSelector((state) => state.users?.user?.roles?.role);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [formData, setFormData] = React.useState({
    title: "",
    alt_text: "",
    uploaded_by: "",
    url: "",
    section: "",
    occurrence: "",
    media_type: "",
  });
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef(null);
  function handleFileChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!file?.type?.includes("image") && !file?.type?.includes("video")) {
      error("Only images and videos are allowed");
      return;
    }
    // CHECK IF SIZE IS ABOVE 10 MB
    if (file.size > 10000000) {
      error("File size should not be above 10MB");
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      url: file,
      media_type: file.type?.includes("image") ? "IMAGE" : "VIDEO",
      title: file.name,
    }));
  }
  async function handleUploadMedia() {
    if (!formData.media_type || !formData.url || !formData.title) {
      error("Select an image or video to upload");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const payload = new FormData();
      payload.append("media_type", formData.media_type);
      payload.append("url", formData.url);
      payload.append("alt_text", formData.title);
      payload.append("title", formData.title);
      payload.append("section", formData.section);
      payload.append("uploaded_by", userId);
      payload.append("occurrence", formData.occurrence);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/media-library/`,
        payload,
        config
      );
      success(res.data.message);
      setFormData((prevState) => ({
        ...prevState,
        media_type: "",
        url: "",
        title: "",
      }));
      inputRef.current.value = null;
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }

  return (
    <Stack>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack className="bg-white rounded-2xl px-12 py-6 mt-8">
        <h2 className="text-xl font-semibold">Upload Media</h2>
      </Stack>

      <Stack className="bg-white rounded-2xl px-8 py-8 mt-7">
        <Stack className="grid grid-cols-10" gap={"20px"}>
          <div
            className="col-span-6 rounded-xl overflow-hidden max-h-[31rem] border-2 border-dashed flex text-center items-center justify-center cursor-pointer"
            onClick={() => inputRef.current.click()}
          >
            {formData.url ? (
              formData?.media_type?.toLowerCase() === "image" ? (
                <img
                  src={URL?.createObjectURL(formData?.url)}
                  className="w-full h-full object-cover"
                />
              ) : (
                <video
                  src={URL?.createObjectURL(formData?.url)}
                  className="w-full h-full object-cover"
                  controls
                />
              )
            ) : (
              <div>
                <Icon
                  icon="ri:upload-cloud-fill"
                  className="text-4xl text-secondary w-fit mx-auto"
                />
                <h2 className="font-bold text-xl pt-3">Upload Media</h2>
                <p className="text-sm font-medium max-w-[60%] mx-auto">
                  Ensure to upload a PNG, JPEG, SVG or MP4 doc of at least 10 MB
                </p>
              </div>
            )}
          </div>
          <input
            ref={inputRef}
            onChange={handleFileChange}
            type="file"
            className="hidden"
          />
          <div className="bg-gray-50 col-span-4 rounded-xl py-3 px-6">
            <div>
              <h2 className="text-xl font-semibold">Media Details</h2>
            </div>
            <div className="mt-4">
              <div>
                <Typography variant="p" color={"#D9D9D9"}>
                  Media Title
                </Typography>
                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  fullWidth
                  placeholder="Media title"
                />
              </div>
              <Stack gap={"6px"} className="mt-4">
                <Typography variant="p" color={"#D2D2D2"}>
                  Occurrence Attached
                </Typography>
                <FormControl>
                  <Select
                    labelId="occurrence-attached"
                    id="occurrence-attached"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={formData.occurrence}
                    onChange={(e) =>
                      setFormData({ ...formData, occurrence: e.target.value })
                    }
                    className="rounded-lg font-semibold"
                  >
                    <MenuItem value="">
                      <p className=" text-secondary ">
                        Select Occurrence attached
                      </p>
                    </MenuItem>
                    {occurrenceList?.data?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        className="text-secondary"
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack gap={"6px"} className="mt-4">
                <Typography variant="p" color={"#D9D9D9"}>
                  Section
                </Typography>
                <FormControl>
                  <Select
                    labelId="Section"
                    id="Section"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={formData.section}
                    onChange={(e) =>
                      setFormData({ ...formData, section: e.target.value })
                    }
                    className="rounded-lg font-semibold"
                  >
                    <MenuItem value="">
                      <p className=" text-secondary ">Select section</p>
                    </MenuItem>
                    {sectionList?.data?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.id}
                        className="text-secondary"
                      >
                        {item.section_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {userRole?.toLowerCase() === "section-owner" && (
                <div className="flex justify-end mt-5">
                  <Button
                    variant="contained"
                    className="px-12 font-bold bg-primary text-sm capitalize py-2.5 rounded-2xl shadow-none"
                    onClick={handleUploadMedia}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddMedia;
